import React, { useEffect, useState } from "react";
import { CurrentDate, ReadableDate } from "../../../Common/Functions/StringDateTime";

import IconButton from "@mui/material/IconButton";
import Heading from "../../../Common/Components/Heading";
import "../Bulletin.css";
// import FilterListIcon from '@mui/icons-material/FilterList';
import Backdrop from "@mui/material/Backdrop";
import Tooltip from "@mui/material/Tooltip";
// import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
// import Input from "../../Setup/Student/Component/Input";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import LinkIcon from "@mui/icons-material/Link";
import DateRangeIcon from "@mui/icons-material/DateRange";
// import SearchIcon from "@mui/icons-material/Search";
import Add from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import LinkIcon from '@mui/icons-material/Link';
import UnarchiveIcon from "@mui/icons-material/Unarchive";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

// import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import axios from "axios";
import url, { storageUrl } from "../../../Common/URL";

import CircularProgress from '@mui/material/CircularProgress';
import { useConfirm } from "material-ui-confirm";

import { Divider, FormControlLabel, Grid, Switch } from "@mui/material";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector } from "react-redux";
import Select from 'react-select';
import Toolbar from "../../../Common/Data/EditorToolbar";
import LoadingSpinner from "../../../Common/Components/LoadingSpinner";
import PurifyHtml from "../../../Common/Functions/PurifyHtml";


const Bulletin_comp = () => {
  useEffect(() => {
    loadLocation();
    loadBatches();
    loadBulletin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirm = useConfirm();

  const isEditorEmpty = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    return rawContentState.blocks.every(block => block.text.trim() === '');
  };

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ PANE - 1 - Data +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ PANE - 1 - Data +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [convertedContent, setConvertedContent] = useState(null);

  // ------------------------For archived Tab -------------------------------------------------

  const [archived, setArchived] = useState([]);

  // ----------------------- For loading Spinner ------------------------------------------------
  const [load, setLoad] = useState(true);

  // ----------------------- For showing bulletin Messages ------------------------------------------------
  const [bulletin_data, setBulletin_data] = useState([]);

  // ========================
  const [backdrop_open, setBackdrop_open] = useState(false);

  // ----------------------- For Add bulletin modal open - close ------------------------------------------------
  const [open, setOpen] = useState(false);

  // ----------------------- For Edit bulletin  modal open - close ------------------------------------------------
  const [edit_msg_open, setEdit_msg_Open] = useState(false);

  // =============================
  const [add_bulletin_spinner, setAdd_bulletin_spinner] = useState(false);

  const [edit_bulletin_spinner, setEdit_bulletin_spinner] = useState(false);
  // =============================
  const [originalData, setOriginalData] = useState([]);

  // =============================
  const [archive_originalData, setArchive_originalData] = useState([]);

  // =============================
  const [searchField, setSearchField] = useState({
    location: [],
  });

  // ============================
  const [archive_tab_searchfield, setArchive_tab_searchfield] = useState({
    location: [],
  });

  useEffect(() => {
    SearchSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  useEffect(() => {
    ArchiveTabSearchSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archive_tab_searchfield]);

  // ----------- This function will Fetch all the Existing Bulletins --------------------

  const loadBulletin = () => {
    const obj = { uname: sessionStorage.getItem("username") };

    axios
      .post(url + "select_bulletin", obj)
      .then((response) => {
        // console.log(response);
        if (response.data.success) {
          var bull = response.data.data;
          console.log(bull);
          var arch = bull === null ? [] : bull.filter((x) => x.archive === 1); // This will store archived message i.e. archive === 1
          var a = bull === null ? [] : bull.filter((x) => x.archive === 0); // var a will store non archived messages i.e. archive === 0

          // console.log(a.map(x=>x.archive));

          if (sessionStorage.getItem("role") === "Student") {
            var batch = sessionStorage.getItem("batch")?.toLowerCase();
            var location = sessionStorage.getItem("location")?.toLowerCase();
            var data = [];
            // So that Student can see only his Batch messages or messages that are his location specific
            a.forEach((x) => {
              if ((x.location_name?.toLowerCase() === location || x.location_name === "all") && (x.batch_name?.toLowerCase() === batch || x.batch_name === null)) {
                data.push(x);
              }
            });
            setLoad(false);
            data.length > 0 ? setBulletin_data(data) : setBulletin_data(false);
          } else if (sessionStorage.getItem("role") === "Teacher") {
            axios
              .post(url + "select_teacher_params", { uname: sessionStorage.getItem("username") })
              .then((res) => {
                console.log(res);
                if (res.data.success) {
                  var batch_name_arr = res.data.batch_data.map((a) => a.batch_name?.toLowerCase());
                  var location_name_arr = res.data.location_data.map((a) => a.location_name?.toLowerCase());
                  // var level_name_arr = res.data.level_data.map(a => a.level_name);

                  // So the Teacher can only see messages of his location  & of only his batches..
                  var filter_one = a.filter((loc) => (loc.location_name === "all" || location_name_arr.indexOf(loc.location_name?.toLowerCase()) >= 0)); //Filter 1 sorting same location
                  var filter_two = filter_one.filter((loc) => batch_name_arr.indexOf(loc.batch_name?.toLowerCase()) >= 0); //Filter 2 sorting same batch
                  var filter_three = filter_one.filter((x) => x.batch_name === null);

                  var final_filter = [...filter_two, ...filter_three];

                  setLoad(false);
                  final_filter.length > 0 ? setOriginalData(final_filter) : setOriginalData([]);
                  final_filter.length > 0 ? setBulletin_data(final_filter) : setBulletin_data(false);
                } else toast.error("An error occurred, Location & Batch of teacher can't be fetched.");
              })
              .catch((res) => console.log(res));
          } else if (sessionStorage.getItem("role") === "Location Manager") {

            let manager_loc_name = sessionStorage.getItem("location")?.toLowerCase().split(",");
            // So the Manager can only see all the messages of his locations.
            let filter = a.filter((loc) => (loc.location_name === "all" || manager_loc_name.indexOf(loc.location_name?.toLowerCase()) >= 0)); //Filter 1 sorting same location
            setLoad(false);

            filter.length > 0 ? setOriginalData(filter) : setOriginalData([]);
            filter.length > 0 ? setBulletin_data(filter) : setBulletin_data(false);

          } else {

            setLoad(false);
            arch.length > 0 ? setArchive_originalData(arch) : setArchive_originalData([]);
            arch.length > 0 ? setArchived(arch) : setArchived(false);

            a.length > 0 ? setOriginalData(a) : setOriginalData([]);
            a.length > 0 ? setBulletin_data(a) : setBulletin_data(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // ------------------- For Editing the form data we use editData and onEdit function --------------

  const [editData, setEditData] = useState({
    location_name: "",
    bull_message: "",
    id: "",
    batch_name: "",
    external_link: "",
  });

  const onEdit = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.value;

    if (name === "location_name") setEditData({ ...editData, batch_name: "", [name]: value });
    else setEditData({ ...editData, [name]: value });

  };

  // ----------- This function will Select data before Editing bulletin message --------------------

  const EditMessage = (id, lname, bname) => {
    setEdit_msg_Open(true);
    setEdit_bulletin_spinner(true);
    const obj = { uname: sessionStorage.getItem("username"), id: id, location_name: lname, batch_name: bname };
    axios
      .post(url + "select_bulletin_link", obj)
      .then((response) => {
        if (response.data.success) {
          let a = response.data.data;
          console.log(a);
          setEditData((preValue) => {
            return { ...preValue, location_name: a.location_name, bull_message: a.bull_message, id: a.bulletin_id, batch_name: a.batch_name, external_link: a.external_link };
          });
          const blocksFromHtml = htmlToDraft(a.bull_message);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setEditorState(EditorState.createWithContent(contentState)); // Bring data in Edit Dialog's Editor
          let currentContentAsHTML = draftToHtml(EditorState.createWithContent(contentState));
          setConvertedContent(currentContentAsHTML);
        }
        setEdit_bulletin_spinner(false);
      })
      .catch((err) => console.log(err));
  };

  //-------------------------- onEditSubmit function for Submitting the Edited Form data---------------------------

  const onEditSubmit = (e) => {
    e.preventDefault();
    setEdit_bulletin_spinner(true);
    /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
    let newdate = CurrentDate();
    // console.log(convertedContent);
    // ------------Storing form data in "obj" object fetched from various input fields-------------
    const obj = {
      uname: sessionStorage.getItem("username"),
      location_name: editData.location_name !== "all" ? editData.location_name : "all",
      batch_name: editData.location_name !== "all" ? editData.batch_name : "",
      bull_message: convertedContent ? PurifyHtml(convertedContent) : editData.bull_message,
      id: editData.id,
      updated_by: sessionStorage.getItem("username"),
      fname: sessionStorage.getItem("userFname"), lname: sessionStorage.getItem("userLname"),
      updated_date: newdate, external_link: editData.external_link
    };
    // ------------Sending form data stored in "obj" object to the add_student file using AXIOS-------------
    // console.log(obj);
    axios
      .post(url + "edit_bulletin", obj)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          toast.success("Updated successfully!");
          setBulletinFile({ ...bulletinFile, file: null });
          loadBulletin();

        } else toast.error("Message Not updated, Please try again");
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setEdit_bulletin_spinner(false);
        setEdit_msg_Open(false);
        setEditData({ location_name: "", bull_message: "", id: "", batch_name: "", external_link: "" });
        setEditorState(EditorState.createEmpty());
        setBulletinFile({ ...bulletinFile, file: null });
      });
  };

  // -----------This function will Delete bulletin message--------------------
  const Delete = async (id) => {
    // if(delete_data === true){
    setBackdrop_open(true);
    const obj = { uname: sessionStorage.getItem("username"), id: id };
    await axios
      .post(url + "delete_bulletin", obj)
      .then((response) => {
        if (response.data.success) {
          toast.info("Bulletin Deleted");
          loadBulletin();
        } else {
          toast.error("An error occurred while deleting the bulletin, please try again later");
        }
      })
      .catch((err) => console.log(err));
    setBackdrop_open(false);
    // setDelete_data(false);
    // }
  };

  // -----------This function will Archive bulletin message--------------------
  const Archive = async (id, bool) => {
    setBackdrop_open(true);

    var arch_value = bool ? 1 : 0;

    const obj = {
      uname: sessionStorage.getItem("username"),
      id: id,
      archive: arch_value,
      updated_by: sessionStorage.getItem("username"),
      updated_date: CurrentDate(),
    };

    await axios
      .post(url + "archive_bulletin", obj)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          bool ? toast.info("Bulletin archived") : toast.info("Bulletin Unarchived");
          loadBulletin();
        } else {
          toast.error("Error occurred while archiving the bulletin, please try later");
        }
      })
      .catch((err) => console.log(err));
    setBackdrop_open(false);
  };

  //----------- For Adding the form data we use data and onAdd function------------
  const [data, setData] = useState({
    location_name: "",
    bull_message: "",
    batch_name: "",
    external_link: "",
    mail_parent: false,
    mail_student: false,
  });

  // ====== Below state is created to manage the file and id of a particular bulletin message
  const [bulletinFile, setBulletinFile] = useState({
    file: null, id: ""
  });

  const onAdd = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.value;
    if (name === "location_name") setData({ ...data, batch_name: "", [name]: value });
    else setData({ ...data, [name]: value });
  };

  //=======================================================
  const AddBulletinFile = (e) => {
    let file = e.target.files[0];
    setBulletinFile((pre) => {
      return { ...pre, file: file };
    });
  };

  // ======================================================

  useEffect(() => {
    convertContentToHTML();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {

    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(currentContentAsHTML);
  };

  //-------------------------- onSubmit function for Submitting the Add bulletin Forms---------------------------
  const onAddSubmit = async (e) => {
    setAdd_bulletin_spinner(true);
    e.preventDefault();

    /*------Fetching Date in YEAR-MONTH-DATE  format-------- */

    const obj = {
      uname: sessionStorage.getItem("username"),
      location_name: data.location_name !== "all" ? data.location_name : "all",
      batch_name: data.location_name !== "all" ? data.batch_name : "",
      bull_message: PurifyHtml(convertedContent),
      // bull_message: convertedContent,
      fname: sessionStorage.getItem("userFname"), lname: sessionStorage.getItem("userLname"),
      external_link: data.external_link, mail_parent: data.mail_parent, mail_student: data.mail_student,
      created_by: sessionStorage.getItem("username"),
    };

    console.log(obj);
    const formData = new FormData();
    Object.keys(obj).forEach(key => formData.append(key, obj[key]));

    formData.append("file", bulletinFile.file);
    // console.log(...formData);

    await axios
      .post(url + "add_bulletin", formData)
      .then((res) => {
        console.log(res);
        // console.log(res.data);
        if ((res.data.success)) {
          toast.success("Message posted successfully.");
          loadBulletin();
        }
        else {
          console.log(res.data.message);
          toast.error("Message posting failed. Retry later.");
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setOpen(false);
        setAdd_bulletin_spinner(false);
        setData({ ...data, mail_parent: false, mail_student: false, location_name: "", bull_message: "", external_link: "" });
        setEditorState(EditorState.createEmpty());
        setBulletinFile({ ...bulletinFile, file: null });
      });
  };

  // ---------++++++++++++++++ Search Submit ++++++++++++++++++----------------
  const SearchSubmit = () => {
    var location = searchField.location.map((a) => a.value);
    let filter =
      location.length === 0
        ? originalData
        : originalData.filter((bulletin) => location.includes(bulletin.location_name)); // Filtering bulletin by location Name
    filter.length > 0 ? setBulletin_data(filter) : setBulletin_data(false);
  };

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ PANE - 2 - Data +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ PANE - 2 - Data +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // ----------------------- For fetching all Locations for select Inputs ------------------------------------------------
  const [loc, setLoc] = useState([]);

  // ----------------------- For fetching all Batch for select Inputs ------------------------------------------------
  const [batch, setBatch] = useState([]);

  // ============================
  const [loc_id, setLoc_id] = useState("");

  // =============================
  const [search_spinner, setSearch_spinner] = useState(false);


  // ===============================

  const [addEdit_bulletinFile_popup, setAddEdit_bulletinFile_popup] = useState(false);
  const [bulletinFile_upload_spinner, setBulletinFile_upload_spinner] = useState(false);

  // ===============================
  const [upload_file_popup, setUpload_file_popup] = useState(false);
  const [file_upload_spinner, setFile_upload_spinner] = useState(false);

  // ===============================
  const [upload_link_popup, setUpload_link_popup] = useState(false);
  const [link_upload_spinner, setLink_upload_spinner] = useState(false);

  // =============================
  const [show_file_and_link, setShow_file_and_link] = useState(false);

  // ============================

  const [edit_link_file, setEdit_link_file] = useState({
    location_file: null,
    batch_link: "",
    batch_id: "",
  });

  // =============================
  const [file_link_data, setFile_link_data] = useState({
    location: [],
    batch_data: [],
  });

  // =============================
  const [teacher_data, setTeacher_data] = useState({
    location: [],
    batch: [],
    level: [],
  });

  // =============================
  const [manager_data, setManager_data] = useState({
    location: [],
  });

  // ----------- This function will Fetch all the Existing Locations --------------------

  const loadLocation = () => {
    const obj = { uname: sessionStorage.getItem("username") };

    axios
      .post(url + "fetch_locations", obj)
      .then((response) => {
        // console.log(response);
        if (response.data.success) {
          var All_locations = response.data.data;
          // console.log(All_locations);

          //---This is for teacher and Student Login ------------
          if (sessionStorage.getItem("role") === "Teacher" || sessionStorage.getItem("role") === "Student" || sessionStorage.getItem("role") === "Location Manager") {
            Get_LinkAndFile_For_StudentTeacherManager_Role(All_locations);
          } else {
            setLoc(All_locations);
          }
        }

      })
      .catch((err) => console.log(err));
  };

  // ------------------------Fetching Batch table's data-------------------------------------
  const loadBatches = () => {
    const obj = { uname: sessionStorage.getItem("username") };

    axios
      .post(url + "fetch_batches", obj)
      .then((response) => {
        console.log(response);
        let a = response.data.data;
        // console.log(a);
        setBatch(a);
      })
      .catch((err) => console.log(err));
  };

  //=======================================================
  const onChange_File = (e) => {
    let file = e.target.files[0];
    setEdit_link_file((pre) => {
      return { ...pre, location_file: file };
    });
  };

  //--------------- On Location_File_Submit function for Uploading New Location File for the selected Location--------------

  const onBulletinFileSubmit = () => {
    setBulletinFile_upload_spinner(true);

    var uname = sessionStorage.getItem("username");
    var data = new FormData();

    data.append("file", bulletinFile.file);
    data.append("id", bulletinFile.id);
    data.append("updated_by", uname);
    data.append("updated_date", CurrentDate());
    data.append("uname", uname);
    // console.log(...data);
    axios
      .post(url + "edit_file_link", data)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          loadBulletin();
          setBulletinFile_upload_spinner(false);
          toast.success("File Uploaded successfully");
          setAddEdit_bulletinFile_popup(false); // For closing the Popup
          setBulletinFile({ id: "", file: null });
        } else {
          setBulletinFile_upload_spinner(false);
          setAddEdit_bulletinFile_popup(false); // For closing the Popup 
          toast.error("File not updated, please try again later.");
          setBulletinFile({ id: "", file: null });
        }
      })
      .catch((err) => console.log(err));
  };


  //--------------- On Location_File_Submit function for Uploading New Location File for the swelected Location--------------

  const onLocation_File_Submit = () => {
    setFile_upload_spinner(true);
    var uname = sessionStorage.getItem("username");
    var data = new FormData();
    data.append("file", edit_link_file.location_file);
    data.append("location_id", file_link_data.location[0].location_id);
    // // data.append("calender_link", field.calender_link);
    data.append("uname", uname);

    axios
      .post(url + "add_link", data)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          Fetch_Link_and_File();
          setFile_upload_spinner(false);
          toast.success("File Uploaded successfully");
          setUpload_file_popup(false); // For closing the Popup
          setEdit_link_file((pre) => {
            return { ...pre, location_file: null };
          });
        } else {
          setFile_upload_spinner(false);
          setUpload_file_popup(false); // For closing the Popup
          setEdit_link_file((pre) => {
            return { ...pre, location_file: null };
          });
          toast.error("File not updated, Please try again later.");
        }
      })
      .catch((err) => console.log(err));
  };

  //--------------- On Batch_link_Submit function Updates the Batch Link for a prticular location  --------------

  const onBatch_link_Submit = (e) => {
    e.preventDefault();
    setLink_upload_spinner(true);
    var newDate = CurrentDate();
    const obj = {
      uname: sessionStorage.getItem("username"),
      updated_by: sessionStorage.getItem("username"),
      id: edit_link_file.batch_id,
      batch_calendar_link: edit_link_file.batch_link,
      updated_date: newDate,
    };

    axios
      .post(url + "edit_batch_calendar", obj)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          Fetch_Link_and_File();
          setLink_upload_spinner(false);
          toast.success("Link Uploaded successfully");
          setUpload_link_popup(false); // For closing the Popup
          setEdit_link_file((pre) => {
            return { ...pre, batch_link: "" };
          });
        } else {
          setLink_upload_spinner(false);
          setUpload_link_popup(false); // For closing the Popup
          setEdit_link_file((pre) => {
            return { ...pre, batch_link: "" };
          });
          toast.error("Link not Uploaded, Please try again later.");
        }
      })
      .catch((err) => console.log(err));
  };
  // -----------------------------------------------------------------------------------------
  const Get_LinkAndFile_For_StudentTeacherManager_Role = (ALL_LOCATIONS) => {

    if (sessionStorage.getItem("role") === "Student") {
      let stu_loc_name = sessionStorage.getItem("location")?.toLowerCase();
      let stu_batch_name = sessionStorage.getItem("batch")?.toLowerCase();

      let loc_name = ALL_LOCATIONS.filter((loc) => loc.name?.toLowerCase() === stu_loc_name);
      // console.log(loc_name);
      let stu_loc_id = loc_name[0].location_id;
      setLoc_id(loc_name[0].location_id);

      const obj = {
        uname: sessionStorage.getItem("username"),
        location_id: stu_loc_id,
      };

      axios
        .post(url + "select_link", obj)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            let loc_data = response.data.loc_data;
            let bth_data = response.data.data;

            let new_batch_data = bth_data === null ? [] : bth_data.filter((bth) => bth.batch_name?.toLowerCase() === stu_batch_name);

            setFile_link_data((pre) => {
              return { ...pre, location: loc_data, batch_data: new_batch_data !== null ? new_batch_data : [] }; // Sorting batches for the Student
            });
          }
        })
        .catch((err) => console.log(err));
      setShow_file_and_link(true);

    } else if (sessionStorage.getItem("role") === "Location Manager") {
      let manager_loc_name = sessionStorage.getItem("location")?.toLowerCase().split(',');

      setManager_data((pre) => {
        return {
          ...pre,
          location: manager_loc_name,
        };
      });

      let loc_names = ALL_LOCATIONS.filter((loc) => manager_loc_name.indexOf(loc.name?.toLowerCase()) >= 0);
      // console.log(loc_names);
      setLoc(loc_names);

    } else if (sessionStorage.getItem("role") === "Teacher") {
      //----------------------------------------------------------------------------------------------
      axios
        .post(url + "select_teacher_params", { uname: sessionStorage.getItem("username") })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            var batch_name_arr = res.data.batch_data.map((a) => a.batch_name?.toLowerCase());
            var location_name_arr = res.data.location_data.map((a) => a.location_name?.toLowerCase());
            var level_name_arr = res.data.level_data.map((a) => a.level_name?.toLowerCase());

            // console.log(batch_name_arr);console.log(location_name_arr);console.log(level_name_arr);

            setTeacher_data((pre) => {
              return {
                ...pre,
                location: location_name_arr,
                batch: batch_name_arr,
                level: level_name_arr,
              };
            });

            var locations_for_teacher = ALL_LOCATIONS.filter((a) => location_name_arr.indexOf(a.name?.toLowerCase()) >= 0);
            setLoc(locations_for_teacher);
          } else toast.error("Error occurred while fetching the teacher's location and batch details.");
        })
        .catch((res) => console.log(res));
      //----------------------------------------------------------------------------------------------
    } else {
    }
  };

  //-------------------Fetching Google calender links for each batch and a location PDF file for a particular location-----------------

  const Fetch_Link_and_File = async () => {
    // Student cant See this search button, so can't access this Fetch link & File function
    if (loc_id !== "") {
      // So no need for giving validation for "STUDENT" here
      setSearch_spinner(true);
      setShow_file_and_link(false);
      const obj = {
        uname: sessionStorage.getItem("username"),
        location_id: loc_id,
      };

      await axios
        .post(url + "select_link", obj)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            var loc_data = response.data.loc_data;
            var bth_data = response.data.data;

            // console.log(loc_data);
            // console.log(bth_data);

            if (sessionStorage.getItem("role") === "Teacher") {
              if (bth_data !== null) {
                //  teacher_data.batch array is already in LowerCase, so no need to make it lower again
                var new_bth_data = bth_data.filter((a) => teacher_data.batch.indexOf(a.batch_name?.toLowerCase()) >= 0);
                setFile_link_data((pre) => {
                  return { ...pre, location: loc_data, batch_data: new_bth_data !== null ? new_bth_data : [] };
                });
              } else {
                setFile_link_data((pre) => {
                  return { ...pre, location: loc_data, batch_data: [] };
                });
              }
            } else {
              // This else is for Admin / SuperAdmin /Systemadmin & Manager
              setFile_link_data((pre) => {
                return { ...pre, location: loc_data, batch_data: bth_data !== null ? bth_data : [] };
              });
            }
          }
        })
        .catch((err) => console.log(err));

      setShow_file_and_link(true);
      setSearch_spinner(false);
    }
  };

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ PANE - 3 - Data +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ PANE - 3 - Data +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // ---------++++++++++++++++ Search Submit ++++++++++++++++++----------------
  const ArchiveTabSearchSubmit = () => {
    var location = archive_tab_searchfield.location.map((a) => a.value);
    let filter =
      location.length === 0
        ? archive_originalData
        : archive_originalData.filter((bulletin) => location.includes(bulletin.location_name)); // Filtering bulletin by location Name
    filter.length > 0 ? setArchived(filter) : setArchived(false);
  };

  return (
    <>
      {/* =============================================================================================== */}
      {/* ================================= Dialog popup for add bulletin  ============================== */}

      <Dialog fullWidth={true} maxWidth="lg" open={open}>
        <DialogTitle id="max-width-dialog-title">Add Bulletin Message <hr className="p-0 mt-2" /></DialogTitle>
        <DialogContent>
          <form onSubmit={onAddSubmit} id="submitForm">
            <div className="row px-3 py-3">
              <div className="col-11 col-md-12 mx-auto ">

                <div className="mb-3 row">
                  <label className="col-1 col-form-label">Location</label>
                  <div className=" col-md-5 ">

                    <select name="location_name" value={data.location_name} onChange={onAdd} className="form-select" required>
                      <option value="">Choose</option>
                      <option value="all">For All Locations</option>
                      {loc.map((data, i) => (
                        <option key={i} value={data.name}>{data.name}</option>
                      ))}
                    </select>

                  </div>

                  <label className="col-1 col-form-label">Batch</label>
                  <div className="col-md-5 ">
                    <select name="batch_name" value={data.batch_name} onChange={onAdd} className="form-select">

                      <option value="">{data.location_name === "" ? `Choose` :
                        (data.location_name === "all" ? `All Batches` : `All Batches under ${data.location_name}`)
                      }
                      </option>
                      {batch
                        .filter((x) => x.location_name === data.location_name)
                        .map((x, i) => <option key={i} value={x.batch_name}>{x.batch_name}</option>)
                      }
                    </select>
                  </div>
                </div>

                <div className="mb-3 row my-4">
                  <label className=" col-auto col-form-label">Add Message</label>

                  <div className="col-12">
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={handleEditorChange}
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      toolbar={Toolbar}
                    />
                  </div>

                </div>

                <div className="mb-3 row">
                  <div className="col-6 d-flex align-items-center">
                    <label className=" col-form-label">Choose File</label>
                    <div className=" ms-4">
                      <input type="file" name="bulletin_file" onChange={AddBulletinFile} />
                    </div>
                  </div>
                  <label className=" col-1 col-form-label">Add Link</label>
                  <div className="col-md-5">
                    <input type="text" className="form-control" name="external_link" value={data.external_link} onChange={onAdd}
                    />
                  </div>
                </div>


                <Divider />
                <Grid container direction="row" alignItems="center">
                  <FormControlLabel className="mt-3"
                    control={<Switch checked={data.mail_parent} color="primary" onChange={(e) => setData({ ...data, mail_parent: e.target.checked })} />}
                    label="Send mail to the parents"
                  />
                  <Divider orientation="vertical" flexItem className="me-3" />
                  <FormControlLabel className="mt-3"
                    control={<Switch checked={data.mail_student} color="primary" onChange={(e) => setData({ ...data, mail_student: e.target.checked })} />}
                    label="Send mail to the student"
                  />
                  <Divider orientation="vertical" flexItem className="me-3" />

                  <div className="alert alert-warning m-0 px-2 pt-1 pb-0" role="alert"><ErrorOutlineIcon className="mb-1" /> As per Google policy, an <strong>Embed</strong> can't be inserted into mail content.</div>
                </Grid>
                <Divider />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isEditorEmpty() && data.external_link === '' && !bulletinFile.file) {
                setOpen(false);
                setBulletinFile({ id: "", file: null });
                setEditorState(EditorState.createEmpty());
                setData({ ...data, mail_parent: false, mail_student: false, location_name: "", bull_message: "", external_link: "" });
              } else {

                confirm({
                  title: "Closing Confirmation",
                  description: "Are you sure you want to close this dialog? Please be aware that your changes will be lost.",
                  confirmationText: 'Yes'
                }).then(() => {
                  setOpen(false);
                  setBulletinFile({ id: "", file: null });
                  setEditorState(EditorState.createEmpty());
                  setData({ ...data, mail_parent: false, mail_student: false, location_name: "", bull_message: "", external_link: "" });
                });
              }
            }}
            color="primary">Close</Button>
          <Button form="submitForm" color="primary" type="submit" disabled={add_bulletin_spinner}> {!add_bulletin_spinner ? "Save" : <CircularProgress size={20} />}</Button>
        </DialogActions>
      </Dialog>

      {/* =============================== Dialog popup for Edit bulletin  =====================================*/}

      <Dialog fullWidth={true} maxWidth="lg" open={edit_msg_open}>
        <DialogTitle id="max-width-dialog-title">
          Edit Bulletin Message <hr className="p-0 mt-2" />{" "}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onEditSubmit} id="editSubmit">
            <div className="row px-3 ">
              <div className="col-11 col-md-12 mx-auto ">

                <div className="mb-3 row">
                  <label className="col-1 col-form-label">Location</label>
                  <div className=" col-md-5 ">
                    <select name="location_name" value={editData.location_name} onChange={onEdit} className="form-select">
                      <option value="">Choose</option>
                      <option value="all">For All Locations</option>
                      {loc.map((data, i) => (
                        <option key={i} value={data.name}>{data.name}</option>
                      ))}
                    </select>
                  </div>

                  <label className="col-1 col-form-label">Batch</label>
                  <div className="col-md-5 ">
                    <select name="batch_name" value={editData.batch_name} onChange={onEdit} className="form-select">
                      <option value="">
                        {editData.location_name === "" ? `Choose` :
                          (editData.location_name === "all" ? `All Batches` : `All Batches under ${editData.location_name}`)
                        }
                      </option>
                      {batch
                        .filter((x) => x.location_name === editData.location_name)
                        .map((x, i) => {
                          return (
                            <option key={i} value={x.batch_name}>{x.batch_name} </option>
                          );
                        })}
                    </select>
                  </div>
                </div>



                <div className="mb-3 row">
                  <label className=" col-sm-4 col-form-label">Message</label>

                  <div className="col-12">
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={handleEditorChange}
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      toolbar={Toolbar}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className=" col-auto col-form-label">Add Link</label>
                  <div className="col-md-5">
                    <input type="text" className="form-control" name="external_link" value={editData.external_link} onChange={onEdit} />
                  </div>
                </div>


              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button

            onClick={() => {
              confirm({
                title: "Closing Confirmation",
                description: "Are you sure you want to close this edit dialog? Please be aware that your changes will be lost.",
                confirmationText: 'Yes'
              }).then(() => {
                setEdit_msg_Open(false);
                setEditorState(EditorState.createEmpty());
                setEditData({ location_name: "", bull_message: "", id: "", batch_name: "", external_link: "" });
              });
            }}
            color="primary">Close</Button>
          <Button className="me-4" form="editSubmit" color="primary" type="submit" disabled={edit_bulletin_spinner}> {!edit_bulletin_spinner ? "Save" : <CircularProgress size={20} />}</Button>
        </DialogActions>
      </Dialog>

      {/* =============================== Dialog popup for Add or Edit of Bulletin message file  =====================================*/}

      <Dialog fullWidth={true} maxWidth="sm" open={addEdit_bulletinFile_popup}>
        <DialogTitle id="max-width-dialog-title">
          Upload / Update File <hr className="p-0 mt-2 mb-0" />
        </DialogTitle>
        <DialogContent>
          <div className="row ">
            <div className="col-12 ">
              <label className="form-label">Add File</label>
              <div>
                <input type="file" className="form-control-file" name="bulletin_file" onChange={(e) => {
                  let file = e.target.files[0];
                  // console.log(file);
                  setBulletinFile({ ...bulletinFile, file: file });
                }} />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setAddEdit_bulletinFile_popup(false);
              setBulletinFile_upload_spinner(false);
              setBulletinFile({ ...bulletinFile, file: null });
            }}>
            Close
          </Button>
          <Button onClick={() => onBulletinFileSubmit()} color="primary" disabled={bulletinFile_upload_spinner}> {!bulletinFile_upload_spinner ? "Save" : <CircularProgress size={20} />}</Button>
        </DialogActions>
      </Dialog>

      {/* =============================== Dialog popup for Edit Location Calendar  =====================================*/}

      <Dialog fullWidth={true} maxWidth="sm" open={upload_file_popup}>
        <DialogTitle id="max-width-dialog-title">
          Upload Location Calendar File <hr className="p-0 mt-2 mb-0" />{" "}
        </DialogTitle>
        <DialogContent>

          <div className="mb-3 ">
            <label className="form-label w-100" style={{ fontWeight: 500 }}>{`${file_link_data.location.length > 0 ? file_link_data.location[0].name : ""
              } Location`}</label>
            <input type="file" className="form-control-file" name="location_file" onChange={onChange_File} />
          </div>

        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setUpload_file_popup(false);
              setFile_upload_spinner(false);
              setEdit_link_file((pre) => {
                return { ...pre, location_file: null };
              });
            }}>
            Close
          </Button>
          <Button onClick={() => onLocation_File_Submit()} color="primary" disabled={file_upload_spinner}> {!file_upload_spinner ? "Save" : <CircularProgress size={20} />}</Button>
        </DialogActions>
      </Dialog>

      {/* =============================== Dialog popup for Edit Batch  Calendar link =====================================*/}

      <Dialog fullWidth={true} maxWidth="sm" open={upload_link_popup}>
        <DialogTitle id="max-width-dialog-title">
          Add Google Batch Calendar Link <hr className="p-0 mt-2 mb-0" />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onBatch_link_Submit} id="Batch_link_submit_form">
            <div className="row ">
              <div className="col-12 ">
                <div className="mb-3">
                  <label className="form-label" style={{ fontWeight: 500 }}>Select Batch</label>
                  <select
                    className="form-control"
                    required
                    onChange={(e) => {
                      let bth_id = e.target.value.split("*")[0];
                      let cal_link = e.target.value.split("*")[1];
                      setEdit_link_file((pre) => {
                        return { ...pre, batch_id: bth_id, batch_link: cal_link };
                      });
                    }}>
                    <option value="">Choose</option>
                    {file_link_data.batch_data.map((x, i) => {
                      return (
                        <option key={i} value={`${x.batch_id}*${x.batch_calendar_link}`}>
                          {x.batch_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="mb-3 ">
                  <label className="form-label" style={{ fontWeight: 500 }}>Add Link</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    name="batch_link"
                    value={edit_link_file.batch_link}
                    onChange={(e) => {
                      let value = e.target.value;
                      setEdit_link_file((pre) => {
                        return { ...pre, batch_link: value };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary"
            onClick={() => {
              setUpload_link_popup(false);
              setEdit_link_file((pre) => {
                return { ...pre, batch_link: "", batch_id: "" };
              });
            }}>Close</Button>
          <Button form="Batch_link_submit_form" type="submit" color="primary" disabled={link_upload_spinner}> {!link_upload_spinner ? "Save" : <CircularProgress size={20} />}</Button>
        </DialogActions>
      </Dialog>

      {/* =====================$$$$$$$$$$$$$$------ Normal flow of CODE -----------$$$$$$$$$$$$$$======================== */}

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Bulletin Board
            </li>
          </ol>
        </nav>

        <Heading name="Bulletin Board" />

        <div className="container-fluid">
          {/* -----------------*********** Nav Tab **********-------------- */}
          <div className="border-bottom pt-2">
            <nav>
              <div className="nav " id="nav-tab" role="tablist">
                <a className="nav_tab nav-link active" data-bs-toggle="tab" href="#nav-update" role="tab" aria-controls="nav-update" aria-selected="true"> Updates
                </a>
                <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-link" role="tab" aria-controls="nav-link" aria-selected="false"> Important Links
                </a>
                {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" || sessionStorage.getItem("role") === "Admin") && (
                  <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-archive" role="tab" aria-controls="nav-archive" aria-selected="false"> Archived Updates
                  </a>
                )}
              </div>
            </nav>
          </div>
          {/* -----------------*****%%%%%%%%%%%%%%%%%%%%%%****** Three tab pane *****%%%%%%%%%%%%%%%%%%%%%%*****-------------- */}
          <div className="tab-content">
            {/* ------==========$$$$$$$$$$$$$========== Pane 1 ========$$$$$$$$$$$$$$============----------- */}
            <div className="tab-pane fade show active" id="nav-update" role="tabpanel">
              <div className="container-fluid mt-3">
                {sessionStorage.getItem("role") !== "Student" && (
                  <div className="row d-flex justify-content-between">
                    {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" ||
                      sessionStorage.getItem("role") === "Admin" || sessionStorage.getItem("role") === "Location Manager") && (
                        <div className="col-auto">
                          <Tooltip title="Add Bulletin" placement="top" >
                            <IconButton className="shadow m-2" onClick={() => setOpen(true)}>
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}

                    <div className="ms-auto col-md-2" >
                      <Select
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isMulti
                        closeMenuOnSelect={false}
                        options={loc.map((x) => ({ label: x.name, value: x.name }))}
                        value={searchField.location}
                        onChange={(e) => {
                          setSearchField((pre) => {
                            return {
                              ...pre,
                              location: e,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="row mt-2">
                  <div className="col-12  d-block">
                    {/* <h5 className="mb-4 heading">Updates</h5> */}

                    {load ?

                      <LoadingSpinner />

                      : bulletin_data ? (
                        bulletin_data
                          .slice(0)
                          .reverse()
                          .map((data) => {
                            return (
                              <Card
                                key={data.bulletin_id}
                                style={{ border: 0, borderLeft: "3px solid #174873" }}
                                className="mb-4 pt-1 px-3 pb-1 position-relative rounded-0">
                                <div className="d-flex align-items-center ">

                                  <p className="m-0 d-flex align-items-center ">
                                    <DoubleArrowIcon fontSize="small" style={{ color: "#e89e01" }} />&nbsp;&nbsp;{`${data.fname} ${data.lname}`}
                                  </p>

                                  {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" ||
                                    sessionStorage.getItem("role") === "Admin") && (
                                      <div className="d-flex align-items-center ms-auto">

                                        <Tooltip title={data.file_link === "" ? "Attach File" : "Edit File"} placement="top" >
                                          <IconButton color="inherit" onClick={() => {
                                            setBulletinFile({ ...bulletinFile, id: data.bulletin_id });
                                            setAddEdit_bulletinFile_popup(true);
                                          }}>
                                            <AttachFileIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Archive" placement="top" >
                                          <IconButton id="arch-btn" onClick={() => Archive(data.bulletin_id, true)}>
                                            <ArchiveIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Delete" placement="top" >
                                          <IconButton
                                            id="del-btn"
                                            onClick={() => {
                                              confirm({
                                                title: "Delete Confirmation",
                                                description: "This will delete the selected bulletin permanently.",
                                              }).then(() => Delete(data.bulletin_id));
                                            }}>
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Edit" placement="top" >
                                          <IconButton
                                            id="edit-btn"
                                            onClick={() => {
                                              EditMessage(data.bulletin_id, data.location_name, data.batch_name);
                                            }}>
                                            <EditIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    )}

                                  {(sessionStorage.getItem("role") === "Location Manager" && data.created_by === sessionStorage.getItem("username")) && (
                                    <div className="d-flex align-items-center ms-auto">

                                      <Tooltip title="Delete" placement="top" >
                                        <IconButton
                                          id="del-btn"
                                          onClick={() => {
                                            confirm({
                                              title: "Delete Confirmation",
                                              description: "This will delete the selected bulletin permanently.",
                                            }).then(() => Delete(data.bulletin_id));
                                          }}>
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>


                                      <Tooltip title="Edit" placement="top" >
                                        <IconButton
                                          id="edit-btn"
                                          onClick={() => {
                                            // setEditData({...editData , location_name: sessionStorage.getItem("location")})
                                            EditMessage(data.bulletin_id, data.location_name, data.batch_name);
                                          }}>
                                          <EditIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  )}

                                </div>
                                <hr className="m-0" />
                                <CardContent>
                                  <div className="shift-list-element-right" dangerouslySetInnerHTML={{ __html: data.bull_message }}></div>
                                  {(data.external_link || data.file_link) &&
                                    <div className="mt-4 mb-3">
                                      <hr />
                                      <ul className="list-group list-group-horizontal">
                                        {data.file_link && <li className="list-group-item">
                                          <a
                                            href={`${storageUrl}${data.file_link}`}
                                            download
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <AttachmentIcon /> View Attachment
                                          </a>
                                        </li>}
                                        {data.external_link && <li className="list-group-item">
                                          <a
                                            href={`${data.external_link}`}
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <LinkIcon /> Visit Link
                                          </a>
                                        </li>}
                                      </ul>
                                    </div>
                                  }
                                  {data.location_name === "all" ?
                                    <p
                                      className="position-absolute text-muted"
                                      style={{ bottom: "-.5em", right: "1.7em", fontWeight: "500", fontSize: ".9em" }}>
                                      {`For All Locations | ${ReadableDate(data.created_at)}`}
                                    </p> :
                                    <p
                                      className="position-absolute text-muted"
                                      style={{ bottom: "-.5em", right: "1.7em", fontWeight: "500", fontSize: ".9em" }}>
                                      {`${data.location_name} - `}{data.batch_name === null ? `Location Specific | ${ReadableDate(data.created_at)}` : `${data.batch_name} | ${ReadableDate(data.created_at)}`}
                                    </p>}
                                </CardContent>
                              </Card>
                            );
                          })
                      ) : (
                        <h6 className="d-flex align-items-center">
                          <ErrorOutlineIcon className="me-2" /> No Bulletin Found
                        </h6>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* ------ ==========$$$$$$$$$$$$$========== Pane 2 ========$$$$$$$$$$$$$$============ ----------- */}
            <div className="tab-pane fade pt-3 mb-3" id="nav-link" role="tabpane2 ">
              <div className="container-fluid ">
                {(sessionStorage.getItem("role") !== "Student") && (
                  <>
                    <div className="mb-3 row g-3">
                      <label htmlFor="inp_loc" className="col-auto col-form-label fw-bold">
                        Select Location To Proceed
                      </label>
                      <div className=" col-auto col-md-2">
                        <select name="location_name" value={loc_id} onChange={(e) => setLoc_id(e.target.value)}
                          required className="form-select">
                          <option value="">Choose</option>
                          {loc.map((data, i) => <option key={i} value={data.location_id}>{data.name}</option>)}
                        </select>
                      </div>

                      <Button variant="contained" className="col-auto ms-md-auto align-self-end" onClick={Fetch_Link_and_File} type="button">
                        {search_spinner ? <CircularProgress size={20} color="inherit" /> : 'Search'}
                      </Button>
                    </div>
                    <hr className="p-0 mb-3 mx-auto" />
                  </>
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center">
                      <DoubleArrowIcon fontSize="small" />
                      <h6 className="me-3 mb-0 ms-2"> Location Calendar File</h6>
                      {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" ||
                        sessionStorage.getItem("role") === "Admin" || sessionStorage.getItem("role") === "Location Manager") &&
                        show_file_and_link && (
                          <Tooltip title="Edit File" placement="top" >
                            <IconButton
                              size="small"
                              style={{ cursor: "pointer" }}
                              id="edit-btn"
                              onClick={() => setUpload_file_popup(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                    </div>
                    <div className="ms-4">
                      {show_file_and_link && (
                        <table className="table w-auto table-borderless shadow-sm mt-3">
                          <thead>
                            <tr>
                              <th className="border-bottom " scope="col"></th>
                              <th className="border-bottom px-4" scope="col">
                                Location Name
                              </th>
                              <th className="border-bottom px-4" scope="col">
                                File
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {file_link_data.location.map((x, i) => {
                              return (
                                <tr key={i} >
                                  <td className="border-right " scope="row">
                                    {i + 1}
                                  </td>
                                  <td className=" px-4">{x.name}</td>
                                  <td className="px-4">
                                    {x.location_file !== "" ? (
                                      <a
                                        href={`${storageUrl}${x.location_file}`}
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <DateRangeIcon /> Location Calendar
                                      </a>
                                    ) : (
                                      <h6 className=" d-flex align-items-center mb-0">
                                        <ErrorOutlineIcon fontSize="small" className="me-2" /> No File present.
                                      </h6>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex  align-items-center">
                      <DoubleArrowIcon fontSize="small" />
                      <h6 className="me-3 mb-0 ms-2"> Google Batch Calendar Link</h6>
                      {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" ||
                        sessionStorage.getItem("role") === "Admin" || sessionStorage.getItem("role") === "Location Manager") &&
                        show_file_and_link &&
                        file_link_data.batch_data.length !== 0 && (
                          <Tooltip title="Edit Link" placement="top" >
                            <IconButton
                              size="small"
                              style={{ cursor: "pointer" }}
                              id="edit-btn"
                              onClick={() => setUpload_link_popup(true)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                    </div>
                    {show_file_and_link && (
                      <div className="ms-4">
                        {file_link_data.batch_data.length !== 0 ? (
                          <table className="table w-auto table-borderless shadow-sm mt-3">
                            <thead>
                              <tr>
                                <th className="border-bottom " scope="col"></th>
                                <th className="border-bottom px-4" scope="col">
                                  Batch Name
                                </th>
                                <th className="border-bottom px-4" scope="col">
                                  Link
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {file_link_data.batch_data.map((x, i) => {
                                return (
                                  <tr key={i} >
                                    <td className="border-right " scope="row">
                                      {i + 1}
                                    </td>
                                    <td className=" px-4">{x.batch_name}</td>
                                    <td className="px-4">
                                      {x.batch_calendar_link !== "" ? (
                                        <a

                                          href={`${x.batch_calendar_link}`}
                                          rel="noopener noreferrer"
                                          target="_blank">
                                          <DateRangeIcon /> Google Calendar
                                        </a>
                                      ) : (
                                        <h6 className=" d-flex align-items-center mb-0">
                                          <ErrorOutlineIcon fontSize="small" className="me-2" /> No link present.
                                        </h6>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <h6 className="mt-3">
                            {" "}
                            <ErrorOutlineIcon fontSize="small" className="me-2" />
                            No batch associated with this location.
                          </h6>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* ------==========$$$$$$$$$$$$$========== Pane 3 ========$$$$$$$$$$$$$$============----------- */}
            <div className="tab-pane fade " id="nav-archive" role="tabpane3">
              <div className="container-fluid mt-3">
                <div className="row pe-3">
                  <div className="ms-auto col-md-2">
                    <Select
                      isMulti
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      isClearable={true}
                      options={loc.map((x) => ({ label: x.name, value: x.name }))}
                      value={archive_tab_searchfield.location}
                      onChange={(e) => {
                        setArchive_tab_searchfield((pre) => {
                          return { ...pre, location: e };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12  d-block">
                    {load ?
                      <LoadingSpinner />
                      : archived ? (
                        archived
                          .slice(0)
                          .reverse()
                          .map((data) => {
                            return (
                              <Card
                                key={data.bulletin_id}
                                style={{ border: 0, borderLeft: "3px solid #174873" }}
                                className="mb-4 pt-1 px-3 pb-1 position-relative rounded-0">
                                <div className="d-flex align-items-center ">
                                  <p className="m-0 d-flex align-items-center">

                                    <DoubleArrowIcon fontSize="small" style={{ color: "#e89e01" }} />&nbsp;&nbsp;{`${data.fname} ${data.lname}`}
                                  </p>
                                  {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" ||
                                    sessionStorage.getItem("role") === "Admin") && (
                                      <div className="d-flex align-items-center ms-auto">

                                        <Tooltip title={data.file_link === "" ? "Attach File" : "Edit File"} placement="top" >
                                          <IconButton color="inherit" onClick={() => {
                                            setBulletinFile({ ...bulletinFile, id: data.bulletin_id });
                                            setAddEdit_bulletinFile_popup(true);
                                          }}>
                                            <AttachFileIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Unarchive" placement="top" >
                                          <IconButton id="arch-btn" onClick={() => Archive(data.bulletin_id, false)}>
                                            <UnarchiveIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Delete" placement="top" >
                                          <IconButton
                                            id="del-btn"
                                            onClick={() => {
                                              confirm({
                                                title: "Delete Confirmation",
                                                description: "This will delete the bulletin permanently.",
                                              }).then(() => Delete(data.bulletin_id));
                                            }}>
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Edit" placement="top" >
                                          <IconButton
                                            id="edit-btn"
                                            onClick={() =>
                                              EditMessage(data.bulletin_id, data.location_name, data.batch_name)
                                            }>
                                            <EditIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    )}
                                </div>
                                <hr className="m-0" />
                                <CardContent>
                                  <div className="shift-list-element-right" dangerouslySetInnerHTML={{ __html: data.bull_message }}></div>
                                  {(data.external_link || data.file_link) &&
                                    <div className="mt-4 mb-3">
                                      <hr />
                                      <ul className="list-group list-group-horizontal">
                                        {data.file_link && <li className="list-group-item">
                                          <a
                                            href={`${storageUrl}${data.file_link}`}
                                            download
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <AttachmentIcon /> View Attachment
                                          </a>
                                        </li>}
                                        {data.external_link && <li className="list-group-item">
                                          <a
                                            href={`${data.external_link}`}
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <LinkIcon /> Visit Link
                                          </a>
                                        </li>}
                                      </ul>
                                    </div>}

                                  {data.location_name === "all" ?
                                    <p
                                      className="position-absolute text-muted"
                                      style={{ bottom: "-.5em", right: "1.7em", fontWeight: "500", fontSize: ".9em" }}>
                                      {`For All Locations | ${ReadableDate(data.created_at)}`}
                                    </p> :
                                    <p
                                      className="position-absolute text-muted"
                                      style={{ bottom: "-.5em", right: "1.7em", fontWeight: "500", fontSize: ".9em" }}>
                                      {`${data.location_name} - `}{data.batch_name === null ? `Location Specific | ${ReadableDate(data.created_at)}` : `${data.batch_name} | ${ReadableDate(data.created_at)}`}
                                    </p>}
                                </CardContent>
                              </Card>
                            );
                          })
                      ) : (
                        <h6 className="d-flex align-items-center">
                          <ErrorOutlineIcon className="me-2" /> No Bulletin Found
                        </h6>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop style={{ zIndex: 2000 }} open={backdrop_open} ><CircularProgress style={{ color: "#fff" }} /></Backdrop>
    </>
  );
};

export default Bulletin_comp;