import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../Common/Components/Heading";


import Add from '@mui/icons-material/Add';


import axios from "axios";
import url from "../../../Common/URL";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
const MessageComp = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state] = useState({
    uname: sessionStorage.getItem("username"), role: sessionStorage.getItem("role"),
    stud_id: sessionStorage.getItem("role") === "Student" ? sessionStorage.getItem("id") : ""
  })

  const Columns = useMemo(
    () => [
      { header: 'Sent By', accessorKey: 'sender_name' },
      { header: 'Sent To', accessorKey: 'reciever_name' },
      { header: 'Subject', accessorKey: 'subject' },
      { header: 'Message', accessorKey: 'message' },
      { header: 'Sent On', accessorKey: 'sent_date' },
    ],
    [],
  );
  const [data, setData] = useState([]);


  // ------------------------Fetching Role table data-------------------------------------
  useEffect(() => {
    loadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMessages = () => {
    const obj = { uname: state.uname, role: state.role, stud_id: state.stud_id }
    axios.post(url + "select_mail", obj)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          let a = response.data.data;
        //  console.log(a);
         !a.length ? setData([]) : setData(a.reverse());
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }

      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Messages</li>
          </ol>
        </nav>

        <Heading name="Messages" />

        <div className="container-fluid mb-5">

          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end btn_compose">
              <Button variant='contained' onClick={() => navigate("/compose")} >Compose <Add /></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{
                  isLoading: loading,
                }}
                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                getRowId={(originalRow) => originalRow.mail_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-message/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

              />
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
export default MessageComp;