import React from "react"
import Navbar from "../../../Common/Components/Navbar"
import Footer from "../../../Common/Components/Footer"
// import Sidebar from "../../../Sidebar/Sidebar"
import TestSheetComp from "./Component/TestSheetComp"

const TestSheet =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            {/* <Sidebar /> */}
             <TestSheetComp/>
        </div>
        <Footer />
        </>
    )
}

export default TestSheet