import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";

import Add from '@mui/icons-material/Add';

import axios from "axios";
import url from "../../../../Common/URL";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";
import Delete from "@mui/icons-material/Delete";

const InventoryComp = () => {
  const confirm = useConfirm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [state] = useState({
    uname: sessionStorage.getItem("username"),
  });

  const Columns = useMemo(
    () => [
      { header: 'Product', accessorKey: 'product_name' },
      { header: 'Model', accessorKey: 'model' },
      { header: 'Make', accessorKey: 'make' },
      { header: 'Status', accessorKey: 'status' },
      { header: 'Price', accessorKey: 'price' },
    ],
    [],
  );

  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(() => {
    loadInventory();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInventory = () => {
    const obj = { uname: state.uname };
    axios.post(url + "fetch_inventorys", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          a !== null ? setData(a) : setData([]);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ------------------------Deleting Student table's data-------------------------------------

  const onDelete = (id, resolve) => {

    const delObj = {
      id: id,
      uname: state.uname,
      tokenId: 123,
    };
    axios.post(url + "delete_inventory", delObj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  };


  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Inventory Items</li>
            {/* <li className="breadcrumb-item active" aria-current="page">Inventory</li> */}
          </ol>
        </nav>

        <Heading name="Inventory Items" />

        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end ">
              <Button variant="contained" onClick={() => navigate("/add-item-inv")} >Add Item <Add /></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.item_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-item-inv/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <Button variant="contained" color="error" startIcon={<Delete />}
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            });
                          });
                          Promise.all(delPromise).then(() => {
                            loadInventory();
                            table.resetRowSelection();
                            toast.info(selectedRows.length + " Row(s) Deleted!");
                          });
                        });
                    }}
                  >Delete
                  </Button>
                )}
              />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default InventoryComp

