import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../../../../Common/Components/Heading";

import { Drawer } from "@mui/material";
import axios from "axios";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import url from "../../../../Common/URL";
import StudentDetailComp from "./StudentDetailComp";

const LocationStudentTableComp = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  });

  const Columns = useMemo(
    () => [

      { header: 'Name', size: 40, id: 'fname', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
      { header: 'Location', size: 40, accessorKey: 'location_name' },
      { header: 'Batch', size: 40, accessorKey: 'batch_name' },
      { header: 'Level', size: 40, accessorKey: 'level_name' },
      { header: 'Join Date', size: 40, accessorKey: 'join_date' }
    ],
    [],
  );


  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(() => {
    loadStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStudents = async () => {
    let location_name_arr = [];
    let batch_name_arr = [];
    let level_name_arr = [];

    // Get teacher parameters if the role is Teacher
    if (sessionStorage.getItem("role") === "Teacher") {
      try {
        const res = await axios.post(url + "select_teacher_params", { uname: state.uname });
        if (res.data.success) {
          batch_name_arr = res.data.batch_data.map(a => a.batch_name.toLowerCase());
          location_name_arr = res.data.location_data.map(a => a.location_name.toLowerCase());
          level_name_arr = res.data.level_data.map(a => a.level_name.toLowerCase());
        } else {
          toast.error("An error occurred. Location, Level, and Batch couldn't be fetched.");
        }
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while fetching teacher parameters.");
      }
    }

    // Fetch all students
    try {
      const response = await axios.post(url + "fetch_students", { uname: state.uname });
      if (response.data.success) {
        let filteredData = response.data.data;

        // Filter students according to Teacher's location, level, and batch
        if (sessionStorage.getItem("role") === "Teacher") {
          filteredData = filteredData.filter(stu => {
            return (
              location_name_arr.includes(stu.location_name.toLowerCase()) &&
              batch_name_arr.includes(stu.batch_name.toLowerCase()) &&
              level_name_arr.includes(stu.level_name.toLowerCase())
            );
          });
        }
        // Filter students for Location Manager
        else if (sessionStorage.getItem("role") === "Location Manager") {
          const locManagerLocations = sessionStorage.getItem("location").toLowerCase().split(',');
          filteredData = filteredData.filter(x => locManagerLocations.includes(x.location_name.toLowerCase()));
        }

        // Set data and loading state
        setData(filteredData);
        setLoading(false);
      } else {
        toast.error("An error occurred while fetching students.");
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while fetching students.");
    }
  };

  const [openStudentDetailsDrawer, setOpenStudentDetailsDrawer] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);

  return (
    <>
      {/* Side Drawer for viewing Student Details */}
      <Drawer
        PaperProps={{ style: { width: '95%' } }}
        anchor='right'
        open={openStudentDetailsDrawer}
        onClose={() => setOpenStudentDetailsDrawer(false)}
      >
        <StudentDetailComp id={selectedStudentId} setOpenDrawer={setOpenStudentDetailsDrawer} />
      </Drawer >

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Students</li>
            <li className="breadcrumb-item active" aria-current="page">Students List</li>
          </ol>
        </nav>
        <Heading name="Students List" />
        <div className="container-fluid">

          <div className="row my-5">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.stud_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => {
                    setSelectedStudentId(row.id);
                    setOpenStudentDetailsDrawer(true);
                  },
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationStudentTableComp;
