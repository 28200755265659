import React, { useState, useEffect } from "react"
import { ReadonlyInput } from "./Input"
import { Link, useParams } from "react-router-dom"
import Heading from "../../../../Common/Components/Heading"
import axios from "axios"
import url from "../../../../Common/URL"
import { useSelector } from "react-redux"

const HistoryDataComp = () => {

    const { id } = useParams();

    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"), stud_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_no: "", home_no: "", email: "",
        parent_fname: "", parent_lname: "", parent_mobile_no: "", parent_email: "", parent_mobile_no_2: "",
        parent_email_2: "", join_date: "", leave_date: "",

        // ------------Textarea input fields--------------
        addressline1: "", addressline2: "",

        // ------------Checkbox input fields--------------
        active: false, photo_consent: false, disclaimer_signed: false,

        // ------------Select inputs fields--------------
        state: "", country: "", location_name: "", batch_name: "", level_name: "", user_name: "",
        picture: "",
    })

    useEffect(() => {
        StudentDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const StudentDetail = async () => {
        const obj = {
            uname: field.uname,
            id: id
        }
        axios.post(url + "student_history_select", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setField({
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city, zip: resData[0].zip,
                    mobile_no: resData[0].mobile_no, home_no: resData[0].home_no, email: resData[0].email,
                    parent_fname: resData[0].parent_fname, parent_lname: resData[0].parent_lname, parent_mobile_no: resData[0].parent_mobile_no,
                    parent_email: resData[0].parent_email, parent_mobile_no_2: resData[0].parent_mobile_no_2, approved_date: resData[0].approved_date,
                    parent_email_2: resData[0].parent_email_2, join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    stud_id: resData[0].stud_id,
                    // ------------Textarea input fields--------------
                    addressline1: resData[0].addressline1, addressline2: resData[0].addressline2,

                    // ------------Checkbox input fields--------------
                    active: resData[0].active, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,

                    // ------------Select inputs fields--------------
                    state: resData[0].state, country: resData[0].country, location_name: resData[0].location_name, batch_name: resData[0].batch_name,
                    level_name: resData[0].level_name, user_name: resData[0].user_name,

                })
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item" ><Link to="/student">Students list</Link></li>
                        <li className="breadcrumb-item "><Link to={"/stu-history-table/" + field.stud_id}>History log</Link></li>
                        <li className="breadcrumb-item active">History details</li>
                    </ol>
                </nav>
                <Heading name="Details of log" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form>
                    <div className="container-fluid mt-3">

                        <div className="row mt-5">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Personal Information</h5>

                                {/* <ReadonlyInput  field="Profile Picture" type="file"  className="form-control-file" value={field.picture} /> */}

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Student ID</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={field.stud_id} />
                                    </div>
                                </div>

                                <ReadonlyInput field="First Name" value={field.fname} />

                                <ReadonlyInput field="Middle Name" value={field.mname} />

                                <ReadonlyInput field="Last Name" value={field.lname} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Address Line 1</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.addressline1}  ></textarea>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Address Line 2</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.addressline2} ></textarea>
                                    </div>
                                </div>

                                <ReadonlyInput field="City" value={field.city} />

                                {/* ---Select Input---- */}
                                <ReadonlyInput field="Province" className="form-control bg-light border-0 " value={field.state} />

                                {/* ---Select Input---- */}
                                <ReadonlyInput field="Country" className="form-control bg-light border-0 " value={field.country} />


                                <ReadonlyInput field="Zip" value={field.zip} />

                                <ReadonlyInput field="Mobile Number" value={field.mobile_no} />

                                <ReadonlyInput field="Home Phone" value={field.home_no} />

                                <ReadonlyInput field="Email" type="email" placeholder="--" value={field.email} />

                                <h5 className="mt-5 mb-4">Parent Information</h5>

                                <ReadonlyInput field="Parent First Name" value={field.parent_fname} />

                                <ReadonlyInput field="Parent Last Name" value={field.parent_lname} />

                                <ReadonlyInput field="Parent Mobile Number" value={field.parent_mobile_no} />

                                <ReadonlyInput field="Parent Email" type="email" placeholder="--" value={field.parent_email} />

                                <ReadonlyInput field="Parent Mobile Number 2" value={field.parent_mobile_no_2} />

                                <ReadonlyInput field="Parent Email 2" type="email" placeholder="--" value={field.parent_email_2} />

                            </div>
                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Batch Information</h5>

                                {/* ---Select Input---- */}
                                <ReadonlyInput field="Location Name" className="form-control bg-light border-0 bg-light border-0 " value={field.location_name} />

                                {/* ---Select Input---- */}
                                <ReadonlyInput field="Batch Name" className="form-control bg-light border-0 bg-light border-0 " value={field.batch_name} />

                                {/* ---Select Input---- */}
                                <ReadonlyInput field="Level Name" className="form-control bg-light border-0 bg-light border-0 " value={field.level_name} />


                                <h5 className="mt-5 mb-4">Account Information</h5>

                                {/* ---Select Input---- */}
                                <ReadonlyInput field="User" className="form-control bg-light border-0 bg-light border-0 " value={field.user_name} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Active Account</label>
                                    <div className="col-sm-7 col-md-7 ps-4">
                                        <div className="form-check">
                                            <input readOnly className="form-check-input" name="active" type="checkbox" checked={field.active} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                                    <div className="col-sm-7 col-md-7 ps-4">
                                        <div className="form-check">
                                            <input readOnly className="form-check-input" name="photo_consent" type="checkbox" checked={field.photo_consent} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                                    <div className="col-sm-7 col-md-7 ps-4">
                                        <div className="form-check">
                                            <input readOnly className="form-check-input" name="disclaimer_signed" type="checkbox" checked={field.disclaimer_signed} />
                                        </div>
                                    </div>
                                </div>

                                <ReadonlyInput field="Join Date" type="date" placeholder="--" value={field.join_date} />

                                <ReadonlyInput field="Leave Date" type="date" placeholder="--" value={field.leave_date} />

                            </div>

                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default HistoryDataComp