import React from "react"
import Navbar from "../../../Common/Components/Navbar"
import Footer from "../../../Common/Components/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import HistoryDataComp from "./Component/HistoryDataComp"


const TeacherHistoryData =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <HistoryDataComp />
            
        </div>
        <Footer />
        </>
    )
}

export default TeacherHistoryData