import { Close, NearMe, RateReview, Visibility } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
    Alert,
    AppBar,
    Button, Card, CardActions, CardContent,
    CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Drawer,
    FormGroup,
    IconButton, TextField,
    Toolbar,
    Tooltip, Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import StatusChip from "../../../../../Common/Components/StatusChip";
import StringToTime, { ReadableDate } from "../../../../../Common/Functions/StringDateTime";
import url from "../../../../../Common/URL";
import Input, { ReadonlyInput } from "../../../../Setup/Student/Component/Input";
import AdmissionDetailsComp from "../../Handling Admission/Component/AdmissionDetailsComp";


const EnquiryDetailsComp = ({ id, setOpenDrawer }) => {

    const [loc, setLoc] = useState([]);
    const [slots, setSlots] = useState([]);

    // To open the Dialog, for adding or editing  the Teacher's Feedback 
    const [openTcFbDialog, setOpenTcFbDialog] = useState(false);
    const [openPocFbDialog, setOpenPocFbDialog] = useState(false);

    // To open the Dialog, for sending TC & POC Invite 
    const [openTcSendInviteDialog, setOpenTcSendInviteDialog] = useState(false);
    const [openPocSendInviteDialog, setOpenPocSendInviteDialog] = useState(false);

    // tc = Trial Class 
    const [tcLoc, setTcLoc] = useState('');
    const [tcSlot, setTcSlot] = useState('');

    // poc = parent Orientation
    const [pocLoc, setPocLoc] = useState('');
    const [pocSlot, setPocSlot] = useState('');

    // Below state is used when a dialog opens for sending invite or updating f/b message,  
    // This state takes values from the row which is clicked to open the dialogs 
    const [studRowData, setStudRowData] = useState({});

    const [assignedTo, setAssignedTo] = useState([]);

    const [loading, setLoading] = useState(false);
    const [sendInviteLoading, setSendInviteLoading] = useState(false);
    const [updateFeedbackLoading, setUpdateFeedbackLoading] = useState(false);

    const [enqData, setEnqData] = useState({});
    const [studentData, setStudentData] = useState([]);

    const [logMessage, setLogMessage] = useState('');
    const [logList, setLogList] = useState([]);


    const [tcFeedback, setTcFeedback] = useState("");
    const [pocFeedback, setPocFeedback] = useState("");


    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newStudentData, setNewStudentData] = useState({
        fname: "", lname: "", age: ""
    });

    const getLocationNameBySlotId = (slotId) => {
        const slot = slots.find((item) => item.slot_id === slotId);

        if (slot) {
            return slot.location_string;
        } else {
            return "Location not found"; // You can adjust the default value if needed
        }
    };

    const Columns = useMemo(() => [
        { header: 'Student Name', size: 40, align: 'center', id: 'student_fname', accessorFn: rowData => `${rowData.student_fname} ${rowData.student_lname}` },
        { header: 'Age', accessorKey: 'age', size: 40 },
        {
            header: 'Trial Class', id: 'trial_class', size: 250,
            enableEditing: false, enableColumnActions: false, enableSorting: false,
            accessorFn: (rowData) => {

                return (
                    <div>
                        <FormGroup>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <StatusChip status={rowData.tc_invite_sent_flag} />
                                <Typography variant="inherit" className="me-auto">Invite Sent?</Typography>
                                <Tooltip title="Send Invite" placement="top" >
                                    <IconButton size="small"
                                        disabled={rowData.student_admission_done === 1}
                                        onClick={() => {
                                            setTcLoc(() => enqData.location);
                                            setTcSlot(() => enqData.slot_id);
                                            setStudRowData(...[rowData]);
                                            setOpenTcSendInviteDialog(true);
                                        }} >
                                        <NearMe />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                            <Stack direction='row' spacing={2} alignItems='center'>
                                <StatusChip status={rowData.tc_attended_flag} />
                                <Typography variant="inherit" className="me-auto">Meeting Attended?</Typography>
                            </Stack>

                            <Stack direction='row' spacing={2} alignItems='center'>
                                <StatusChip status={rowData.tc_feedback_flag} />
                                <Typography variant="inherit" className="me-auto">Teacher's F/B?</Typography>

                                <Tooltip title="Add / Update Feedback" placement="top" >
                                    <IconButton size="small" onClick={() => {
                                        setStudRowData(...[rowData]);
                                        setTcFeedback(rowData.tc_feedback ?? '');
                                        setOpenTcFbDialog(true);
                                    }} >
                                        <RateReview />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </FormGroup>
                        <hr className="mt-0 mb-2" />

                        {rowData.tc_invite_sent_flag === 0 ?
                            <Alert severity="warning" className="py-0 border shadow-sm">Please send Invite</Alert> :
                            <Stack direction='column'>
                                <p className="mb-0">{`On: ${ReadableDate(rowData.tc_slot_date)}, ${StringToTime(rowData.tc_slot_time)}`}</p>
                                <p className="mb-0">{`Location: ${getLocationNameBySlotId(rowData.tc_slot_id)}`}</p>
                                <p className="mb-0">{`Coordinator: ${rowData.tc_coordinator_name}`}</p>
                            </Stack>
                        }

                    </div >
                );
            }
        },
        {
            header: 'Parent Orientation', id: 'parent_orientation', size: 250,
            enableEditing: false, enableColumnActions: false, enableSorting: false,
            accessorFn: (rowData) => {

                return (
                    <div>
                        <FormGroup>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <StatusChip status={rowData.poc_invite_sent_flag} />
                                <Typography variant="inherit" className="me-auto">Invite Sent?</Typography>
                                <Tooltip title="Send Invite" placement="top" >
                                    <IconButton size="small"
                                        disabled={rowData.student_admission_done === 1}
                                        onClick={() => {
                                            setStudRowData(...[rowData]);
                                            setOpenPocSendInviteDialog(true);
                                        }
                                        } >
                                        <NearMe />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                            <Stack direction='row' spacing={2} alignItems='center'>
                                <StatusChip status={rowData.poc_attended_flag} />
                                <Typography variant="inherit" className="me-auto">Meeting Attended?</Typography>
                            </Stack>

                            <Stack direction='row' spacing={2} alignItems='center'>
                                <StatusChip status={rowData.poc_feedback_flag} />
                                <Typography variant="inherit" className="me-auto">Teacher's F/B?</Typography>
                                <Tooltip title="Add / Update Feedback" placement="top" >
                                    <IconButton size="small" onClick={() => {
                                        setStudRowData(...[rowData]);
                                        setPocFeedback(rowData.poc_feedback ?? '');
                                        setOpenPocFbDialog(true);
                                    }
                                    }  ><RateReview />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </FormGroup>

                        <hr className="mt-0 mb-2" />

                        {rowData.poc_invite_sent_flag === 0 ?
                            <Alert severity="warning" className="py-0 border shadow-sm" >Please send Invite</Alert> :
                            <Stack direction='column'>
                                <p className="mb-0">{`On: ${rowData.poc_slot_date}, At: ${StringToTime(rowData.poc_slot_time)}`}</p>
                                <p className="mb-0">{`Location: ${getLocationNameBySlotId(rowData.poc_slot_id)}`}</p>
                                <p className="mb-0">{`Coordinator: ${rowData.poc_coordinator_name}`}</p>

                            </Stack>
                        }
                    </div >
                );
            }
        },
        {
            header: 'Current Status', id: 'admission_form',
            enableEditing: false, enableColumnActions: false, enableSorting: false,
            accessorFn: (rowData, index) => {

                return (
                    rowData.admission_form_created === 1 ?
                        <div className="vstack gap-2">
                            <Alert severity="success" className="border shadow-sm">Admission Form Created</Alert>
                            <Button size="small" color="info" variant="contained" startIcon={<Visibility />}
                                onClick={() => {
                                    setSelectedAdmissionFormId(rowData.adm_id);
                                    setOpenAdmissionFormDetailsDrawer(true);
                                }}
                            >View Admission Form</Button>
                            <div className={`alert ${rowData.student_admission_done === 1 ? 'alert-success' : 'alert-secondary'} py-2 d-flex justify-content-center align-items-center`}>
                                <Typography variant="subtitle2" className="me-auto">Admission Status</Typography>
                                <StatusChip status={rowData.student_admission_done} />
                            </div>
                        </div>
                        :
                        <Button size="small" color="success" variant="contained"
                            onClick={() => {
                                createAdmissionForm(rowData.enq_stud_id);
                            }} >Create Admission Form</Button>
                );
            }
        },
        // {
        //     header: '', id: "index", size: 40, enableEditing: false, enableColumnActions: false, enableSorting: false, accessorFn: (rowData, index) => {
        //         return (
        //             <Tooltip title="Delete" >
        //                 <IconButton disabled={loading} onClick={() => handleDeleteStudent(rowData.enq_stud_id)}>
        //                     <DeleteOutline />
        //                 </IconButton>
        //             </Tooltip>
        //         );
        //     }
        // }
    ], [tcSlot, tcLoc, pocLoc, pocSlot, slots, loc]);

    useEffect(() => {
        loadAllAsyncTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const loadAllAsyncTasks = () => {
        toast.info('Loading...', { autoClose: false, closeOnClick: false });

        let allCompleted = Promise.all([
            LoadEnquiryDetails(),
            loadLocation(),
            fetchAssignedToDropdowns(),
            fetchSlots(),
            fetchLogMessages()
        ]);
        allCompleted.then(() => {
            toast.dismiss();
        });
    };


    // -----------This function will Fetch all the Existing Locations--------------------
    const LoadEnquiryDetails = async () => {
        setLoading(true);
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };
        await axios.post(url + "view_adm_enquiry", obj)
            .then((response) => {
                let resData = response.data.data;
                console.log(resData);
                setEnqData(...resData);
                setStudentData(resData[0].students);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    };

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                console.log(a);
                setLoc([...a]);  // spread operator is used for, making the screen re-render
            })
            .catch((err) => console.log(err));
    };

    // -----------This function will Fetch all the Existing Locations--------------------
    const fetchAssignedToDropdowns = async () => {


        await axios.get(url + "assigned_dropdown")
            .then((response) => {
                let a = response.data;
                console.log(a);
                !a.length ? setAssignedTo([]) : setAssignedTo(a);
            })
            .catch((err) => console.log(err));
    };
    // -----------This function will Fetch all the Existing Locations--------------------

    const fetchSlots = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.get(url + "slots", obj)
            .then((response) => {
                let a = response.data;
                console.log(a);
                !a.length ? setSlots([]) : setSlots([...a]);
            })
            .catch((err) => console.log(err));
    };
    // ====================== OnChange =========================

    const onChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        setEnqData(pre => {
            return { ...pre, [name]: value };
        });
    };

    const AddLogMessage = () => {
        if (logMessage === '') {
            toast.error("Please enter a log message.");
            return;
        }
        setLoading(true);
        const obj = {
            uname: sessionStorage.getItem("username"), enq_id: id,
            log_message: logMessage
        };
        console.log(obj);
        axios.post(url + "add_enq_log", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchLogMessages().then(() => {
                        toast.success("Log added");
                    });
                } else {
                    toast.error("An error occurred while adding log, please try again later!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred while adding log, please try again later!");
            })
            .finally(() => {
                setLoading(false);
                setLogMessage('');
            });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const obj = {
            fname: enqData.fname, lname: enqData.lname, assigned_to: enqData.assigned_to,
            phone: enqData.phone, message: enqData.message, email: enqData.email, preferred_time: enqData.preferred_time,
            location: enqData.location, language: enqData.language,
            campaign: enqData.campaign, preferred_date: enqData.preferred_date, status: enqData.status,
            enq_id: id, uname: sessionStorage.getItem('username')
        };


        console.log(obj);

        await axios
            .post(url + "edit_adm_enquiry", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    LoadEnquiryDetails().then(() => {
                        toast.success('Enquiry updated');
                    });
                } else {
                    toast.error("An error occurred while updating, please try again later!");
                }
            })
            .catch((err) => {
                toast.error("An error occurred while updating, please try again later!");
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleAddNewStudent = (e) => {
        e.preventDefault();
        setLoading(true);
        let obj = { ...newStudentData, enq_id: id };

        axios.post(url + "add_enq_stu", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    LoadEnquiryDetails().then(() => {
                        toast.success("Student added");
                    });
                } else {
                    toast.error("An error occurred, please try again later!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred, please try again later!");
            })
            .finally(() => {
                setLoading(false);
                setOpenAddDialog(false);
                setNewStudentData({
                    fname: "", lname: "", age: ""
                });
            });
    };

    const handleSaveRowEdits = ({ exitEditingMode, row, values }) => {

        let obj = {
            fname: values.student_fname, lname: values.student_lname, age: values.age,
            enq_stud_id: row.original.enq_stud_id, enq_id: id, uname: sessionStorage.getItem('username')
        };
        console.log(obj);

        axios.post(url + "edit_enq_stu", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    LoadEnquiryDetails().then(() => {
                        toast.success("Student updated");
                    });
                } else {
                    toast.error("An error occurred, please try again later!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred, please try again later!");
            })
            .finally(() => {
                setLoading(false);
                setOpenAddDialog(false);
                exitEditingMode();   //required to exit editing mode and close modal
            });

    };

    const handleDeleteStudent = (enq_stud_id) => {
        setLoading(true);
        let obj = { enq_stud_id, uname: sessionStorage.getItem('username') };
        console.log(obj);
        axios.post(url + "delete_enq_stu", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    LoadEnquiryDetails().then(() => {
                        toast.info("Student deleted");
                    });
                } else {
                    toast.error("An error occurred, please try again later!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred, please try again later!");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createAdmissionForm = (enq_stud_id) => {

        const obj = {
            enq_stud_id: enq_stud_id,
        };

        console.log(obj);
        axios.post(url + 'generate_adm_form_with_existing_data', obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    LoadEnquiryDetails().then(() => {
                        toast.success("Admission form created");
                    });
                } else {
                    toast.error("An error occurred while creating admission form, please try again later!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred while creating admission form, please try again later!");
            })
            .finally(() => {
                setSendInviteLoading(false);
            });
    };

    // This function will be called when we are sending TC & POC Invites

    const handleSentInvite = (e) => {
        setSendInviteLoading(true);
        let calledFrom = e.target.name;
        let apiName = calledFrom === 'tc' ? 'send_trial_class_invite' : 'send_orientation_class_invite';

        const obj = {
            slot_id: calledFrom === 'tc' ? tcSlot : pocSlot,
            enq_stud_id: studRowData.enq_stud_id,
            enq_id: id
        };
        console.log(obj);

        axios.post(url + apiName, obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    LoadEnquiryDetails().then(() => {
                        toast.success("Invite Sent");
                    }).then(() => {
                        if (calledFrom === 'tc') {
                            setOpenTcSendInviteDialog(false);
                        } else {
                            setOpenPocSendInviteDialog(false);
                        }
                    });
                } else {
                    toast.error("An error occurred while sending invite, please try again later!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred while sending invite, please try again later!");
            })
            .finally(() => {
                setSendInviteLoading(false);
            });
    };

    // This function will be called when we are updating Teacher's Feedback
    const handleFeedbackMessage = async (e, feedbackFlag = 1) => {
        setUpdateFeedbackLoading(true);

        let calledFrom = e.target.name;
        let apiName = calledFrom === 'tc' ? 'edit_tc_data' : 'edit_poc_data';

        if (calledFrom === 'tc') {
            var obj = {
                enq_stud_id: studRowData.enq_stud_id,
                tc_attended_flag: studRowData.tc_attended_flag,
                tc_feedback_flag: feedbackFlag,
                tc_feedback: feedbackFlag === 1 ? tcFeedback : '',
            };
        }
        else {
            var obj = {
                enq_stud_id: studRowData.enq_stud_id,
                poc_attended_flag: studRowData.poc_attended_flag,
                poc_feedback_flag: feedbackFlag,
                poc_feedback: feedbackFlag === 1 ? pocFeedback : '',
            };
        }

        await axios.post(url + apiName, obj)
            .then((response) => {
                let a = response.data;
                console.log(a);
                LoadEnquiryDetails().then(() => {
                    if (feedbackFlag === 0) {
                        calledFrom === 'tc' && setTcFeedback('');
                        calledFrom === 'poc' && setPocFeedback('');
                    }
                    feedbackFlag === 1 ? toast.success("Feedback updated") : toast.success("Feedback removed");
                    if (calledFrom === 'tc') {
                        setOpenTcFbDialog(false);
                    } else {
                        setOpenPocFbDialog(false);
                    }

                });
            })
            .catch((err) => {
                console.log(err);
                toast.error("Updating failed!");
            }).finally(() => {
                setUpdateFeedbackLoading(false);
            });
    };


    const fetchLogMessages = async () => {
        await axios.post(url + 'fetch_enq_logs', { enq_id: id }).then((res) => {
            // console.log(res);
            setLogList(res.data);
        });
    };

    const deleteLogMessage = async (enq_log_id) => {
        toast.info('Loading...', { autoClose: false, closeOnClick: false });
        console.log(enq_log_id);
        await axios.post(url + "enq_log_delete", { enq_log_id })
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchLogMessages().then(() => {
                        toast.dismiss();
                        toast.info('Log Deleted');
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const [openAdmissionFormDetailsDrawer, setOpenAdmissionFormDetailsDrawer] = useState(false);
    const [selectedAdmissionFormId, setSelectedAdmissionFormId] = useState(null);

    return (
        <>
            {/* ################# Sending Invite Dialog for Parent Orientation Class ####################### */}

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={openPocSendInviteDialog}
                onClose={() => setOpenPocSendInviteDialog(false)}>
                <DialogTitle>Send Parent Orientation Class Invite</DialogTitle>
                <DialogContent className="pt-3">
                    <select name="location" value={pocLoc} onChange={(e) => setPocLoc(e.target.value)} className="form-select mb-3">
                        <option value="">Choose Location</option>
                        {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                    </select>

                    <select name="slot_id" value={pocSlot} onChange={(e) => setPocSlot(e.target.value)} className="form-select mb-3">
                        <option value="" >Choose Slot</option>
                        {
                            slots.filter((x) => (x.location_string === pocLoc && x.slot_type === 1)).map((data, i) => (<option key={i}
                                value={data.slot_id}>{`${data.slot_date} at ${StringToTime(data.slot_time)}`}</option>))
                        }
                    </select >
                </DialogContent>
                <DialogActions>
                    <Button disabled={sendInviteLoading} color="primary" onClick={() => setOpenPocSendInviteDialog(false)}>Close</Button>
                    <Button disabled={sendInviteLoading || pocLoc === '' || pocSlot === ''} color="primary" name="poc" onClick={handleSentInvite} >Send</Button>
                </DialogActions>
            </Dialog>

            {/* ################# Sending Invite Dialog for Trial Class ####################### */}
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={openTcSendInviteDialog}
                onClose={() => setOpenTcSendInviteDialog(false)}>
                <DialogTitle>Send Trial Class Invite</DialogTitle>
                <DialogContent className="pt-3">
                    <select name="location" value={tcLoc} onChange={(e) => setTcLoc(e.target.value)} className="form-select mb-3">
                        <option value="">Choose Location</option>
                        {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                    </select>

                    <select name="slot_id" value={tcSlot} onChange={(e) => setTcSlot(e.target.value)} className="form-select mb-3">
                        <option value="" >Choose Slot</option>
                        {
                            slots.filter((x) => (x.location_string === tcLoc && x.slot_type === 0)).map((data, i) => (<option key={i}
                                value={data.slot_id}>{`${data.slot_date} at ${StringToTime(data.slot_time)}`}</option>))
                        }
                    </select >
                </DialogContent>
                <DialogActions>
                    <Button disabled={sendInviteLoading} color="primary" onClick={() => setOpenTcSendInviteDialog(false)}>Close</Button>
                    <Button disabled={sendInviteLoading || tcLoc === '' || tcSlot === ''} color="primary" name="tc" onClick={handleSentInvite} >Send</Button>
                </DialogActions>
            </Dialog>

            {/* ################# Dialog for Trial Class Teacher's Feedback ####################### */}
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={openTcFbDialog}
                onClose={() => setOpenTcFbDialog(false)}>
                <DialogTitle>Teacher's Feedback On Trial Class</DialogTitle>
                <DialogContent className="pt-2">
                    <textarea
                        readOnly={studRowData.student_admission_done === 1}
                        name="tc"
                        placeholder="Enter Feedback"
                        className="form-control"
                        value={tcFeedback}
                        onChange={(e) => setTcFeedback(e.target.value)}
                    >
                    </textarea>
                </DialogContent>
                <DialogActions className="px-4">
                    <Button disabled={(studRowData.student_admission_done === 1) || updateFeedbackLoading} size="small" variant="contained" className="me-auto" color="error" name="tc" onClick={(e) => handleFeedbackMessage(e, 0)} >Remove Feedback</Button>
                    <Button disabled={updateFeedbackLoading} color="primary" onClick={() => setOpenTcFbDialog(false)}>Close</Button>
                    <Button disabled={(studRowData.student_admission_done === 1) || updateFeedbackLoading || tcFeedback === ''} color="primary" name="tc" onClick={handleFeedbackMessage} >{(updateFeedbackLoading) ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                </DialogActions>
            </Dialog>

            {/* ################# Dialog for Parent Orientation Teacher's Feedback ####################### */}
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={openPocFbDialog}
                onClose={() => setOpenPocFbDialog(false)}>
                <DialogTitle>Teacher's Feedback On Parent Orientation Class</DialogTitle>
                <DialogContent className="pt-2">
                    <textarea
                        readOnly={studRowData.student_admission_done === 1}
                        name="poc"
                        placeholder="Enter Feedback"
                        className="form-control"
                        value={pocFeedback}
                        onChange={(e) => setPocFeedback(e.target.value)}
                    >
                    </textarea>
                </DialogContent>
                <DialogActions className="px-4">
                    <Button disabled={(studRowData.student_admission_done === 1) || updateFeedbackLoading} size="small" variant="contained" className="me-auto" color="error" name="poc" onClick={(e) => handleFeedbackMessage(e, 0)}>Remove Feedback</Button>
                    <Button disabled={updateFeedbackLoading} color="primary" onClick={() => setOpenPocFbDialog(false)}>Close</Button>
                    <Button disabled={(studRowData.student_admission_done === 1) || updateFeedbackLoading || pocFeedback === ''} color="primary" name="poc" onClick={handleFeedbackMessage} >{(updateFeedbackLoading) ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                </DialogActions>
            </Dialog>

            {/* ------------------- Add Student Dialog --------------- */}
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={openAddDialog}
                onClose={() => {
                    setOpenAddDialog(false);
                    setNewStudentData({
                        fname: "", lname: "", age: ""
                    });
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Add New Student</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleAddNewStudent} id="addNewStudent" >
                        <TextField value={newStudentData.fname} className="w-100 mb-3"
                            onChange={(e) => setNewStudentData({ ...newStudentData, fname: e.target.value })}
                            label="First Name" variant="filled" required
                        />
                        <TextField value={newStudentData.lname} className="w-100 mb-3"
                            onChange={(e) => setNewStudentData({ ...newStudentData, lname: e.target.value })}
                            label="Last Name" variant="filled" required
                        />
                        <TextField value={newStudentData.age} className="w-100 mb-3"
                            onChange={(e) => setNewStudentData({ ...newStudentData, age: e.target.value })}
                            label="Age" variant="filled" required
                        />
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={() => {
                        setOpenAddDialog(false);
                        setNewStudentData({
                            fname: "", lname: "", age: ""
                        });

                    }}>Close</Button>
                    <Button type="submit" form="addNewStudent" color="primary" > {loading ? <CircularProgress size={20} /> : "Add"}</Button>
                </DialogActions>
            </Dialog>


            {/* Side Drawer viewing admission form details*/}
            <Drawer
                PaperProps={{ style: { width: '95%' } }}
                anchor='right'
                open={openAdmissionFormDetailsDrawer}
                onClose={() => setOpenAdmissionFormDetailsDrawer(false)}
            >
                <AdmissionDetailsComp id={selectedAdmissionFormId} setOpenDrawer={setOpenAdmissionFormDetailsDrawer} />
            </Drawer >

            {/* --------------------------------- */}

            <AppBar color="transparent" position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => setOpenDrawer(false)}
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 2 }}
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Enquiry Details
                    </Typography>
                </Toolbar>
            </AppBar>

            <div className="container-fluid p-4">

                <div className="border-bottom pt-2">
                    <nav>
                        <div className="nav " id="nav-tab" role="tablist">
                            <a className="nav_tab nav-link active" data-bs-toggle="tab" href="#nav-enquiry-info" role="tab" aria-controls="nav-enquiry-info" aria-selected="true"> Enquiry Information
                            </a>
                            <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-students-info" role="tab" aria-controls="nav-students-info" aria-selected="false">Student(s) Information
                            </a>
                            <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-logs-info" role="tab" aria-controls="nav-logs-info" aria-selected="false">Enquiry Logs
                            </a>
                        </div>
                    </nav>
                </div>
                <form id='mainEnquiryForm' onSubmit={onSubmit} >

                    <div className="tab-content px-2 py-4">

                        <div className="tab-pane fade show active" id="nav-enquiry-info" role="tabpanel">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end mx-auto">
                                    <Button form='mainEnquiryForm' variant="contained" type="submit" disabled={loading} >{loading ? <CircularProgress size={20} color="inherit" /> : 'Update Enquiry'}</Button>
                                </div>
                            </div>

                            <div className="row my-4">
                                <h5 className="mb-4">Enquiry Information</h5>
                                <div className="col-12  col-md-6">
                                    <ReadonlyInput name="enq_id" field="Enquiry Id" value={enqData.enq_id} />
                                    <ReadonlyInput name='created_at' field="Enquiry Date" value={enqData.created_at} />
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Status</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select name="status" value={enqData.status ?? ''} onChange={onChange} className="form-select col-md-2">
                                                <option value="">Choose</option>
                                                <option value="New">New</option>
                                                <option value="In Progress">In Progress</option>
                                                <option value="Closed">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12  col-md-6">
                                    <ReadonlyInput name="campaign" field="Heard About Us From" value={enqData.campaign ?? ''} />
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Assigned To</label>
                                        <div className="col-sm-7 col-md-7 ">

                                            <select name="assigned_to" value={enqData.assigned_to ?? ''} onChange={onChange} className="form-select">
                                                <option value="" >Choose</option>
                                                {assignedTo.map((data, i) => (<option key={i} value={data.id}>{`${data.first_name} ${data.last_name}`}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">

                                {/*------------------------------------------------ */}
                                <div className="col-12 col-md-6 mx-auto">
                                    <h5 className="mb-4">Parent / Requester Information</h5>
                                    <Input name="fname" field="First Name" value={enqData.fname} className="form-control" onChange={onChange} />
                                    <Input name="lname" field="Last Name" value={enqData.lname} className="form-control" onChange={onChange} />
                                    <Input name="phone" field="Phone Number" className="form-control" value={enqData.phone} onChange={onChange} />
                                    <Input name="email" field="Email" className="form-control" value={enqData.email} onChange={onChange} />
                                </div>
                                {/*-----------------------Column-2 for Inputs------------------------- */}
                                <div className="col-12 col-md-6 mx-auto">

                                    <div className="col-12 mb-3">
                                        <div className="d-flex align-items-center">
                                            <h5>Parent's Preference</h5>
                                            {/* <Button variant="contained" type='button' disabled className=" ms-auto">Appointment Slots</Button> */}
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Preferred Language</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select name="language" value={enqData.language ?? ''} onChange={onChange} className="form-select">
                                                <option value="">Choose</option>
                                                <option value="English">English</option>
                                                <option value="Dutch">Dutch</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Location</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select required name="location" value={enqData.location ?? ''} onChange={onChange} className="form-select">
                                                <option value="" >Choose</option>
                                                {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Selected Trail Class Slot</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select disabled={true} name="slot_id" value={enqData.preferred_slot_id ?? ''} className="form-select">
                                                <option value="" >Choose</option>
                                                {slots.filter(x => x.slot_type === 0).map((data, i) => (<option key={i} value={data.slot_id}>{`${data.slot_date} at ${StringToTime(data.slot_time)}`}</option>))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Customer Message</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea name="message" className="form-control" value={enqData.message ?? ''} onChange={onChange} ></textarea>
                                        </div>
                                    </div>
                                    {/* <Input name="preferred_date" onChange={onChange} field="Preferred Date" type="date" className="form-control" value={enqData.preferred_date} />
                                            <Input name="preferred_time" onChange={onChange} field="Preferred Time" type="time" className="form-control" value={enqData.preferred_time} /> */}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade " id="nav-students-info" role="tabpanel">
                            <div className="row">

                                <div className="col-12 col-md-12 mb-3">
                                    <MaterialReactTable
                                        columns={Columns}
                                        data={studentData}
                                        initialState={{ density: 'compact' }}
                                        enableDensityToggle={false}
                                        // enableRowSelection
                                        enablePagination={false}
                                        // editingMode="row"
                                        // enableEditing
                                        enableBottomToolbar={false}
                                        // onEditingRowSave={handleSaveRowEdits}

                                        muiTableBodyCellProps={{
                                            //easier way to create media queries, no useMediaQuery hook needed.
                                            sx: {
                                                verticalAlign: 'top'
                                            },
                                        }}
                                    // We are not going to provide the Add Children feature Temporarily
                                    // renderTopToolbarCustomActions={({ table }) => (
                                    //     <Button
                                    //         onClick={() => setOpenAddDialog(true)}
                                    //         variant="contained"
                                    //         disabled={loading}
                                    //     >Add Children
                                    //     </Button>
                                    // )}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade " id="nav-logs-info" role="tabpanel">
                            <div className='row'>
                                <div className="col-md-6">
                                    {/* <h5 className="mb-4">Add Log</h5> */}


                                    <textarea className="form-control mb-3"
                                        value={logMessage}
                                        placeholder="Add Log Message"
                                        onChange={(e) => setLogMessage(e.target.value)} >
                                    </textarea>
                                    <Button onClick={AddLogMessage} variant="contained" disabled={loading} type='button' >Add Log</Button>
                                </div>

                                <div className="col-md-6 overflow-auto" style={{ maxHeight: '70vh' }}>
                                    <h5 className="mb-4">Enquiry Logs</h5>
                                    {!logList.length && <Alert severity="info" className="border shadow-sm">No logs to display, add one.</Alert>}

                                    {logList.map((x, i) => {
                                        return <Card className="mb-3" key={i} variant="outlined">
                                            <CardActions className="py-0 border-bottom">
                                                <Typography variant="subtitle2">{`${x.created_by_string} | ${new Date(x.created_at).toLocaleString()}`}</Typography>

                                                <IconButton color="error" className="ms-auto" onClick={() => deleteLogMessage(x.enq_log_id)}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </CardActions>

                                            <CardContent>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    {x.log_message}
                                                </Typography>
                                            </CardContent>
                                        </Card>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </>
    );
};
export default EnquiryDetailsComp;