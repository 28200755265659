import React, { useState, useEffect } from "react"
import { ReadonlyInput } from "../../Student/Component/Input"
import { Link, useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../../Common/Components/Heading"
import axios from "axios"
import url from "../../../../Common/URL"
import { Button } from "@mui/material";

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux"


const ViewQuestionComp = () => {
    const confirm = useConfirm();
    const [load, setLoad] = useState(true);

    const navigate = useNavigate();
    const { id } = useParams();

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        ques_num1: "", ques_num2: "", ques_num3: "", ques_num4: "",
        ques_num5: "", ques_num6: "", ques_num7: "", ques_num8: "",
        ques_num9: "", ques_num10: "", ques_num11: "", ques_num12: "",
        ques_num13: "", ques_num14: "", ques_num15: "", ques_num16: "",
        answer: "", level_name: "Level 1",
        location_name: "", category_name: "", que_type: "", assignment_type: "", page: "",
    })
    // ------------------------------
    const Questiondetails = async () => {

        const obj = {
            uname: state.uname,
            id: id
        }
        await axios.post(url + "fetch_single_question", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setState({
                    ques_num1: resData[0].ques_num1, ques_num2: resData[0].ques_num2, ques_num3: resData[0].ques_num3,
                    ques_num4: resData[0].ques_num4, ques_num5: resData[0].ques_num5, ques_num6: resData[0].ques_num6,
                    ques_num7: resData[0].ques_num7, ques_num8: resData[0].ques_num8, ques_num9: resData[0].ques_num9,
                    ques_num10: resData[0].ques_num10,
                    ques_num11: resData[0].ques_num11, ques_num12: resData[0].ques_num12, ques_num13: resData[0].ques_num13,
                    ques_num14: resData[0].ques_num14, ques_num15: resData[0].ques_num15, ques_num16: resData[0].ques_num16,
                    answer: resData[0].answer,
                    level_name: resData[0].level_name, location_name: resData[0].location_name, page: resData[0].page,
                    category_name: resData[0].category_name, que_type: resData[0].que_type, assignment_type: resData[0].assignment_type

                })
            })
            .catch((err) => console.log(err));
        setLoad(false);
    }

    useEffect(() => {
        Questiondetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ------------------------Deleting Student table's data-------------------------------------

    const onDelete = () => {
        confirm({ title: "Delete Confirmation", description: 'This will delete this question permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_question", delObj)
                    .then((res) => {
                        // console.log(res)
                        toast.info("Question deleted ");
                        navigate("/question")
                    })
                    .catch((err) => console.log(err));
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item "><Link to="/question">Questions</Link></li>
                        <li className="breadcrumb-item active">Add Questions</li>
                    </ol>
                </nav>
                <Heading name={load ? "View Question" : `View Question - ${state.que_type} ${state.level_name} ${state.page}`} />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <div className="container-fluid mt-3">
                    <div className="row my-4">
                        <div className="col-12 d-flex justify-content-end mx-auto">
                            <Button variant="contained" color="error"onClick={onDelete} >Delete</Button>
                            <Button variant="contained" onClick={() => navigate("/edit-question/" + id)} className="ms-3">Edit</Button>
                        </div>
                    </div>

                    <div className="row">

                        {/*-----------------------Column-1 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <h5 className="mb-4">Added Question</h5>

                            {/* <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label">Quesdtion ID</label>
                            <div className="col-sm-7 col-md-6 ">
                            <input type="text" readonly className="form-control-plaintext" value="<Auto generated>"  />
                            </div>
                        </div> */}


                            {state.ques_num1 !== "" &&
                                <ReadonlyInput field="Number 1" name="ques_num1"  className="form-control bg-light border-0 text-center" value={state.ques_num1} />}



                            {state.ques_num2 !== "" &&
                                <ReadonlyInput field="Number 2" name="ques_num2"  className="form-control bg-light border-0 text-center" value={state.ques_num2} />}



                            {state.ques_num3 !== "" &&
                                <ReadonlyInput field="Number 3" name="ques_num3"  className="form-control bg-light border-0 text-center" value={state.ques_num3} />}



                            {state.ques_num4 !== "" &&
                                <ReadonlyInput field="Number 4" name="ques_num4"  className="form-control bg-light border-0 text-center" value={state.ques_num4} />}


                            {state.ques_num5 !== "" &&
                                <ReadonlyInput field="Number 5" name="ques_num5"  className="form-control bg-light border-0 text-center" value={state.ques_num5} />}


                            {state.ques_num6 !== "" &&
                                <ReadonlyInput field="Number 6" name="ques_num6"  className="form-control bg-light border-0 text-center" value={state.ques_num6} />}

                            {state.ques_num7 !== "" &&
                                <ReadonlyInput field="Number 7" name="ques_num7"  className="form-control bg-light border-0 text-center" value={state.ques_num7} />}

                            {state.ques_num8 !== "" &&
                                <ReadonlyInput field="Number 8" name="ques_num8"  className="form-control bg-light border-0 text-center" value={state.ques_num8} />}

                            {state.ques_num9 !== "" &&
                                <ReadonlyInput field="Number 9" name="ques_num9"  className="form-control bg-light border-0 text-center" value={state.ques_num9} />}

                            {state.ques_num10 !== "" &&
                                <ReadonlyInput field="Number 10" name="ques_num10"  className="form-control bg-light border-0 text-center" value={state.ques_num10} />}

                            {state.ques_num11 !== "" &&
                                <ReadonlyInput field="Number 11" name="ques_num11"  className="form-control bg-light border-0 text-center" value={state.ques_num11} />}

                            {state.ques_num12 !== "" &&
                                <ReadonlyInput field="Number 12" name="ques_num12"  className="form-control bg-light border-0 text-center" value={state.ques_num12} />}

                            {state.ques_num13 !== "" &&
                                <ReadonlyInput field="Number 13" name="ques_num13"  className="form-control bg-light border-0 text-center" value={state.ques_num13} />}

                            {state.ques_num14 !== "" &&
                                <ReadonlyInput field="Number 14" name="ques_num14"  className="form-control bg-light border-0 text-center" value={state.ques_num14} />}

                            {state.ques_num15 !== "" &&
                                <ReadonlyInput field="Number 15" name="ques_num15"  className="form-control bg-light border-0 text-center" value={state.ques_num15} />}

                            {state.ques_num16 !== "" &&
                                <ReadonlyInput field="Number 16" name="ques_num16"  className="form-control bg-light border-0 text-center" value={state.ques_num16} />}



                            <ReadonlyInput field="Answer" name="answer" type="text" placeholder="Enter Answer" className="form-control bg-light border-0 text-center" value={state.answer} />
                        </div>

                        {/*-----------------------Column-2 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">
                            <h5 className="mb-4">Question Infotmation</h5>

                            {/* ---Select Input---- */}
                            {/* <ReadonlyInput field="Location"  className="form-control bg-light border-0 "  value={state.location_name}/> */}

                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Level"  className="form-control bg-light border-0 " value={state.level_name} />

                            <ReadonlyInput field="Question Type"  className="form-control bg-light border-0 " value={state.que_type} />

                            {state.que_type === "Assignment" &&
                                <>
                                    <ReadonlyInput field="Category"  className="form-control bg-light border-0 " value={state.assignment_type === "A" ? "Class Work" : "Home Work"} />

                                    <ReadonlyInput field="Page"  className="form-control bg-light border-0 " value={state.page} />
                                </>}

                            {/* {(state.level_name !== "Level 1" && state.level_name !== "Level 2"  && state.level_name !== "Level 2 Beginner" && state.que_type !== "Assignment" ) &&  */}
                            {(state.que_type !== "Assignment") &&
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Category</label>
                                    <div className="col-sm-7 col-md-6 align-items-center d-flex ">
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input" type="radio"
                                                name="category_name"
                                                id="inlineRadio1"
                                                value="Mind Math"
                                                checked={state.category_name === "Mind Math"}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Mind Math</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input" type="radio"
                                                name="category_name"
                                                id="inlineRadio2"
                                                value="Abacus"
                                                checked={state.category_name === "Abacus"}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Abacus</label>
                                        </div>
                                    </div>
                                </div>}


                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default ViewQuestionComp