
import React, { useState, useEffect } from "react";
// import {Link } from "react-router-dom";
import Img from "./avatar.png";
import axios from "axios";
// import Switch from "../../Switch/Switch";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

// import Input from "../../../Tabs/Setup/Student/Component/Input"
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

import Backdrop from "@mui/material/Backdrop";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// #makeStyle



import url, { storageUrl } from "../../URL";
import CheckoutTable from "../CheckoutTable";
import StudentDetailProfileComp from "./StudentDetailProfileComp";
import ManagerDetailProfileComp from "./ManagerDetailProfileComp";
import TeacherDetailProfileComp from "./TeacherDetailProfileComp";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";


const ProfileComp = () => {
  const [backdrop, setBackdrop] = useState(true);

  const [photo_consent, setPhoto_consent] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const [checkPassword, setCheckPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [data, setData] = useState({
    email: "",
    firstname: "",
    lastname: "",
    profilepic: "",
    role: sessionStorage.getItem("role"),
    password: "",
    confirm_password: "",
    id: "",
  });

  const [changePass_Open, setChangePass_Open] = useState(false);
  const [load, setLoad] = useState(false);
  const [pic, setPic] = useState(null);
  const [open, setOpen] = useState(false);

  const [tab, setTab] = useState(1);

  useEffect(() => {
    loadUserData();
    loadId();

    // --- Below code is used for Ideal payment integration.
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      // console.log("success");
      setTab(2);
    }
    if (query.get("canceled")) {
      // console.log("canceled");
      setTab(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("role") === "Teacher" ||
      sessionStorage.getItem("role") === "Student" ||
      sessionStorage.getItem("role") === "Location Manager"
    ) {
      CheckPhotoConsent();
    } else {
      setBackdrop(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------This function will Fetch the User's Data--------------------

  const loadUserData = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios
      .post(url + "select_profile", obj)
      .then((response) => {
        //   console.log(response);
        let resData = response.data.data;
        console.log(resData);
        setData((pre) => {
          return {
            ...pre,
            email: resData[0].email,
            firstname: resData[0].first_name,
            lastname: resData[0].last_name,
            role: resData[0].role,
            profilepic: resData[0].profilepic,
          };
        });
        setBackdrop(false);
      })
      .catch((err) => console.log(err));

    // if(data.role ==="Student" || data.role ==="Teacher" || data.role === "Location Manager"){
    //     User_detail();
    // }
  };
  // ------- Getting the  photo Consent -----------------
  //  Use The ID from here in Edit disclaimer APi ---------
  const CheckPhotoConsent = async () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
      email: sessionStorage.getItem("username"),
      role: sessionStorage.getItem("role"),
    };
    await axios
      .post(url + "match_student", obj)
      .then((response) => {
        let resData = response.data.data;
        console.log(resData);
        let photo_con = resData[0].photo_consent === "1" ? true : false;
        setPhoto_consent(photo_con);
        setBackdrop(false);
        setOpenDialog(false);

      })
      .catch((err) => console.log(err));
  };

  // -----------This function will Fetch the Id of logged user to change the Password  --------------------
  const loadId = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios
      .post(url + "select_user_id", obj)
      .then((response) => {
        let resData = response.data.data;
        //console.log(resData);
        setData((pre) => {
          return {
            ...pre,
            id: resData[0].id,
          };
        });
      })
      .catch((err) => console.log(err));
  };
  // ===============================================================================================

  // const User_detail = () =>{
  //     const obj = { uname :sessionStorage.getItem("username") }

  //     axios.post(url+"select_user_id", obj)
  //     .then( (response) => {
  //         let resData = response.data.data;
  //         console.log(resData);
  //         setData((pre)=>{
  //             return{ ...pre,  id:resData[0].id }
  //         })
  //     }).catch( (err) => console.log(err) );
  // }

  // ===============================================================================================

  // ===============================================================================================
  const onPasswordChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.value;
    setData({ ...data, [name]: value });

    if (name === "password") {
      const reg = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
      if (value === "") {
        setCheckPassword("");
      } else {
        let result = reg.test(value);
        if (result) setCheckPassword("is-valid");
        else setCheckPassword("is-invalid");
      }
    }
  };
  // ===============================================================================================
  const onChange = (e) => {
    let file = e.target.files[0];
    setPic(file);
  };
  //=================================================================================================
  const upload_img = () => {
    setLoad(true);
    var uname = sessionStorage.getItem("username");
    var image = new FormData();
    image.append("image", pic);
    image.append("name", "profile_pic");
    image.append("uname", uname);

    axios
      .post(url + "edit_profile", image)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          setOpen(false);
          toast.success("Image uploaded successfully");
          loadUserData();
          setLoad(false);
        } else {
          setOpen(false);
          setLoad(false);
          toast.error("Image not uploaded.");
        }
      })
      .catch((err) => console.log(err));
  };
  // =====================================================================================================

  const ChangePassword = (e) => {
    e.preventDefault();
    if (data.password === data.confirm_password) {
      setLoad(true);
      const obj = { uname: sessionStorage.getItem("username"), id: data.id, pass: data.password };
      axios
        .post(url + "edit_password", obj)
        .then((res) => {
          //  console.log(res)
          if (res.data.success) {
            setChangePass_Open(false);
            toast.success("Password changed successfully");
            setLoad(false);
            setData((pre) => {
              return {
                ...pre,
                password: "",
                confirm_password: "",
              };
            });
          } else toast.error("An error occurred during password change, Please try again later.");
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Password didn't match, please enter password again");
    }
  };
  // ====================================================================================================

  const style_profile_pic = {
    height: "12em",
    width: "12em",
    border: "solid 4px #fff",
  };

  const handleToggle = async () => {

    const obj = {
      uname: sessionStorage.getItem("username"),
      role: sessionStorage.getItem("role"),
      id: sessionStorage.getItem("id"),
      photo_consent: photo_consent,
      disclaimer_signed: true,
    };
    await axios
      .post(url + "edit_disclaimer", obj)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          CheckPhotoConsent();
          setOpenSuccessDialog(true);
        } else {
          toast.error("An unexpected error occurred. Please try again after some time.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Backdrop sx={{ zIndex: 3001, color: "#fff" }} open={backdrop} ><CircularProgress style={{ color: "#fff" }} /></Backdrop>
      {/* ############################################ Modal for Uploding  Profile picture ################################################## */}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">CHANGE PROFILE PICTURE</DialogTitle>
        <DialogContent>
          <div className="row ">
            <div className="col">
              <input type="file" className="form-control-file" name="profile_pic" onChange={onChange} />
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Close</Button>
          <Button disabled={pic === null ? true : false} onClick={upload_img} color="primary">{!load ? "Upload" : <CircularProgress size={20} />}</Button>
        </DialogActions>
      </Dialog>

      {/* ########################################### Modal Popup for changing password ###################################### */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={changePass_Open}
        onClose={() => {
          setChangePass_Open(false);
          setData({ ...data, password: "", confirm_password: "" });
          setCheckPassword("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Password Dialog</DialogTitle>
        <DialogContent>
          <form onSubmit={ChangePassword} id="changePassword" >
            <div className="mb-3">
              <label >Enter Password</label>
              <input required className={`form-control ${checkPassword}`} type={showPassword ? "text" : "password"} name="password" value={data.password} onChange={onPasswordChange} />
              <div className="invalid-feedback">The password must contain 8 characters, including an uppercase (A,B,C etc.), lowercase (a,b,c etc.) & a special character(@,#,$,% etc.).</div>
            </div>
            <div className="mb-3">
              <label >Confirm Password</label>
              <input required className="form-control" type={showPassword ? "text" : "password"} name="confirm_password" value={data.confirm_password} onChange={onPasswordChange} />
            </div>
            <div className="mb-3 form-check mt-3">
              <input type="checkbox" onClick={(e) => setShowPassword(e.target.checked)} className="form-check-input" id="showPassword" />
              <label className="form-check-label" htmlFor="showPassword">Show Password</label>
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={() => {
            setChangePass_Open(false);
            setData({ ...data, password: "", confirm_password: "" });
            setCheckPassword("");
          }}>Close</Button>
          <Button type="submit" disabled={checkPassword === "is-invalid"} form="changePassword" color="primary" > {load ? <CircularProgress size={20} /> : "Change Password"}</Button>
        </DialogActions>
      </Dialog>

      {/* ****************************************** Dialog popup for Photo Consent **************************************** */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setOpenSuccessDialog(false);
          }
        }}
        disableEscapeKeyDown
        open={openSuccessDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">PHOTOGRAPH CONSENT</DialogTitle>
        <DialogContent>

          {photo_consent ? <div>
            <p>Thanks for giving photograph consent, so that we can use your child's photograph and video for Guruscool abacus promotions.</p>

            <p>We have noted your preference for your photography consent.</p>

            <p>Thank you.</p>


          </div> :
            <div>
              {/* <p>Thanks for giving photograph consent, so that we can use your child's photograph and video for Guruscool abacus promotions.</p> */}

              <p>We have noted your preference for your photography consent.</p>

              <p>Thank you.</p>


            </div>}


        </DialogContent>

        <DialogActions>

          <Button onClick={() => setOpenSuccessDialog(false)} variant="outlined" color="primary" >Close</Button>
        </DialogActions>
      </Dialog>

      {/* ################################################################################################################################## */}

      {/* ****************************************** Dialog popup for Photo Consent **************************************** */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setOpen(false);
          }
        }}
        disableEscapeKeyDown
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">MINOR (CHILD) PHOTO RELEASE FORM</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div>
            <p>I the parent or legal guardian of my child grant Guruscool abacus my permission to use
              the photographs and video for any legal use, including but not limited to: publicity,
              copyright purposes, illustration, advertising, and web content.</p>

            <p>Furthermore, I understand that no royalty, fee or other compensation shall become
              payable to me by reason of such use.</p>

            <div className="mb-3">
              <div className="form-check d-flex align-items-center">
                <input className="form-check-input me-3 mb-1" type="checkbox" id="gridCheck" name="photo_consent" checked={photo_consent} onChange={() => setPhoto_consent(!photo_consent)} />
                <label className="form-check-label" htmlFor="gridCheck">Yes I Agree.</label>
              </div>
            </div>
          </div>

          {/* </DialogContentText> */}
        </DialogContent>

        <DialogActions>
          {/* <Button onClick={handleToggle} variant="outlined" color="default" >Disagree</Button> */}
          <Button onClick={handleToggle} variant="outlined" color="primary" >Submit</Button>
        </DialogActions>
      </Dialog>

      {/* ################################################################################################################################## */}

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        {sessionStorage.getItem("role") === "Student" && (
          <>
            {/* <hr className="mt-2 mb-0 " /> */}
            <nav>
              <div className="nav" id="nav-tab" role="tablist">
                <a
                  className={tab === 1 ? "nav_tab nav-link active" : "nav_tab nav-link"}
                  data-bs-toggle="tab"
                  href="#nav-profile"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="true">
                  My Profile
                </a>

                <a
                  className={tab === 1 ? "nav_tab nav-link " : "nav_tab nav-link active"}
                  data-bs-toggle="tab"
                  href="#nav-fee"
                  role="tab"
                  aria-controls="nav-fee"
                  aria-selected="false">
                  Fees Information
                </a>
              </div>
            </nav>
            <hr className="mt-0 " />
          </>
        )}

        <div className="tab-content">
          {/* ----------------------Profile- Information------------------------------- */}
          <div
            className={tab === 1 ? "tab-pane fade show active" : "tab-pane fade"}
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile-tab">
            <div className="row d-flex justify-content-center align-self-center mt-5">

              <div className="position-relative col-auto">
                <img
                  alt="profile"
                  src={data.profilepic ? `${storageUrl}${data.profilepic}` : Img}
                  className="rounded-circle img-fluid shadow-sm"
                  style={style_profile_pic}
                />
                <div style={{ position: "absolute", bottom: ".1em", right: ".1em" }}>
                  <Tooltip title="Edit Profile Picture" placement="right">
                    <IconButton
                      className="shadow m-2 bg-white"
                      aria-label="add to shopping cart"
                      onClick={() => setOpen(true)}>
                      <EditIcon fontSize="small" style={{ color: "#e89801" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="ms-2 col-auto align-self-center">
                <h4>
                  {data.firstname} {data.lastname}
                </h4>
                <h6 className="text-muted">{data.role}</h6>
              </div>

            </div>
            <hr className="mx-auto" style={{ border: "none", borderBottom: "dotted 5px #e89801", width: "5em" }} />

            <div className="row ">
              <div className="text-center col-auto mx-auto">
                <div className="card shadow border-0">
                  <div className="card-body">
                    <h5 className="card-title mb-1 text-center ">Contact</h5>
                    <hr style={{ border: "none", borderBottom: "1px solid #e89801" }} className="m-1 mb-3" />
                    <p className="card-text text-center">
                      <EmailIcon fontSize="small" /> {data.email}
                    </p>
                  </div>
                </div>
                <p className=" mt-3">
                  To Change password Click{" "}
                  <a onClick={() => setChangePass_Open(true)} style={{ cursor: "pointer" }} className="ms-1">
                    {" "}
                    here
                  </a>
                  .
                </p>
              </div>
            </div>

            {(sessionStorage.getItem("role") === "Student") && (
              <div className="row mt-4">
                <div className="col-md-6 mx-auto rounded">
                  <div className="d-flex justify-content-center bg-light shadow align-items-center pt-2">
                    <h6 className="me-3">
                      {photo_consent ? (
                        <>
                          You have given consent to use your photograph{" "}
                          <SentimentVerySatisfiedIcon fontSize="large" style={{ color: "#e99732" }} />
                        </>
                      ) : (
                        <>
                          {" "}
                          You haven't given consent to use your photograph{" "}
                          <SentimentVeryDissatisfiedIcon fontSize="large" />
                        </>
                      )}{" "}
                    </h6>
                    {/* <Switch isOn={photo_consent} handleToggle={handleToggle} /> */}
                  </div>
                  <p className="text-end mt-2">To change <Button variant="text" size="small" onClick={() => setOpenDialog(true)}>click here</Button>.</p>
                </div>
              </div>
            )}

            {sessionStorage.getItem("role") === "Student" && <>
              <hr className="my-5" />
              <StudentDetailProfileComp />
            </>}
            {sessionStorage.getItem("role") === "Teacher" && <>
              <hr className="my-5" />
              <TeacherDetailProfileComp />
            </>}
            {sessionStorage.getItem("role") === "Location Manager" && <>
              <hr className="my-5" />
              <ManagerDetailProfileComp />
            </>}
          </div>

          {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

          {/* ----------------------Fees- Information------------------------------- */}
          {sessionStorage.getItem("role") === "Student" && (
            <div
              className={tab === 1 ? "tab-pane fade " : "tab-pane fade show active"}
              id="nav-fee"
              role="tabpanel"
              aria-labelledby="fee-tab">
              <div>
                <div className="row mt-5">
                  <div className="col-11 mx-auto">
                    <h5 className="mb-0">Table of Fees</h5>
                    <hr className="mb-2" />
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-11 mx-auto">
                    <CheckoutTable />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileComp;
