import React from "react"
import Navbar from "../../Common/Components/Navbar"
import Footer from "../../Common/Components/Footer"
import Sidebar from "../../Sidebar/Sidebar"
import ViewMessageComp from "./Component/ViewMessageComp"


const ViewMessage =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea" >
            <Sidebar />
            <ViewMessageComp />
        </div>
        <Footer />
        </>
    )
}

export default ViewMessage