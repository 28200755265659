import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
// import {toast} from "react-toastify"
import Heading from "../../../../Common/Components/Heading";
import LoadingSpinner from "../../../../Common/Components/LoadingSpinner";
import IMG_LOGO from "./Report-Logo.png";
import axios from "axios";
import url from "../../../../Common/URL";

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Button } from "@mui/material";

// ---  SVG IMPORT ---------

import TL from "./ExamSVG/TopLeft.svg";
import TR from "./ExamSVG/TopRight.svg";
import BL from "./ExamSVG/BottomLeft.svg";
import BR from "./ExamSVG/BottomRight.svg";

import Border_TL from "./TermTestSVG/BorderTopLeft.svg";
import Border_TR from "./TermTestSVG/BorderTopRight.svg";
import Border_BL from "./TermTestSVG/BorderBottomLeft.svg";
import Border_BR from "./TermTestSVG/BorderBottomRight.svg";
import { useSelector } from "react-redux";

const ViewReportComp = () => {

    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [field, setField] = useState({
        approved: "", batch_name: "", location_name: "", test_id: "",
        attempted_percentage: "", created_by: "", created_at: "", level_name: "", test_level: "",
        not_ans: "", overall_percentage: "", ques_id: "", score: "", test_mode: "",
        test_name: "", test_type: "", time_taken_abacus: "", time_taken_mm: "",
        user_ans_id: "", user_answer: "", wrong_ans: "",
        accuracy_ab: '', accuracy_mm: "", accuracy_per_ab: "", accuracy_per_mm: "",
        teacher_comment: "", total_accuracy_per: "", total_attempted_ab: "", total_attempted_mm: "",
        total_attempted_per_ab: "", total_attempted_per_mm: "", user_name: "",

        first_name: "", last_name: ""
    });
    const [medal, setMedal] = useState("");
    const [date, setDate] = useState({
        month: "", Year: ""
    });

    useEffect(() => {
        loadScores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const monthNames = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
        "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
    ];


    const loadScores = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };
        axios.post(url + "view_score", obj)
            .then((response) => {
                let resData = response.data.data;
                console.log(resData);

                let d = new Date(resData[0].created_at.toString());
                let month = monthNames[d.getUTCMonth()];
                let year = d.getUTCFullYear();
                setDate({ month: month, year: year });
                // console.log(resData[0].test_type)
                if (resData[0].test_type === "Exam") {

                    let per = ((parseFloat(resData[0].accuracy_per_ab) + parseFloat(resData[0].accuracy_per_mm)) / 2);
                    // console.log(per);
                    if (per >= 70 && per < 80) {
                        setMedal("BRONZE CHAMPION");
                    } else if (per >= 80 && per < 90) {
                        setMedal("SILVER CHAMPION");
                    } else if (per >= 90) {
                        setMedal("GOLD CHAMPION");
                    } else {
                        setMedal("--");
                    }
                }

                setField(pre => {
                    return {
                        ...pre, approved: resData[0].approved, batch_name: resData[0].batch_name, location_name: resData[0].location_name,
                        test_id: resData[0].test_id, test_level: resData[0].test_level?.split(" ")[1], level_name: resData[0].level_name?.split(" ")[1],
                        created_by: resData[0].created_by, created_at: resData[0].created_at, test_mode: resData[0].test_mode,
                        not_ans: resData[0].not_ans, overall_percentage: resData[0].overall_percentage,
                        ques_id: resData[0].ques_id, score: resData[0].score, test_name: resData[0].test_name, test_type: resData[0].test_type,
                        time_taken_abacus: resData[0].time_taken_abacus, time_taken_mm: resData[0].time_taken_mm,
                        user_ans_id: resData[0].user_ans_id, user_answer: resData[0].user_answer, wrong_ans: resData[0].wrong_ans,
                        attempted_percentage: resData[0].attempted_percentage,
                        user_name: resData[0].user_name,
                        accuracy_ab: resData[0].accuracy_ab, accuracy_mm: resData[0].accuracy_mm, accuracy_per_ab: parseFloat(resData[0].accuracy_per_ab),
                        accuracy_per_mm: parseFloat(resData[0].accuracy_per_mm), teacher_comment: resData[0].teacher_comment, total_accuracy_per: resData[0].total_accuracy_per,
                        total_attempted_ab: resData[0].total_attempted_ab, total_attempted_mm: resData[0].total_attempted_mm,
                        total_attempted_per_ab: parseFloat(resData[0].total_attempted_per_ab), total_attempted_per_mm: parseFloat(resData[0].total_attempted_per_mm)
                    };
                });

                // ----------------- This Will Fetch Name using Username -----------------------

                axios.post(url + "select_profile", { uname: resData[0].user_name })
                    .then((response) => {
                        // console.log(response);
                        if (response.data.message === "User Not Login") {
                            alert("Unable to fetch data, user was removed from the database.");
                        }
                        let x = response.data.data;
                        // console.log(x)
                        setField(pre => {
                            return {
                                ...pre, first_name: x[0].first_name.toUpperCase(), last_name: x[0].last_name.toUpperCase(),
                            };
                        });
                        setLoading(false);
                    }).catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));

    };

    const PrintDocument = () => {
        const input = document.getElementById('PDF_DOCUMENT');
        html2canvas(input, { scale: 10 })
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg", 1.0);
                var pdf = new jsPDF("p", "mm", "a4");

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                // console.log(width); console.log(height);
                field.test_type === "Exam" ? pdf.addImage(imgData, 'PNG', -1.9, .2, width + 2, height) :
                    pdf.addImage(imgData, 'PNG', -2.2, .2, width + 2, height);

                pdf.save("Report Card.pdf");
            });
    };

    const addTimes = (startTime, endTime) => {
        var times = [0, 0];
        var max = times.length;

        var a = (startTime || '')?.split(':');
        var b = (endTime || '')?.split(':');

        // normalize time values
        for (let i = 0; i < max; i++) {
            a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i]);
            b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i]);
        }

        // store time values
        for (let i = 0; i < max; i++) {
            times[i] = a[i] + b[i];
        }


        var minutes = times[0];
        var seconds = times[1];

        if (seconds >= 60) {
            var m = (seconds / 60) << 0;
            minutes += m;
            seconds -= 60 * m;
        }


        //   console.log( ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2))
        return ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item link-primary text-decoration-underline" role="button" onClick={() => navigate(-1)}>Back</li>
                    </ol>
                </nav>
                <Heading name="Report Card" />
                {loading ? <LoadingSpinner /> :
                    <div className="container-fluid">

                        <div className="row mb-4">
                            <div className="col-10 d-flex mx-auto pe-0">

                                <Button variant="contained" onClick={PrintDocument} className=" ms-auto  ">Download Report</Button>
                            </div>
                        </div>

                        <div className="row mb-5 " >
                            {/* ----------- Main Blue Box inside main BOX -------- */}
                            <div style={{ backgroundColor: field.test_type === "Exam" ? "#174873" : "#fff" }} className="col-10 mx-auto p-5 position-relative" id="PDF_DOCUMENT">


                                <img alt="corner-arrow" src={field.test_type === "Exam" ? TL : Border_TL} style={{ position: "absolute", top: 0, left: 0 }} />
                                <img alt="corner-arrow" src={field.test_type === "Exam" ? TR : Border_TR} style={{ position: "absolute", top: 0, right: 0 }} />
                                <img alt="corner-arrow" src={field.test_type === "Exam" ? BL : Border_BL} style={{ position: "absolute", bottom: 0, left: 0 }} />
                                <img alt="corner-arrow" src={field.test_type === "Exam" ? BR : Border_BR} style={{ position: "absolute", bottom: 0, right: 0 }} />
                                <div className="row mt-4">
                                    <div className="col-11 mx-auto d-flex justify-content-between">
                                        <img alt="corner-arrow" src={IMG_LOGO} className="img-fluid" width="30%" height="20%" />
                                        <div className="text-center text-white " style={{ backgroundColor: field.test_type === "Exam" ? "transparent" : "#174873" }}>
                                            {field.test_type === "Exam" ? <><h4>ABACUS NATIONAL CHALLENGE CONTEST</h4>
                                                <h4>{`REPORT ${date.year - 1} - ${date.year}`}</h4></> :
                                                <h4 className="p-3">{`ABACUS TERM REPORT ${date.month} ${date.year}`}</h4>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* ----------- Yellow Box inside main BOX -------- */}
                                <div className="row mt-5 mb-4">
                                    <div style={{ backgroundColor: "#e8aa30", color: "#174873", border: "2px solid #174873" }} className="col-11 mx-auto  p-5">
                                        {/* Heading  */}
                                        <div className="bg-white d-flex p-3" style={{ color: "#174873" }}>
                                            <h6 className="p-0 m-0">{`STUDENT NAME  :  ${field.first_name} ${field.last_name}`}</h6>
                                            <h6 className="ms-5 py-0 my-0">{`LEVEL  :  ${field.test_mode === "offline" ? field.test_level : field.level_name}`}</h6>
                                        </div>

                                        {/* Score Table */}
                                        <div className="my-5 px-3">
                                            <div className="row text-center mb-1 ">
                                                <div className="col bg-white py-5 me-1  fw-bold"> </div>
                                                <div className="col bg-white py-5 me-1  fw-bold">ATTEMPTED %</div>
                                                <div className="col bg-white py-5 me-1  fw-bold">ACCURACY %</div>
                                                <div className="col bg-white py-5 me-1  fw-bold">TIME TAKEN</div>
                                            </div>
                                            <div className="row text-center mb-1 ">
                                                <div className="col bg-white py-5 me-1  fw-bold">WITH ABACUS</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{(field.total_attempted_per_ab).toFixed(1)}</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{(field.accuracy_per_ab).toFixed(1)}</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{field.time_taken_abacus}</div>
                                            </div>
                                            <div className="row text-center mb-1 ">
                                                <div className="col bg-white py-5 me-1  fw-bold">WITHOUT ABACUS</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{field.total_attempted_per_mm.toFixed(1)}</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{field.accuracy_per_mm.toFixed(1)}</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{field.time_taken_mm}</div>
                                            </div>
                                            <div className="row text-center ">
                                                <div className="col bg-white py-5 me-1  fw-bold">TOTAL</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{((field.total_attempted_per_ab + field.total_attempted_per_mm) / 2).toFixed(2)}</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{((field.accuracy_per_ab + field.accuracy_per_mm) / 2).toFixed(2)}</div>
                                                <div className="col bg-white py-5 me-1  fw-bolder">{addTimes(field.time_taken_abacus, field.time_taken_mm)}</div>
                                            </div>
                                        </div>

                                        {/* Medal Category */}
                                        {field.test_type === "Exam" && <div className="bg-white text-center py-2 mb-5">
                                            <h6 className="p-0 m-0">{`MEDAL CATEGORY : ${medal}`}</h6>
                                        </div>}

                                        {/*Teacher Comment */}
                                        <div className="bg-white text-center py-2 mb-5">
                                            <h5 style={{ fontWeight: "500" }}>COMMENTS</h5>
                                            <h5 style={{ fontWeight: "900", letterSpacing: 1 }} className=" my-4">{field.teacher_comment !== (undefined || "") ? field.teacher_comment : "--"}</h5>
                                        </div>

                                        {/* Different Medals Description */}
                                        {field.test_type === "Exam" && <div className="bg-white py-3">
                                            <div className="text-center mb-3">
                                                <h6><i style={{ color: "gold" }} className="fa fa-star me-1"></i> GOLD CHAMPION 90 -100</h6>
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <h6><i style={{ color: "silver" }} className="fa fa-star me-1"></i> SILVER CHAMPION 80 -90</h6>
                                                <h6><i style={{ color: "#bb6e4c" }} className="fa fa-star me-1"></i> BRONZE CHAMPION 70 -80</h6>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default ViewReportComp;
