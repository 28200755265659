import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import url from "../../../../../Common/URL";
import Heading from "../../../../../Common/Components/Heading";
import { toast } from "react-toastify";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const EnquiryFormComp = () => {

    const [loc, setLoc] = useState([]);
    const [state, setState] = useState({
        fname: "", lname: "", email: "", phone: "", preferred_time: "",
        category: "", location: "", message: "", campaign: "", preferred_date: ""
    });

    useEffect(() => {
        loadLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------

    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    };

    // ====================== OnChange =========================

    const onChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setState(pre => {
            return { ...pre, [name]: value };
        });
    };

    const onSubmit = async e => {
        e.preventDefault();

        const obj = {
            created_by: sessionStorage.getItem("username"), fname: state.fname, lname: state.lname,
            phone: state.phone, message: state.message, email: state.email, preferred_time: state.preferred_time,
            category: state.category, location: state.location, campaign: state.campaign, preferred_date: state.preferred_date,
        };

        const formData = new FormData();
        Object.keys(obj).forEach(key => formData.append(key, obj[key]));


        await axios
            .post(url + "add_enquiry", formData)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    toast.info("Enquiry Submitted, You will be notified soon.");
                } else {
                    toast.error("An error occurred while submitting the Enquiry, please try again later!");
                }
            })
            .catch((err) => console.log(err));
        setState({
            fname: "", lname: "", email: "", phone: "", preferred_time: "",
            category: "", location: "", message: "", campaign: "", preferred_date: ""
        });
    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">CRM</li>
                        <li className="breadcrumb-item active" aria-current="page">General Enquiry Form</li>
                    </ol>
                </nav>

                <Heading name="General Enquiry Form" />

                <div className="container-fluid shadow-sm mb-5" >
                    <form onSubmit={onSubmit}>
                        <h6 className="mb-3 d-flex align-items-center"><ErrorOutlineIcon />&nbsp;All the fields are mandatory and to be filled.</h6>
                        <hr />
                        <div className="mb-3 row">
                            <div className="col ">

                                <input required name="fname" value={state.fname} onChange={onChange} type="text" className="form-control  shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} placeholder="First name" />
                            </div>
                            <div className="col ">

                                <input required name="lname" value={state.lname} onChange={onChange} type="text" className="form-control  shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} placeholder="Last name" />
                            </div>
                        </div>

                        <div className="mb-3 row">

                            <div className="col ">
                                <input required name="email" value={state.email} onChange={onChange} type="email" className="form-control  shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                                <small className="form-text text-muted ms-2">We'll never share your email with anyone else.</small>
                            </div>

                            <div className="col ">
                                <input required name="phone" value={state.phone} onChange={onChange} type="tel" placeholder="Phone Number" className="form-control  shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                        </div>

                        <div className="mb-3 row">

                            <div className="col">
                                <select name="category" value={state.category} onChange={onChange} className=" form-select shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} id="validationTooltip04" required>
                                    <option value="">Choose Category</option>
                                    <option value="Admission">Admission</option><option value="General Enquiry">General Enquiry</option><option value="Other">Other</option>
                                </select>
                            </div>

                            <div className=" col">
                                <select name="location" value={state.location} onChange={onChange} className=" form-select shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} id="validationTooltip04" required>
                                    <option value="">Choose Location</option>
                                    {loc.map((x, i) => <option key={i} value={x.name}>{x.name}</option>)}
                                </select>
                            </div>
                        </div>


                        <div className="mb-3 row">
                            <div className="col">
                                <label className="col-form-label">Preferred appointment date</label>
                                <input required name="preferred_date" value={state.preferred_date} onChange={onChange} type="date" className="form-control  shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} />
                            </div>
                            <div className="col">
                                <label className="col-form-label">Preferred appointment time</label>
                                <input required name="preferred_time" value={state.preferred_time} onChange={onChange} type="time" className="form-control  shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} />
                            </div>
                            <div className="col">
                                <label className="col-form-label">How did you hear about us?</label>
                                <select name="campaign" value={state.campaign} onChange={onChange} className="form-select  shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} id="validationTooltip04" required>
                                    <option value="">Select one</option>
                                    <option value="Advertisement">Advertisement</option>
                                    <option value="Google Search">Google Search</option><option value="Friend Referral">Friend Referral</option>
                                    <option value="WhatsApp">WhatsApp</option><option value="Facebook Groups">Facebook Groups</option>
                                    <option value="Blogs">Blogs</option><option value="Others">Others</option>
                                </select>
                            </div>
                        </div>

                        <div className="mb-4 mb-3">
                            <textarea required name="message" value={state.message} onChange={onChange} className="form-control shadow-sm " style={{ border: 0, borderLeft: ".3em solid #174873" }} rows="5" placeholder="Enter Your Message here..."></textarea>
                        </div>

                        <Button variant="contained" type="submit">Submit</Button>
                    </form>
                </div>
            </div>
        </>
    );
};
export default EnquiryFormComp;