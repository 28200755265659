import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../../Common/Components/Heading";
// import {toast} from "react-toastify"
import LoadingSpinner from "../../../../Common/Components/LoadingSpinner";

import RestorePageIcon from '@mui/icons-material/RestorePage';

import { ReadableDateTime } from '../../../../Common/Functions/StringDateTime';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import axios from "axios";
import url from "../../../../Common/URL";
// import { toast } from "react-toastify";
import { Summarize } from "@mui/icons-material";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import RenderTestExamAssignmentPreviewComponent from "../../../../Common/Components/RenderTestExamAssignmentPreviewComponent";
import ViewTestHistoryComp from "./ViewTestHistoryComp";

const ExamAndTestHistoryComp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const role = sessionStorage.getItem("role");
  const [state] = useState({
    uname: sessionStorage.getItem("username"),
    role: sessionStorage.getItem("role"),
  });

  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [selectedUserAnswerId, setSelectedUserAnswerId] = useState(null);

  const [openTestExamAssignmentPreviewDrawer, setOpenTestExamAssignmentPreviewDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showStudentAnswers, setShowStudentAnswers] = useState(false);

  const Columns = useMemo(() => ((state.role === "Superadmin") || (state.role === "Systemadmin") || (state.role === "Location Manager") || (state.role === "Teacher") || (state.role === "Admin")) ?
    [
      { header: 'Name', size: 40, id: 'name', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
      { header: 'Level', size: 40, accessorKey: 'level_name' },
      {
        header: 'Test Type', size: 40, id: 'test_type', accessorFn: rowData => rowData.generator_test === 1 ?
          <div className="hstack gap-2">
            <span>{rowData.test_type}</span>
            <Tooltip title="Generated from generator" placement="right" >
              <SettingsSuggestIcon className="ms-2" style={{ cursor: "pointer", fontSize: '20px' }} color="primary" />
            </Tooltip>
          </div> : rowData.test_type
      },
      { header: 'Test Name', size: 40, id: 'test_name', accessorFn: rowData => rowData.test_mode === "offline" ? (<span className="d-flex align-items-center">{rowData.test_name}&nbsp;<CloudOffIcon style={{ color: "#810000", fontSize: '15px' }} /></span>) : rowData.test_name },
      { header: 'Date', size: 40, id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },
    ] :
    [
      { header: 'Level', size: 40, accessorKey: 'level_name' },
      {
        header: 'Test Type', size: 40, id: 'test_type', accessorFn: rowData => rowData.generator_test === 1 ?
          <div className="hstack gap-2">
            <span>{rowData.test_type}</span>
            <Tooltip title="Generated from generator" placement="right" >
              <SettingsSuggestIcon className="ms-2" style={{ cursor: "pointer", fontSize: '20px' }} color="primary" />
            </Tooltip>
          </div> : rowData.test_type
      },
      { header: 'Test Name', size: 40, sorting: false, id: 'test_name', accessorFn: rowData => rowData.test_mode === "offline" ? (<span className="d-flex align-items-center">{rowData.test_name}&nbsp;<CloudOffIcon style={{ color: "#810000", fontSize: '15px' }} /></span>) : rowData.test_name },
      { header: 'Date', size: 40, id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },

    ], [],
  );

  useEffect(() => {
    loadScores();
  }, []);

  const [data, setData] = useState([]);

  // ------------------------Fetching Scores table data-------------------------------------
  const loadScores = async () => {
    const obj = { uname: state.uname };
    if (state.role === "Student") {
      await axios.post(url + "select_score", obj)
        .then((response) => {
          // console.log(response)
          if (response.data.success) {
            let b = response.data.data;
            let remove_assignment_scores = b !== null ? b.filter(x => x.test_type !== "Assignment") : b;
            let a = remove_assignment_scores !== null ? remove_assignment_scores.reverse() : remove_assignment_scores;
            console.log(a);
            setLoading(false);
            a !== null ? setData(a) : setData([]);
          } else {
            setLoading(false);
            setData([]);
          }
        })
        .catch((err) => console.log(err));
    }
    else {
      await axios.post(url + "scores", obj)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            let a = response.data.data;
            // let remove_assignment_scores =  a !== null ?  a.filter(x => x.test_type !== "Assignment") : a;
            // let reverse = remove_assignment_scores !== null ? remove_assignment_scores.reverse() : remove_assignment_scores;
            let reverse = a !== null ? a.reverse() : a;

            // console.log(a);
            setLoading(false);
            reverse !== null ? setData(reverse) : setData([]);
          } else {
            setLoading(false);
            setData([]);
          }
        })
        .catch((err) => console.log(err));
    }

  };


  return (
    <>
      {/* Side Drawer for Viewing Test History Details */}
      <Drawer
        PaperProps={{ style: { width: '95%' } }}
        anchor='right'
        open={openDetailsDrawer}
        onClose={() => setOpenDetailsDrawer(false)}
      >
        <ViewTestHistoryComp primaryId={selectedUserAnswerId} setOpenDetailsDrawer={setOpenDetailsDrawer} loadParentData={loadScores} />
      </Drawer >


      {/* Side Drawer for Previewing TEST/EXAM/ASSIGNMENT Answers given by the student */}
      <Drawer
        PaperProps={{ style: { width: '95%' } }}
        anchor='right'
        open={openTestExamAssignmentPreviewDrawer}
        onClose={() => setOpenTestExamAssignmentPreviewDrawer(false)}
      >
        <RenderTestExamAssignmentPreviewComponent data={selectedRowData} showStudentAnswers={showStudentAnswers} setOpenDrawer={setOpenTestExamAssignmentPreviewDrawer} />
      </Drawer >


      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Tests</li>
            <li className="breadcrumb-item active">Tests</li>
            {/* <li className="breadcrumb-item active" aria-current="page">Test History</li> */}
          </ol>
        </nav>

        <Heading name="Tests" />
        {loading ? <LoadingSpinner /> :
          <div className="container-fluid">

            <div className="row mb-4">
              <nav className="col-12 mx-auto ">
                {role === "Student" ?
                  <div className="nav border-bottom" id="nav-tab" role="tablist">
                    <Link className="nav_tab nav-link" to="/all-tests" role="tab">My Tests</Link>
                    <Link className="nav_tab nav-link active" to="/test-history" role="tab" >Exam & Test History</Link>
                    <Link className="nav_tab nav-link " to="/tasks-history" role="tab" >Mind-Math & Assignment History</Link>
                  </div> :
                  // Role other that Student (Admin, SuperAdmin, SystemAdmin)
                  <div className="nav border-bottom" id="nav-tab" role="tablist">
                    <Link className="nav_tab nav-link active" to="/test-history" role="tab" >Exam, Test & Assignment History</Link>
                  </div>
                }
              </nav>
            </div>

            <div className="row mt-4 mb-3">
              <div className="col-auto ">
                <p className="alert alert-info p-2 alert" style={{ fontWeight: "500" }}>Reports are generated for Test and Exam only.</p>
              </div>

              <div className="col-12 ">
                <div className="hstack gap-3">
                  <div className="p-2  shadow-sm border"><p style={{ margin: 0, fontWeight: "500" }}><Summarize style={{ color: "#4BB543" }} />&nbsp; Reports Approved & Available To Download</p></div>
                  <div className="p-2  shadow-sm border"><p style={{ margin: 0, fontWeight: "500" }}><Summarize style={{ color: "#AEAEAE" }} />&nbsp; Report Not Available To Download</p></div>
                  <div className="p-2  shadow-sm border"><p style={{ margin: 0, fontWeight: "500" }}><CloudOffIcon style={{ color: "#810000" }} />&nbsp; Test given Offline</p></div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 ">

                <MaterialReactTable
                  columns={Columns}
                  data={data}
                  enableRowNumbers
                  initialState={{ density: 'compact' }}
                  enableDensityToggle={false}
                  positionActionsColumn='first'
                  enableRowActions
                  getRowId={(originalRow) => originalRow.user_ans_id}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      setSelectedUserAnswerId(row.id);
                      setOpenDetailsDrawer(true);
                    },
                    sx: { cursor: 'pointer' },
                  })}
                  renderRowActions={(row, index) => (
                    <Stack direction="row" >
                      {row.row.original.test_mode !== "offline" &&
                        <Tooltip placement="right" title="View Your Answers">
                          <IconButton onClick={(event) => {
                            event.stopPropagation();
                            let rowData = row.row.original;
                            setSelectedRowData(rowData);
                            setShowStudentAnswers(true);
                            setOpenTestExamAssignmentPreviewDrawer(true);
                          }}>
                            <RestorePageIcon />
                          </IconButton></Tooltip>}

                      {(row.row.original.test_type !== "Assignment"
                        && !row.row.original.test_type.includes("Practice")) &&
                        <Tooltip placement="right" title={row.row.original.approved === "1" ? 'View Report' : 'Report Not Approved'}>
                          <span>
                            <IconButton
                              style={{ color: row.row.original.approved === "1" && "#4BB543" }}
                              disabled={role !== "Student" ? false : (row.row.original.approved === "1" ? false : true)}
                              onClick={(event) => {
                                event.stopPropagation();
                                setSelectedUserAnswerId(row.row.original.user_ans_id);
                                setOpenDetailsDrawer(true);
                              }}
                            >
                              <Summarize />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                    </Stack>
                  )}
                />
              </div>
            </div>
          </div>}
      </div>
    </>
  );
};
export default ExamAndTestHistoryComp

