import React, { useState, useEffect } from "react";
import Input from "../../Student/Component/Input";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";
import axios from "axios";
import url, { storageUrl } from "../../../../Common/URL";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const EditLocationComp = () => {
  let message = "";
  const navigate = useNavigate();

  const [disable, setDisable] = useState(true);
  const [form_data, setForm_data] = useState(true); //Used to compare if any update is done in form data.

  const [openDialog, setOpenDialog] = useState(false);

  const [link_upload_spinner, setLink_upload_spinner] = useState(false); // This is used in Calendar link upload  Popup
  // ----------------------- For Upload batch Calendar File------------------------------------------------
  const [batchFile, setBatchFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState([]);
  const { id } = useParams();

  const [field, setField] = useState({
    uname: sessionStorage.getItem("username"),
    name: "",
    city: "",
    state: "",
    country: "",
    addressline1: "",
    addressline2: "",
    zip: "",
    phone: "",
    location_type: "",
    manager_name: "",
    updated_by: "",
    updated_date: "",
    location_picture: "",
    location_file: "",
    fname: "",
    lname: "",
  });

  useEffect(() => {
    loadLocations();
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadLocations = (res) => {
    const obj = {
      uname: sessionStorage.getItem("username"),
      id: id,
    };
    axios
      .post(url + "fetch_single_location", obj)
      .then((response) => {
        let resData = response.data.data;
        console.log(resData);
        setForm_data(resData);
        //This will help in checking any update done in form, so we can enable or disable the save form button
        setField({
          location_id: resData[0].location_id,
          fname: resData[0].fname,
          lname: resData[0].lname,
          name: resData[0].name,
          city: resData[0].city,
          state: resData[0].state,
          country: resData[0].country,
          addressline1: resData[0].addressline1,
          addressline2: resData[0].addressline2,
          zip: resData[0].zip,
          phone: resData[0].phone,
          location_type: resData[0].location_type,
          manager_name: resData[0].manager_name,
          updated_by: resData[0].updated_by,
          updated_date: resData[0].updated_date,
          location_file: resData[0].location_file,
        });
        res !== undefined && res();
      })
      .catch((err) => console.log(err));
    setDisable(true);
  };

  // ----------------------- This function will Fetch all the Existing Users -------------------------------------

  const loadUser = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    axios
      .post(url + "fetch_users", obj)
      .then((response) => {
        //   console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          var user = a.filter((x) => x.role === "Teacher" || x.role === "Manager");
          // console.log(user)
          setUser(user);
        } else {
          setUser([]);
        }
      })
      .catch((err) => console.log(err));
  };

  // ----------------------------------------- Onchange Function for all Inputs ---------------------

  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;

    if (form_data[0][name] !== value) {
      setDisable(false);
    } else {
      setDisable(true);
    }

    setField((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  // ------------------------------------- functions for Submitting Country and state ------------------------------------

  const selectCountry = (val) => {
    setField((preValue) => {
      return {
        ...preValue,
        country: val,
      };
    });

    // making save button disable or enable
    if (form_data[0].country !== val) {
      setDisable(false);
    } else {
      setDisable(true);
    }

  };

  const selectRegion = (val) => {
    setField((preValue) => {
      return {
        ...preValue,
        state: val,
      };
    });

    // making save button disable or enable
    if (form_data[0].state !== val) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  //-------------------------- onSubmit function for Submitting the  Forms---------------------------
  const onSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    setLoading(true);
    const data = new FormData();
    data.append("image", field.location_picture);

    /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    var newdate = year + "-" + month + "-" + day;

    /*---------------Creating object to send data through Axios------------------------- */
    const obj = {
      uname: sessionStorage.getItem("username"),
      id: id,
      fname: field.fname,
      lname: field.lname,
      name: field.name,
      city: field.city,
      state: field.state,
      country: field.country,
      addressline1: field.addressline1,
      addressline2: field.addressline2,
      zip: field.zip,
      location_type: field.location_type,
      manager_name: field.manager_name,
      updated_by: field.uname,
      updated_date: newdate,
      phone: field.phone,
    };
    // console.log("data" , obj)
    axios
      .post(url + "edit_location", obj)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          loadLocations();
          toast.success("Updated successfully!");
        } else {
          message = res.data.message;
          toast.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Update failed, please try later');
      }).finally(() => {
        setLoading(false);
      });
  };

  //--------------- onCalendar_link_submit function for Submitting the  Important Links according to the location & batches--------------

  const onCalendar_link_submit = () => {
    setLink_upload_spinner(true);
    var uname = sessionStorage.getItem("username");
    var data = new FormData();
    data.append("file", batchFile);
    data.append("location_id", field.location_id); // here I'm sending location ID , because it was said to me by Smith Sir
    // data.append("calender_link", field.calender_link);
    data.append("uname", uname);

    axios
      .post(url + "add_link", data)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          const promise = new Promise((resolve, reject) => loadLocations(resolve));
          promise.then(() => {
            setLink_upload_spinner(false);
            toast.success("Uploaded successfully");
            setOpenDialog(false); // For closing the Popup
            setBatchFile(null); // For making fields blank again
          });
        } else {
          setLink_upload_spinner(false);
          setOpenDialog(false); // For closing the Popup
          setBatchFile(null); // For making fields blank again
          toast.error("Data not updated, Please try again later.");
        }
      })
      .catch((err) => console.log(err));
  };
  //=======================================================
  const onChangeFile = (e) => {
    let file = e.target.files[0];
    setBatchFile(file);
  };

  return (
    <>
      {/* =============================== Dialog popup for Adding Google & Batch Calendar Link =====================================*/}

      <Dialog fullWidth={true} maxWidth="sm" open={openDialog}>
        <DialogTitle id="max-width-dialog-title">
          Add Location Calendar File <hr className="p-0 mt-2 mb-0" />
        </DialogTitle>
        <DialogContent>
          <div className="row ">
            <div className="col-12 ">
              <div className="mb-3 ">
                <label>Location Calendar File</label>

                <input type="file" className="form-control-file" name="location_file" onChange={onChangeFile} />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpenDialog(false);
              setBatchFile(null);
            }}>
            Close
          </Button>
          <Button disabled={batchFile === null ? true : false} onClick={() => onCalendar_link_submit()} color="primary" > {!link_upload_spinner ? "Save" : <CircularProgress size={20} />}</Button>
        </DialogActions>
      </Dialog>

      {/* ===========================================================================================================================*/}

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item">
              <Link to="/location">Location List</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Edit Location
            </li>
          </ol>
        </nav>
        <Heading name={`Edit Location - ${field.name}`} />

        <div className="container-fluid">
          <form onSubmit={onSubmit}>
            <div className="row mb-4">
              <div className="col-12 col-md-12 d-flex justify-content-between align-items-end ">
                <h5>Location Information</h5>
                <div>
                  <Button variant="contained" onClick={() => navigate("/view-location/" + field.location_id)} >
                    Back
                  </Button>
                  <Button variant="contained" type="submit" disabled={disable || loading} className="ms-3">
                    {loading ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                  </Button>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-11 col-md-6 mx-auto">
                <Input
                  field="Location Name"
                  type="text"
                  placeholder="Add text"
                  name="name"
                  className="form-control"
                  value={field.name}
                  onChange={onChange}
                  required={true}
                />
                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                  <div className="col-sm-7 col-md-7 ">
                    <textarea
                      className="form-control"
                      placeholder="Add text"
                      name="addressline1"
                      value={field.addressline1}
                      onChange={onChange}
                      required></textarea>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label ">Address Line 2</label>
                  <div className="col-sm-7 col-md-7 ">
                    <textarea
                      className="form-control"
                      placeholder="Add text"
                      name="addressline2"
                      value={field.addressline2}
                      onChange={onChange}></textarea>
                  </div>
                </div>

                <Input
                  field="City"
                  type="text"
                  placeholder="Add text"
                  name="city"
                  className="form-control"
                  value={field.city}
                  onChange={onChange}
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                  <div className="col-sm-7 col-md-7 ">
                    <CountryDropdown
                      className="form-select"
                      name="country"
                      value={field.country}
                      onChange={(val) => selectCountry(val)}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label">Province</label>
                  <div className="col-sm-7 col-md-7 ">
                    <RegionDropdown
                      className="form-select"
                      name="state"
                      country={field.country}
                      value={field.state}
                      onChange={(val) => selectRegion(val)}
                    />
                  </div>
                </div>
                <Input
                  field="Zip"
                  type="number"
                  placeholder="Add text"
                  name="zip"
                  className="form-control"
                  value={field.zip}
                  onChange={onChange}
                />
              </div>

              <div className="col-11 col-md-6 mx-auto">
                <Input
                  name="phone"
                  onChange={onChange}
                  field="Location Contact No."
                  type="number"
                  placeholder="Add Number"
                  className="form-control"
                  value={field.phone}
                  required={false}
                />

                {/* <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label ">Location Manager</label>
                  <div className="col-sm-7 col-md-7 ">
                    <select
                      name="manager_name"
                      value={`${field.manager_name}^${field.fname}^${field.lname}`}
                      className="form-select"

                      onChange={(e) => {
                        let d1 = e.target.value;

                        if (form_data[0][e.target.name] !== d1.split("^")[0]) {
                          setDisable(false);
                        } else {
                          setDisable(true);
                        }
                        setField({
                          ...field,
                          manager_name: d1.split("^")[0],
                          fname: d1.split("^")[1],
                          lname: d1.split("^")[2],
                        });
                      }}>
                      <option value="">Choose</option>
                      {user.map((data, i) => (
                        <option
                          key={i}
                          value={`${data.username}^${data.first_name}^${data.last_name}`}>{`${data.first_name} ${data.last_name}`}</option>
                      ))}
                    </select>
                  </div>
                </div> */}
                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Location Type</label>
                  <div className="form-check form-check-inline w-auto  ms-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="location_type"
                      id="inlineRadio1"
                      value={1}
                      checked={field.location_type === 1}
                      onChange={onChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Direct
                    </label>
                  </div>
                  <div className="form-check form-check-inline w-auto ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="location_type"
                      id="inlineRadio2"
                      value={0}
                      checked={field.location_type === 0}
                      onChange={onChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Franchise
                    </label>
                  </div>
                </div>
                {/* 
                <div className="mb-3 row">
                    <label className="col-sm-5 col-form-label">Calendar Link</label>
                    <div className="col-sm-7 col-md-7 ">
                        <input className="form-control bg-white" type="text"  placeholder="--" name="calender_link" defaultValue={field.calender_link} />
                    </div>
                </div> */}

                <div className="mb-3 row ">
                  <label className="col-sm-5 col-form-label">Location Calendar PDF</label>
                  {field.location_file !== "" ? (
                    <div className="col-sm-7 col-md-7 d-flex align-items-center">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`${storageUrl}${field.location_file}`}>
                        Download Calendar PDF
                      </a>
                    </div>
                  ) : (
                    <div className="col-sm-7 col-md-7 d-flex align-items-center">
                      <p className="m-0 bold" style={{ fontWeight: "500" }}>
                        No File Present
                      </p>
                    </div>
                  )}
                </div>

                <hr className="p-0 mb-1 w-50" />
                <div className="mb-3 row ">
                  <label className=" col-auto col-form-label font" style={{ fontWeight: "500" }}>
                    Edit Location Calendar File
                    <IconButton size="medium" onClick={() => setOpenDialog(true)} className="ms-2">
                      <EditIcon color="primary" />
                    </IconButton>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditLocationComp;
