import React, { useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../../../Common/Components/Heading";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ReactTooltip from 'react-tooltip';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import "./HelpVideo.css"
import { Data } from "./HelpVideoData"
import { useSelector } from "react-redux";

const HelpVideosComp = () => {
    const [open, setOpen] = useState(false);
    const [externalOpen, setExternalOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    return (
        <>
            <ReactTooltip place="right" effect="solid" delayShow={300} />

            {/* Dialog for playing YouTube Abacus Videos */}
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent style={{ padding: 0 }}>
                    <div className="ratio ratio-4x3">
                        <iframe
                            src={`https://www.youtube.com/embed/${videoUrl}?rel=0`}
                            title="YouTube video player" frameBorder="0"
                            allowFullScreen="0"
                            allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </DialogContent>

            </Dialog>

            {/* Dialog for playing Externally Hosted Abacus Videos */}
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={externalOpen}
                onClose={() => setExternalOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent style={{ padding: 0 }}>
                    <div className="ratio ratio-16x9">
                        <iframe
                            src={videoUrl}
                            scrolling="no"
                            title="YouTube video player" frameBorder="0"
                            allowFullScreen="0"
                            allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </DialogContent>

            </Dialog>

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Help Videos</li>
                    </ol>
                </nav>

                <Heading name="Help Videos" />
                <div className="container-fluid ">
                    <div className="row mb-5">
                        <div className="col-12 ">
                            <nav>
                                <div className="nav mb-4" id="nav-tab" role="tablist">
                                    <a className="nav_tab nav-link active" data-bs-toggle="tab" href="#nav-youtube-hosted" role="tab" aria-controls="nav-youtube-hosted" aria-selected="true">Youtube Hosted</a>
                                    <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-externally-hosted" role="tab" aria-controls="nav-externally-hosted" aria-selected="false">Externally Hosted</a>
                                </div>
                            </nav>

                            <div className="tab-content" id="nav-tabContent">
                                {/* ---------------------- Youtube Hosted ------------------------------- */}
                                <div className="tab-pane fade show active" id="nav-youtube-hosted" role="tabpanel" aria-labelledby="nav-helpform">
                                    <div className="row">
                                        {Data.map((x, i) => (
                                            <div className="col-md-3 mb-4" key={i}>
                                                <div className="card shadow-sm" style={{ borderLeft: ".2rem solid #3F51B5" }}>
                                                    <div className="card-body">
                                                        <h6 className="card-title">{`${x.number} : ${x.name}`}</h6>
                                                        <p className="card-text">{`Level ${x.level}`}</p>
                                                        <IconButton aria-label="play"
                                                            onClick={() => {
                                                                setVideoUrl(x.url);
                                                                setOpen(true);
                                                            }}
                                                            className="shadow-sm" data-tip="Watch Now">
                                                            <PlayArrowIcon color="primary" />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* ----------------------- End ------------------------------- */}

                                {/* ------------------------ Externally Hosted ------------------------------- */}
                                <div className="tab-pane fade" id="nav-externally-hosted" role="tabpanel" aria-labelledby="nav-helpdoc">
                                    <div className="row">
                                        <div className="col-md-3 mb-4" >
                                            <div className="card shadow-sm" style={{ borderLeft: ".2rem solid #3F51B5" }}>
                                                <div className="card-body">
                                                    <h6 className="card-title">{`1 : Subtracting 1 Using 5's Complement`}</h6>
                                                    <p className="card-text">{`Level 1`}</p>
                                                    <IconButton aria-label="play"
                                                        onClick={() => {
                                                            setVideoUrl("https://videomanapp.com/frame/2pzgd/");
                                                            setExternalOpen(true);
                                                        }}
                                                        className="shadow-sm" data-tip="Watch Now">
                                                        <PlayArrowIcon color="primary" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* -------------------------- End -------------------------- */}
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpVideosComp