import React from "react"
import Navbar from "../Common/Components/Navbar"
// import Footer from "../Common/Footer"
import Reset_pass_comp from "./Component/Reset_pass_comp"


const ResetPass =()=>{
    return(
        <>
       
        <Navbar />
        <div className="d-flex ">
            <Reset_pass_comp />
        </div>
        </>
    )
}

export default ResetPass