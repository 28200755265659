import React from "react"
import Navbar from "../../Common/Components/Navbar"
import Footer from "../../Common/Components/Footer"
import Sidebar from "../../Sidebar/Sidebar"
import HelpVideosComp from "./Component/HelpVideosComp"


const HelpVideos =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <HelpVideosComp />
        </div>
        <Footer />
        </>
    )
}

export default HelpVideos