import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Chip } from "@mui/material";

const StatusChip = ({ status }) => {
    return status === 1 ? (
        <Chip color="success" icon={<CheckCircleIcon />} size="small" label="done" />
    ) : (
        <Chip color="error" icon={<ErrorIcon />} size="small" label="pending" />
    );
};

export default StatusChip;