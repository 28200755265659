import React from "react"
import Navbar from "../../../../Common/Components/Navbar"
import Footer from "../../../../Common/Components/Footer"
import Sidebar from "../../../../Sidebar/Sidebar"
import EditEnquiryComp from "./Component/EditEnquiryComp"

const EditEnquiry =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <EditEnquiryComp />
        </div>
        <Footer />
        </>
    )
}

export default EditEnquiry