import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../Common/Components/Footer";
import Heading from "../../../Common/Components/Heading";
import Navbar from "../../../Common/Components/Navbar";
import Sidebar from "../../../Sidebar/Sidebar";

import { toast } from "react-toastify";

import Add from '@mui/icons-material/Add';

import axios from "axios";
import { useConfirm } from 'material-ui-confirm';
import url from "../../../Common/URL";

import { Alert, AlertTitle } from "@mui/material";
import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import { Delete } from "@mui/icons-material";
import { ReadableDateTime } from "../../../Common/Functions/StringDateTime";

const User = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  });

  const Columns = useMemo(
    () => [
      {
        header: 'Name', size: 40, id: 'first_name', accessorFn: rowData => `${rowData.first_name} ${rowData.last_name}`,
      },
      {
        accessorKey: 'username', size: 40, //normal accessorKey
        header: 'Email',
      },
      {
        accessorKey: 'role', size: 40,
        header: 'Role',
      },

      { header: 'Created On', size: 40, id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },

      {
        accessorFn: (row) => row.active === '1' ? 'Active' : 'Inactive',
        id: 'active',
        header: 'Status', size: 40,
      },
    ],
    [],
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------------------------Fetching users table data-------------------------------------
  const loadUsers = async () => {
    const obj = { uname: state.uname };
    axios.post(url + "fetch_users", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          console.log(a);
          setData(a);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      }).catch((err) => console.log(err));
  };

  // ---------------------------------deleting a User from the Users Table---------------------------
  function onDelete(id, resolve) {
    const delObj = {
      id: id,
      uname: state.uname,
      tokenId: 123,
    };
    axios.post(url + "delete_user", delObj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  }


  return (
    <>
      <Navbar />
      <div className="d-flex mainContentArea">
        <Sidebar />
        <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-light py-1 mt-2 align-items-center">
              <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
              <li className="breadcrumb-item">Setup</li>
              <li className="breadcrumb-item active" aria-current="page">Users</li>
            </ol>
          </nav>

          <Heading name="Users" />

          <div className="container-fluid">

            <Alert severity="warning" >
              <AlertTitle><strong>Information:</strong></AlertTitle>
              You can only Create Users with Roles <strong>Superadmin, Admin, and Accountant</strong> from this <strong>User Module.</strong> For creating <strong>Teacher, Student, and Manager Role Users</strong>, please navigate to the respective modules inside the
              <strong>Setup Menu.</strong>
            </Alert>

            <div className="row my-4">
              <div className="col">
                <Alert severity="info" >
                  Now, you can update the <strong>Password</strong> and <strong>Username</strong> of users by navigating to the 'View User' and 'Edit User' screens, respectively.
                </Alert>
              </div>
              <div className="col-auto">
                <Button variant="contained" onClick={() => navigate("/add-user")} >Add User <Add /></Button>
              </div>
            </div>
            <div className="row ">
              <div className="col-12 mx-auto mb-5">
                <MaterialReactTable
                  state={{ isLoading: loading }}

                  columns={Columns}
                  data={data}
                  enableRowNumbers
                  enableRowSelection
                  initialState={{ density: 'compact' }}
                  enableDensityToggle={false}

                  getRowId={(originalRow) => originalRow.id}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => navigate("/view-user/" + row.id),
                    sx: { cursor: 'pointer' },
                  })}

                  renderTopToolbarCustomActions={({ table }) => (
                    <Button variant="contained" color="error" startIcon={<Delete />}
                      disabled={table.getSelectedRowModel().rows.length ? false : true}
                      onClick={() => {
                        confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                          .then(() => {
                            let selectedRows = table.getSelectedRowModel().rows;
                            // console.log(selectedRows);
                            const delPromise = selectedRows.map((row) => {
                              return new Promise((resolve, reject) => {
                                onDelete(row.id, resolve);
                              });
                            });
                            Promise.all(delPromise).then(() => {
                              loadUsers();
                              table.resetRowSelection();
                              toast.info(selectedRows.length + " Row(s) Deleted!");
                            });
                          });
                      }}
                    >Delete
                    </Button>
                  )}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default User

