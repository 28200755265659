import React  from "react";
import {Link} from "react-router-dom";
import Heading from "../../../Common/Components/Heading";


const DocumentComp =()=>{


return(
    <>
    
    <div className="container-fluid  ">
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Documentation</li>
            </ol>
        </nav> 

        <Heading name="Documentation" />
        <div className="container mb-5">
          
        </div>
    </div>
    </>
)
}

export default DocumentComp