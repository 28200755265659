import React from "react"
import Navbar from "../../../Common/Components/Navbar"
import Footer from "../../../Common/Components/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import TransactionTableComp from "./Component/TransactionTableComp"


const TransactionTable = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                <Sidebar />
                <TransactionTableComp />
            </div>
            <Footer />
        </>
    )
}

export default TransactionTable