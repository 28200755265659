import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Heading from "../../../Common/Components/Heading";

const ReleaseNoteComp = () => {

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Release Notes</li>
                    </ol>
                </nav>

                <Heading name="Release Notes" />
                <div className="container-fluid mb-5 w-100 overflow-hidden " >
                    <iframe src="https://docs.google.com/document/d/e/2PACX-1vSGiC63IFJGFAE_HPiHdJ2j0weaCpMNK_cdssAGLJljJgALv3tpoQUPg-D7AfLZBVT_V_RMQJgsnacW/pub?embedded=true" width="105%" height="800" frameBorder="0"></iframe>
                </div>
            </div>
        </>
    );
};

export default ReleaseNoteComp;