import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { toast } from 'react-toastify';
import Heading from "../../../Common/Components/Heading";

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import axios from "axios";
import Switch from "../../../Common/Switch/Switch";
import url from "../../../Common/URL";

// import { useConfirm } from 'material-ui-confirm';
import TextField from '@mui/material/TextField';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const ReviewTimesheetComp = () => {

  // const confirm = useConfirm();
  const { id } = useParams();

  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const [summaryModal, setSummaryModal] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [summarydata, setSummarydata] = useState({
    fdate: "", ldate: "", hours: "", show: false
  });

  const [status, setStatus] = useState({
    weekdata: "", weekapproved: ""      //if any particular timesheet data is empty, then the admin cant approve or disapprove the weekly timesheet
  });

  const [week, setWeek] = useState({
    firstday: "", lastday: "", currentWeekLast: ""
  });

  const Columns = useMemo(
    () => [
      { header: 'Project', accessorKey: 'project', size: 50 },
      { header: 'Task', accessorKey: 'task', size: 50 },
      { header: 'MON', id: 'MON', accessorFn: rowData => rowData.work_day.includes("Mon") ? rowData.work_hours : "--", size: 50 },
      { header: 'TUE', id: 'TUE', accessorFn: rowData => rowData.work_day.includes("Tue") ? rowData.work_hours : "--", size: 50 },
      { header: 'WED', id: 'WED', accessorFn: rowData => rowData.work_day.includes("Wed") ? rowData.work_hours : "--", size: 50 },
      { header: 'THU', id: 'THU', accessorFn: rowData => rowData.work_day.includes("Thu") ? rowData.work_hours : "--", size: 50 },
      { header: 'FRI', id: 'FRI', accessorFn: rowData => rowData.work_day.includes("Fri") ? rowData.work_hours : "--", size: 50 },
      { header: 'SAT', id: 'SAT', accessorFn: rowData => rowData.work_day.includes("Sat") ? rowData.work_hours : "--", size: 50 },
      { header: 'SUN', id: 'SUN', accessorFn: rowData => rowData.work_day.includes("Sun") ? rowData.work_hours : "--", size: 50 },

    ],
    [],
  );

  const [data, setData] = useState([]);


  // ------------------------Fetching Batch table's data-------------------------------------
  useEffect(() => {
    loadTimesheets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTimesheets = async (fd, ld) => {
    setLoading(true);

    if (fd === undefined && ld === undefined) {
      let curr = new Date();
      let week = [];

      for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i;
        let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
        week.push(day);
      }

      // console.log(week);
      // console.log(week[0]);
      // console.log(week[6]);


      setWeek({ ...week, firstday: week[0], lastday: week[6], currentWeekLast: week[6] });

      var obj = {
        user_id: id, uname: sessionStorage.getItem("username"),
        start_date: week[0], end_date: week[6]
      };

    } else {
      setWeek({ ...week, firstday: fd, lastday: ld });
      var obj = {
        user_id: id, uname: sessionStorage.getItem("username"),
        start_date: fd, end_date: ld
      };
    }

    await axios.post(url + "timesheet", obj)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          let a = response.data.data;
          console.log(a);
          if (!a.length) {
            setStatus({ ...status, weekdata: null });
            setData([]);
          } else {

            let st = a.every(x => x.status === "true");
            setStatus({ weekapproved: st, weekdata: true });
            !a.length ? setData([]) : setData(a);
          }
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // ============================================================================

  const PreviousWeekData = () => {

    setLoading(true);

    let startDate, endDate, midCalculation;

    let ed = new Date(week.firstday);
    ed.setDate(ed.getDate() - 1);
    endDate = ed.toISOString().slice(0, 10);

    midCalculation = new Date(week.firstday);
    midCalculation.setDate(midCalculation.getDate() - 7);

    startDate = midCalculation.toISOString().slice(0, 10);

    // console.log(startDate);
    // console.log(endDate);
    // console.log(sessionStorage.getItem("id"));

    setWeek({ ...week, firstday: startDate, lastday: endDate });

    // ===================================================================

    const obj1 = {
      uname: sessionStorage.getItem("username"), user_id: id,
      start_date: startDate, end_date: endDate
    };

    axios.post(url + "timesheet", obj1)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          if (!a.length) {
            setStatus({ ...status, weekdata: null });
            setData([]);
          } else {

            let st = a.every(x => x.status === "true");
            setStatus({ weekapproved: st, weekdata: true });
            !a.length ? setData([]) : setData(a);
          }
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // ============================================================================

  const NextWeekData = () => {
    setLoading(true);

    let startDate, endDate, midCalculation;

    let sd = new Date(week.lastday);
    sd.setDate(sd.getDate() + 1);
    startDate = sd.toISOString().slice(0, 10);

    midCalculation = new Date(week.lastday);
    midCalculation.setDate(midCalculation.getDate() + 7);

    endDate = midCalculation.toISOString().slice(0, 10);

    // console.log(startDate);
    // console.log(endDate);
    // console.log(sessionStorage.getItem("id"));

    setWeek({ ...week, firstday: startDate, lastday: endDate });

    // ===================================================================

    const obj2 = {
      uname: sessionStorage.getItem("username"), user_id: id,
      start_date: startDate, end_date: endDate
    };

    axios.post(url + "timesheet", obj2)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          if (!a.length) {
            setStatus({ ...status, weekdata: null });
            setData([]);
          } else {

            let st = a.every(x => x.status === "true");
            setStatus({ weekapproved: st, weekdata: true });
            !a.length ? setData([]) : setData(a);
          }
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));

  };

  // ================================================================================

  const SearchSummary = async () => {
    setSummaryLoading(true);
    var obj = {
      user_id: id, uname: sessionStorage.getItem("username"),
      start_date: summarydata.fdate, end_date: summarydata.ldate
    };

    await axios.post(url + "timesheet", obj)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          if (!a.length) {
            setSummarydata({ ...summarydata, hours: 0, show: true });
          } else {

            var total = 0;
            a.forEach(item => {
              total += Number(item.work_hours);
            });

            setSummarydata({ ...summarydata, hours: total, show: true });
          }
          setSummaryLoading(false);
        } else {
          setData([]);
          setSummaryLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleToggle = async () => {
    try {
      setBackdrop(true);

      const toggle = status.weekapproved ? "false" : "true";

      const obj = {
        uname: sessionStorage.getItem("username"),
        user_id: id,
        status: toggle,
        sdate: week.firstday,
        edate: week.lastday,
        updated_by: sessionStorage.getItem("username"),
      };

      const formData = new FormData();
      Object.entries(obj).forEach(([key, value]) => formData.append(key, value));

      const response = await axios.post(url + "add_approve_comments", formData);

      if (response.data.success) {
        const successMessage = toggle === "true" ? "Weekly timesheet approved." : "Weekly timesheet disapproved.";
        toast.success(successMessage);
        loadTimesheets(week.firstday, week.lastday);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setBackdrop(false);
    }
  };


  return (
    <>

      {/* ############################################ Modal for Uploading  Attachments ################################################## */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={summaryModal}
        onClose={() => {
          setSummaryModal(false);
          setSummarydata({ ...summarydata, fdate: "", ldate: "", hours: 0, show: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Date Range</DialogTitle>
        <DialogContent>
          <div className="row pt-3">
            <div className="col-auto ">
              <TextField
                label="From"
                type="date"
                value={summarydata.fdate}
                className="form-control"
                onChange={e => {
                  setSummarydata(pre => {
                    return { ...pre, fdate: e.target.value };
                  });
                }
                }
                InputLabelProps={{ shrink: true, }}
              />
            </div>

            <div className=" col-auto ">
              <TextField
                label="To"
                type="date"
                value={summarydata.ldate}
                className="form-control"
                onChange={e => {
                  setSummarydata(pre => {
                    return { ...pre, ldate: e.target.value };
                  });
                }
                }
                InputLabelProps={{ shrink: true, }}
              />
            </div>
          </div>



        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={() => {
            setSummaryModal(false);
            setSummarydata({ ...summarydata, fdate: "", ldate: "", hours: 0, show: false });
          }}>Close</Button>
          <Button color="primary" disabled={(summarydata.fdate === "" || summarydata.ldate === "") ? true : false}
            onClick={SearchSummary}>{!summaryLoading ? "Calculate" : <CircularProgress size={20} />}</Button>
        </DialogActions>
        {summarydata.show &&
          <>
            <hr />
            <h6 className="ps-3">{`Total working hours on selected date range is ${summarydata.hours} hours.`}</h6>
          </>
        }

      </Dialog>

      {/* ############################################################### */}


      <Backdrop sx={{ zIndex: 3001, color: '#fff' }} open={backdrop} ><CircularProgress style={{ color: "#fff" }} /></Backdrop>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/timesheet">Timesheet</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Review Timesheet</li>
          </ol>
        </nav>

        <Heading name="Review Timesheet" />


        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 d-flex ">
              <Button variant="contained" onClick={PreviousWeekData} ><NavigateBeforeIcon /> Previous Week </Button>
              <Button variant="contained" onClick={NextWeekData} disabled={week.lastday === week.currentWeekLast ? true : false} className="ms-3 ">Next Week <NavigateNextIcon /></Button>
              <Button variant="contained" onClick={() => setSummaryModal(true)} className="ms-auto">Check Summary</Button>
            </div>
          </div>

          <div className="row ">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.id}
                renderTopToolbarCustomActions={() => (
                  <Typography variant="h6">{`[ ${week.firstday} - ${week.lastday} ]`}</Typography>
                )}

                muiTableProps={{
                  sx: {
                    tableLayout: 'fixed',
                  },
                }}
              />

            </div>
          </div>

          {!loading &&
            <div className="row mt-4">
              <div className="col-11 col-md-6 mx-auto rounded">
                <div className="d-flex justify-content-center bg-light shadow-sm align-items-center pt-2">
                  {status.weekdata !== null ?
                    <> <h6 className="me-3">{status.weekapproved === true ? "Disapprove This Timesheet" : "Approve This Timesheet"}</h6> <Switch isOn={status.weekapproved === true ? true : false} handleToggle={handleToggle} /> </>
                    :
                    <h6 className="me-3">Can't Approve or Disapprove An Empty Weekly Timesheet</h6>
                  }
                </div>
              </div>
            </div>}
        </div>
      </div>
    </>
  );
};
export default ReviewTimesheetComp

