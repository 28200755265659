import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Heading from "../../../../../Common/Components/Heading"
import { toast } from "react-toastify"
import Input from "../../../Student/Component/Input"
import axios from "axios";
import url from "../../../../../Common/URL"
import { Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux"


const TsTaskAddComp = () => {

    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [proj, setProj] = useState([]);

    const [field, setField] = useState({
        task_name: "", task_desc: "", project_id: "", project_name: ""
    })

    useEffect(() => {
        LoadProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LoadProjects = () => {
        const obj = { uname: sessionStorage.getItem("username") }
        axios.post(url + "fetch_projects", obj)
            .then((response) => {
                if (response.data.success) {
                    let a = response.data.data;
                   !a.length ? setProj([]) : setProj(a);
                } else {
                    setProj([]);
                }
            }).catch((err) => console.log(err));
    }

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);


        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            task_name: field.task_name, created_by: sessionStorage.getItem("username"), task_desc: field.task_desc,
            uname: sessionStorage.getItem("username"), project_id: field.project_id, project_name: field.project_name,
            fname: sessionStorage.getItem("userFname"), lname: sessionStorage.getItem("userLname")
        }
        axios.post(url + "add_task", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Task created successfully!")
                    navigate("/ts-task");
                } else {
                    toast.error("Task Creation Failed!")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })


    }
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item">Timesheet</li>
                        <li className="breadcrumb-item active"><Link to="/ts-task">Tasks</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add Task</li>
                    </ol>
                </nav>

                <Heading name="Add Task" />
                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            <div className="col-12 d-flex justify-content-between align-items-center ">
                                <h5>Add Task</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/ts-task")} >Back</Button>
                                    <Button variant="contained" disabled={loading} type="submit" className="ms-3">{loading ? <CircularProgress color="inherit" size={20} /> :'Save'}</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-11 col-md-6 mx-auto mx-md-0">

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Project</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="project_id"
                                            value={`${field.project_id}*${field.project_name}`}
                                            onChange={(e) => {
                                                let id, name;
                                                let data = e.target.value;
                                                id = data.split("*")[0];
                                                name = data.split("*")[1];
                                                setField({ ...field, project_id: id, project_name: name });
                                            }} className="form-select" required>
                                            <option value="">Choose</option>
                                            {proj.map((data, i) => (<option key={i} value={`${data.project_id}*${data.project_name}`}>{data.project_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <Input field="Task Name"
                                    name="task_name"
                                    type="text"
                                    placeholder="Add text" className="form-control"
                                    value={field.task_name} onChange={onChange}
                                    required={true}
                                />
                                <Input field="Task Description"
                                    name="task_desc"
                                    type="text"
                                    placeholder="Add text" className="form-control"
                                    value={field.task_desc} onChange={onChange}
                                    required={true}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default TsTaskAddComp