import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";
import { ReadonlyInput } from "../../Student/Component/Input";
import axios from "axios";
import url, { storageUrl } from "../../../../Common/URL";

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { useConfirm } from 'material-ui-confirm';
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import { ReadableDateTime } from "../../../../Common/Functions/StringDateTime";
const ViewManagerComp = () => {
    const confirm = useConfirm();

    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(false);

    // ################################################Below Data for 1st Tab ########################################################

    const [state, setState] = useState({

        uname: sessionStorage.getItem("username"), manager_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_number: "", home_number: "", email: "",
        join_date: "", leave_date: "", registration_number: "", kvk_number: "",
        contract_period: "", contract_renewal_date: "", probation_period: "",
        // ------------Textarea input fields--------------
        address_line1: "", address_line2: "",

        // ------------Checkbox input fields--------------
        active: false, photo_consent: false, disclaimer_signed: false,

        // ------------Select inputs fields--------------
        province: "", country: "", location_name: "", batch_name: [], level_name: [], user_name: "",
        picture: null
    });


    useEffect(() => {
        ManagerDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ------------------------------

    const ManagerDetail = async () => {
        // console.log("ManagerDetail");
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };
        axios.post(url + "fetch_single_manager", obj)
            .then((response) => {
                // console.log(response)
                let resData = response.data.data;

                let locNames = resData[0].location_name.replaceAll(",", ", ");
                setState(pre => ({
                    ...pre,
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city,
                    zip: resData[0].zip, mobile_number: resData[0].mobile_number, home_number: resData[0].home_number,
                    email: resData[0].email,
                    join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    manager_id: resData[0].manager_id,
                    probation_period: resData[0].probation_period, contract_renewal_date: resData[0].contract_renewal_date,
                    contract_period: resData[0].contract_period, kvk_number: resData[0].kvk_number,
                    // ------------Textarea input fields--------------
                    address_line1: resData[0].address_line1, address_line2: resData[0].address_line2,

                    // ------------Checkbox input fields--------------
                    active: resData[0].active, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,

                    // ------------Select inputs fields--------------
                    province: resData[0].province, country: resData[0].country, user_name: resData[0].user_name,
                    location_name: locNames,

                }));
                //----------------------------------------------------------------------------------------------

                // axios.post(url+"/select_teacher_params", {uname: resData[0].user_name})
                // .then(res=>{console.log(res);
                //     if(res.data.success){
                //         var batch_name_arr = JSON.parse(res.data.batch_data).map(a => a.batch_name);
                //         var location_name_arr = JSON.parse(res.data.location_data).map(a => a.location_name);
                //         var level_name_arr = JSON.parse(res.data.level_data).map(a => a.level_name);

                //         console.log(batch_name_arr);console.log(location_name_arr);console.log(level_name_arr);
                //         setState(pre=>{
                //             return{
                //                 ...pre , location_name:location_name_arr.join(",\n"),  batch_name:batch_name_arr.join(",\n"), 
                //                          level_name:level_name_arr.join(",\n"),
                //             }
                //         })

                //     }else{
                //         toast.error("An error occurred, Location , Level & Batch can't be fetched.")
                //     }
                // }).catch(res=>console.log(res))

                //----------------------------------------------------------------------------------------------
                LoadAttachments(resData[0].user_name);
                //----------------------------------------------------------------------------------------------

            })
            .catch((err) => console.log(err));
    };
    // ------------------------Deleting Manager table's data-------------------------------------

    const onDelete = () => {
        confirm({ title: "Delete Confirmation", description: 'This will delete the manager permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_manager", delObj)
                    .then((res) => {
                        // console.log(res)
                        toast.info("Manager deleted ");
                        navigate("/manager");
                    })
                    .catch((err) => console.log(err));
            });

    };


    // ######################################################### Below Data is for 2nd Tab ##################################################


    const Columns = useMemo(
        () => [
            { header: 'Category', accessorKey: 'category' },
            { header: 'Comment', accessorKey: 'comments', sorting: false },
            { header: 'Created Date', id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },
            { header: 'Document', id: "attach_url", accessorFn: rowData => <a href={`${storageUrl}${rowData.attach_url}`} rel="noopener noreferrer" target="_blank">Document</a> },

            {
                header: 'Action', id: "attach_id", size: 30, enableColumnActions: false, enableSorting: false, accessorFn: rowData => {
                    return (
                        <Tooltip title="Delete Attachment" placement="right" >
                            <IconButton onClick={() => onAttachmentDelete(rowData.attach_id)} >
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    );
                }
            }
        ],
        [],
    );

    const [open, setOpen] = useState(false);
    const [attachment, setAttachment] = useState([]);


    const [file, setFile] = useState([]);
    const [data, setData] = useState({
        category: "", comment: ""
    });

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.value;
        setData(pre => {
            return {
                ...pre, [name]: value
            };
        });
    };
    // -----------------------------Upload Attachment -----------------------------


    const UploadAttachments = async () => {
        setLoading(true);
        const formData = new FormData();

        for (let i = 0; i < file.length; i++) {
            formData.append(`file[${i}]`, file[i]);
        }

        formData.append("category", data.category);
        formData.append("comments", data.comment);
        formData.append("role", "Manager");
        formData.append("uname", state.user_name);
        formData.append("created_by", sessionStorage.getItem("username"));

        await axios.post(url + "attachements", formData)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("New Attachment Added");
                    LoadAttachments(state.user_name);
                } else {
                    toast.error("Error, Attachment not added please try again later!");
                }
            }).catch((err) => {
                // console.log(err);
                toast.error("Error, please try again later!");
            });
        setLoading(false);
        setOpen(false);
        setData({ category: "", comment: "" });
    };
    // ------------------- Load Attachments -----------

    const LoadAttachments = (user_name) => {

        const obj = { uname: user_name };
        axios.post(url + "select_attachments", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    if (a !== null) {
                        setAttachment(a);
                    } else setAttachment([]);
                } else {
                    setAttachment([]);
                }
            })
            .catch((err) => {
                // console.log(err);
                toast.error("Error occurred, Try again later.");
            });
    };


    // ------------------------Deleting Attachment data-------------------------------------

    const onAttachmentDelete = (id) => {
        confirm({ title: "Delete Confirmation", description: 'This will delete the attachment permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username")
                };
                axios.post(url + "delete_attachments", delObj)
                    .then((res) => {
                        // console.log(res);
                    })
                    .catch((err) => console.log(err));

                ManagerDetail();
                toast.info("Attachment Deleted");
            });

    };


    const sendLoginCredentials = async () => {
        setLoading(true);
        const obj = { uname: state.user_name };
        await axios.post(url + "send_login_credentials", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success && response.data.mail_sent === 1) {
                    toast.success("Login credentials sent successfully");
                } else {
                    toast.error("Error occurred, Try again later.");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error occurred, Try again later.");
            }).finally(() => {
                setLoading(false);
            });
    };



    return (
        <>
            {/* ############################################ Modal for Uploading Attachments ################################################## */}

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Add Attachment(s)</DialogTitle>
                <DialogContent>
                    <div className="mb-3 row">
                        <label className="col col-form-label">Category</label>
                        <div className="col-sm-7 col-md-8 ">
                            <select name="category" className="form-control" onChange={onChange} value={data.category}>
                                <option value="">Select Category</option>
                                <option value="Admission Form">Admission Form</option>
                                <option value="Contract">Contract</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col col-form-label">Comment</label>
                        <div className="col-sm-7 col-md-8 ">
                            <textarea name="comment" className="form-control" placeholder="Add text" onChange={onChange} value={data.comment} rows="3"></textarea>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col col-form-label">Upload Attachment(s)</label>
                        <div className="col-sm-7 col-md-8 py-3">
                            <input multiple type="file" onChange={(e) => setFile(e.target.files)} className="form-control-file" name="profile_pic" />
                        </div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button className="mt-3" size="small" color="primary" onClick={() => setOpen(false)}>Close</Button>
                    <Button className="mt-3" size="small" color="primary" onClick={() => UploadAttachments()}>{!loading ? "Save" : <CircularProgress size={20} />}</Button>
                </DialogActions>

            </Dialog>

            {/* ############################################################### */}

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*----------------------- Bread crumb ------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/manager">Manager List</Link></li>
                        <li className="breadcrumb-item active">View Manager</li>
                    </ol>
                </nav>
                <Heading name={"View Manager -" + " " + state.fname} />
                {/* ------------------------------------------------- Two Columns for Inputs --------------------------------------- */}
                <div className="container-fluid mt-3">

                    <div className="row my-4">
                        <div className="col-12 d-flex ">
                            {/* <Link to={"/tch-history-table/"+id } >View History</Link> */}
                            <Button variant="contained" color="error" onClick={() => onDelete()} >Delete</Button>
                            <Button variant="contained" onClick={sendLoginCredentials} color="secondary" className="ms-auto" disabled={loading}>{loading ? <CircularProgress color="inherit" size={20} /> : 'Send Login Credentials'}</Button>
                            <Button variant="contained" onClick={() => navigate("/edit-manager/" + id)} className="ms-3">Edit</Button>
                        </div>
                    </div>


                    <div className="row border-top border-bottom mb-4 pt-4">
                        <nav>
                            <div className="nav " id="nav-tab" role="tablist">
                                <a className="nav_tab nav-link active " id="nav-info-tab" data-bs-toggle="tab" href="#nav-info" role="tab" aria-controls="nav-info" aria-selected="true">Information</a>
                                <a className="nav_tab nav-link " id="nav-attachment-tab" data-bs-toggle="tab" href="#nav-attachment" role="tab" aria-controls="nav-attachment" aria-selected="false">Attachments</a>
                            </div>
                        </nav>
                    </div>

                    <div className="tab-content" id="nav-tabContent">

                        <div className="tab-pane fade show active" id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab">

                            <div className="row">
                                {/*-----------------------Column-1 for Inputs------------------------- */}
                                <div className="col-11 col-md-6 mx-auto">

                                    <h5 className="mb-4">Personal Information</h5>


                                    {/* <Input field="Profile Picture" type="file" className="form-control-file" name="picture" onChange={handleInputChange} value={state.picture}/> */}

                                    {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Teacher ID</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={state.manager_id} />
                                </div>
                            </div> */}

                                    <ReadonlyInput field="First Name" name="fname" value={state.fname} />

                                    <ReadonlyInput field="Middle Name" name="mname" value={state.mname} />

                                    <ReadonlyInput field="Last Name" name="lname" value={state.lname} />

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Address Line 1</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea readOnly className="form-control bg-light border-0 " name="address_line1" placeholder="--" value={state.address_line1 ?? ''}  ></textarea>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Address Line 2</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea readOnly className="form-control bg-light border-0 " name="address_line2" placeholder="--" value={state.address_line2 ?? ''} ></textarea>
                                        </div>
                                    </div>

                                    <ReadonlyInput field="City" name="city" value={state.city} />


                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="Country" className="form-control bg-light border-0 " value={state.country} />


                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="Province" className="form-control bg-light border-0 " value={state.province} />

                                    <ReadonlyInput field="Zip" name="zip" value={state.zip} />

                                    <ReadonlyInput field="Mobile Number" name="mobile_number" value={state.mobile_number} />

                                    <ReadonlyInput field="Home Phone" name="home_number" value={state.home_number} />

                                    <ReadonlyInput field="Email" type="email" placeholder="--" name="email" value={state.email} />

                                    <ReadonlyInput field="KVK Number" name="kvk_number" value={state.kvk_number} />

                                    {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Contract Document</label>
                                <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                    <a href="#" download >View Contract Document &nbsp;<i className="fa fa-download"></i></a>
                                </div>
                            </div> */}

                                </div>

                                {/*-----------------------Column-2 for Inputs------------------------- */}
                                <div className="col-11 col-md-6 mx-auto">

                                    <h5 className="mb-4">Location Information</h5>


                                    <ReadonlyInput field="Location Name" name="location_name" value={state.location_name} />

                                    {/* <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">Batch Name</label>
                                <div className="col-sm-7 col-md-8 ">
                                    <textarea readOnly className="form-control bg-light border-0 " rows="3" name="batch_name"  placeholder="--" value={state.batch_name}  ></textarea>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">Level Name</label>
                                <div className="col-sm-7 col-md-8 ">
                                    <textarea readOnly className="form-control bg-light border-0 " rows="3" name="level_name"  placeholder="--" value={state.level_name}  ></textarea>
                                </div>
                            </div> */}

                                    <h5 className="mt-5 mb-4">Account Information</h5>


                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="User" className="form-control bg-light border-0 " value={state.user_name} />


                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Active Account</label>
                                        <div className="col-sm-7 col-md-7 ps-4">
                                            <div className="form-check">
                                                <input readOnly className="form-check-input" type="checkbox" name="active" checked={(state.active === "1" || state.active === "true") ? true : false} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                                        <div className="col-sm-7 col-md-7 ps-4">
                                            <div className="form-check">
                                                <input readOnly className="form-check-input" type="checkbox" name="photo_consent" checked={(state.photo_consent === "1" || state.photo_consent === "true") ? true : false} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                                        <div className="col-sm-7 col-md-7 ps-4">
                                            <div className="form-check">
                                                <input readOnly className="form-check-input" type="checkbox" name="disclaimer_signed" checked={(state.disclaimer_signed === "1" || state.disclaimer_signed === "true") ? true : false} />
                                            </div>
                                        </div>
                                    </div>

                                    <ReadonlyInput name="join_date" field="Join Date" type="date" placeholder="--" value={state.join_date} />

                                    <ReadonlyInput name="probation_period" value={state.probation_period} field="Probation Period" type="number" placeholder="In Months" />

                                    <ReadonlyInput name="contract_renewal_date" value={state.contract_renewal_date} field="Contract Renewal Date" type="date" placeholder="--" />

                                    <ReadonlyInput name="contract_period" value={state.contract_period} field="Contract Period" type="number" placeholder="In Months" />

                                    <ReadonlyInput name="leave_date" value={state.leave_date} field="Leave Date" type="date" placeholder="--" />


                                </div>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-attachment" role="tabpanel" aria-labelledby="nav-attachment-tab">

                            <div className="row cl-12">
                                <Button variant='contained' className=" ms-auto col-auto " onClick={() => setOpen(true)}>Add Attachment <Add /></Button>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12 ">

                                    <MaterialReactTable
                                        columns={Columns}
                                        data={attachment}
                                        enableRowNumbers
                                        initialState={{ density: 'compact' }}
                                        enableDensityToggle={false}

                                        getRowId={(originalRow) => originalRow.id}

                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ViewManagerComp;


