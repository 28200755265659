import { PlayCircle } from "@mui/icons-material";
import { Button, Dialog, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../../Common/Components/Heading";
import LoadingSpinner from "../../../../../Common/Components/LoadingSpinner";
import assignmentQuestionsData, { GenerateAssignmentQuestions } from "../../../../../Common/Question Generator/AssignmentQuestionsLogic";
import { AnswerFieldStyle, calculatePercentage, countOfCorrectIncorrectAttempted, RearrangeArray, styleColor, styleFontWeight, TableHeaderStyle } from "../../../../../Common/Question Generator/QuestionGeneratorFunctions";
import url from "../../../../../Common/URL";
import AlertBlockForQuestionPapers from "../../../../../Common/Components/AlertBlockForQuestionPapers";

const AssignmentPreviewAndAttendComp = () => {
    let { level, type, rows, no_of_questions, test_type } = useParams();
    const [open, setOpen] = useState(false);
    const [videoUrl] = useState(assignmentQuestionsData[level][type].helpVideoId);

    const navigate = useNavigate();
    const loggedInRole = sessionStorage.getItem('role');

    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);


    // =========================================================================================================
    var noOfQuestions = Number(no_of_questions);

    let tableHeader = [...Array(noOfQuestions).keys()]; // to create header number for a table
    let inputFieldArray = [...Array(noOfQuestions).keys()]; // to create input fields for the table
    let actualAnswerFieldArray = [...Array(noOfQuestions).keys()]; // to create the actual answer field 

    // This is to store actual answers, which will later be used to check right and wrong answers
    const [answersArray, setAnswersArray] = useState([]);
    // This is to store input field answers, which will later be used to check right and wrong answers
    const [inputAnswers, setInputAnswers] = useState(Array(noOfQuestions).fill(''));

    // This array store question, which is to be sent to db 
    const [generatedQuestions, setGeneratedQuestions] = useState([]);

    // This data will be sent to DB 
    const [paperState, setPaperState] = useState({
        total_ab_questions: 0, total_mm_questions: 0, total_correct: 0, total_incorrect: 0, total_not_attempted: 0
    });

    useEffect(() => {
        GenerateTest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GenerateTest = () => {
        // New Logic
        const [questions, questionsCopy, answers, questionsCount] = GenerateAssignmentQuestions(Number(level), Number(type), Number(rows), Number(no_of_questions));
        setGeneratedQuestions(questionsCopy);
        setPaperState({ ...paperState, total_ab_questions: questionsCount.ab, total_mm_questions: questionsCount.mm });
        // Its an Array of Array, which holds all the questions
        let questionsArray = questions;
        let answerArray = answers;

        // splicedArray holds sets of 10 questions each
        var splicedArray = [];
        while (questionsArray.length > 0) {
            splicedArray.push(questionsArray.splice(0, 10));
        }

        for (let i = 0; i < splicedArray.length; i++) {
            questionsArray.push(RearrangeArray(splicedArray[i]));
        }

        setQuestions(questionsArray);
        setAnswersArray(answerArray);
        setLoading(false);
        toast.success('Test generated successfully');

    };

    const RegenerateTest = () => {
        setInputAnswers(Array(noOfQuestions).fill(''));
        setIsSubmitted(false);
        setGeneratedQuestions([]);
        setPaperState({ total_ab_questions: 0, total_mm_questions: 0, total_correct: 0, total_incorrect: 0, total_not_attempted: 0 });
        setQuestions([]);
        setAnswersArray([]);
        // All the above setStates are providing each state variable their initial values

        GenerateTest();
    };

    /*  The below function will return an "Array of Objects" where, 
        the object is of form
        obj = {question: [Number, Number] , answer: Number}
    */
    const GenerateQueAnsObjectArray = () => {
        let Array = [];
        for (let i = 0; i < noOfQuestions; i++) {
            let obj = {};
            obj['question'] = generatedQuestions[i];
            obj['answer'] = answersArray[i];
            obj['type'] = i < paperState.total_mm_questions ? 'mindmath' : 'abacus';
            Array.push(obj);
        }
        return Array;
    };

    // This function is used when student submits the generated Assignment/Test/Exam
    const calculateSubmitObjectValues = () => {
        let obj = {
            total_mm_questions: paperState.total_mm_questions, total_mm_attempted: 0, total_mm_correct: 0,
            total_ab_questions: paperState.total_ab_questions, total_ab_attempted: 0, total_ab_correct: 0,
        };

        /*
            Below resultArray array will have 1, 0 & null; 
            1 means student answer is correct at that index and 
            0 means student answer is wrong at that index
            null means student didn't attempted the answer
        */
        let resultArray = [];
        for (let i = 0; i < noOfQuestions; i++) {
            inputAnswers[i] === '' ? resultArray.push(null) :
                ((inputAnswers[i] === answersArray[i]) ? resultArray.push(1) : resultArray.push(0));
        }

        // Questions are stored such way that first mind-math questions are there and then of Abacus
        // We are going to split the 'resultArray' into two parts 'abSet' & 'mmSet' Accordingly
        let mmSet = resultArray.splice(0, paperState.total_mm_questions);
        let abSet = resultArray.splice(0, paperState.total_ab_questions);

        let mmData = countOfCorrectIncorrectAttempted(mmSet);
        let abData = countOfCorrectIncorrectAttempted(abSet);

        obj.total_mm_attempted = mmData.attempted;
        obj.total_mm_correct = mmData.correct;
        obj.total_ab_attempted = abData.attempted;
        obj.total_ab_correct = abData.correct;

        setPaperState({
            ...paperState,
            total_correct: mmData.correct + abData.correct,
            total_incorrect: mmData.incorrect + abData.incorrect,
            total_not_attempted: (mmData.not_attempted + abData.not_attempted),
        });


        return obj;

    };

    const onChange = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        setInputAnswers({ ...inputAnswers, [name]: value !== '' ? Number(value) : '' });  // Ternary operator prevents Number() from converting empty string into 0.
    };

    const checkAnswer = (x) => {
        // inputAnswers = Entered answers; 
        // answersArray = Actual Answers;
        return (inputAnswers[x] === answersArray[x]) ? true : false;
    };

    // This function will be called when student submits the Test/Exam/Assignment
    const Submit = () => {
        let x = calculateSubmitObjectValues();
        let total_questions = x.total_ab_questions + x.total_mm_questions;
        let total_attempted = x.total_ab_attempted + x.total_mm_attempted;
        let total_correct = x.total_ab_correct + x.total_mm_correct;

        const obj = {
            uname: sessionStorage.getItem('username'), role: loggedInRole,
            level: sessionStorage.getItem('level_name').split(' ')[1],
            test_type: test_type, test_level: level,
            test_name: assignmentQuestionsData[level][type].name,
            total_ab_questions: x.total_ab_questions,
            total_ab_attempted: x.total_ab_attempted,
            total_ab_correct: x.total_ab_correct,
            total_ab_attempted_percentage: calculatePercentage(x.total_ab_attempted, x.total_ab_questions),
            total_ab_accuracy_percentage: calculatePercentage(x.total_ab_correct, x.total_ab_attempted),

            total_mm_questions: x.total_mm_questions,
            total_mm_attempted: x.total_mm_attempted,
            total_mm_correct: x.total_mm_correct,
            total_mm_attempted_percentage: calculatePercentage(x.total_mm_attempted, x.total_mm_questions),
            total_mm_accuracy_percentage: calculatePercentage(x.total_mm_correct, x.total_mm_attempted),

            total_questions: total_questions,
            total_attempted: total_attempted,
            total_correct: total_correct,
            total_attempted_percentage: calculatePercentage(total_attempted, total_questions),
            total_accuracy_percentage: calculatePercentage(total_correct, total_attempted),
            student_answers: Object.keys(inputAnswers).map((key) => inputAnswers[key]), //converting inputAnswers obj into and Array..
            values: GenerateQueAnsObjectArray(),
        };
        console.log(obj);
        axios.post(url + "save_assignment_generator_answer", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    toast.success('Test submitted successfully');
                    setIsSubmitted(true);

                } else {
                    toast.error('Test submission failed, please try again later');
                }

            })
            .catch((err) => console.log(err));

    };


    // This function will show the Result in place of Submit button on test Submit -----
    function ShowResult() {
        return (
            <div className=" container-fluid mb-4">
                <div className="row">
                    <div className="col-md-12 card shadow  p-4 bg-light ">
                        <div >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>Results</h4>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/generator')}
                                >Back To Generator
                                </Button>
                            </div>
                            <hr />
                            <div className="mb-4">
                                <span className="badge p-2 bg-success me-2">
                                    {paperState.total_correct} Correct Answer{paperState.total_correct !== 1 ? 's' : ''}
                                </span>
                                <span className="badge p-2 bg-danger me-2">
                                    {paperState.total_incorrect} Wrong Answer{paperState.total_incorrect !== 1 ? 's' : ''}
                                </span>
                                <span className="badge p-2 bg-secondary">
                                    Not Answered: {paperState.total_not_attempted}
                                </span>
                            </div>

                            <div className="mb-3">
                                <strong>Total Questions:&nbsp;</strong>
                                {(paperState.total_ab_questions + paperState.total_mm_questions)}
                            </div>


                        </div>

                    </div >
                </div >
            </div >
        );
    }

    return (
        <>

            {/* Dialog for playing YouTube Abacus Videos */}
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ padding: 0 }}>
                    <div className="ratio ratio-4x3">
                        <iframe
                            src={`https://www.youtube.com/embed/${videoUrl}?rel=0`}
                            title="YouTube video player" frameBorder="0"
                            allowFullScreen="0"
                            allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </DialogContent>
            </Dialog>

            {/* ========================================================= */}


            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to='/generator'>Generator</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Preview and attend</li>
                    </ol>
                </nav>

                <Heading name={`${test_type} : ${assignmentQuestionsData[level][type].name} `} />
                {loading ? <LoadingSpinner /> :
                    <div className="container-fluid mb-5">
                        <div className="row my-3">

                            <AlertBlockForQuestionPapers />

                            <div className="col-12 mx-auto d-flex">
                                {/* {(loggedInRole === 'Admin' ||
                                    loggedInRole === 'Superadmin' ||
                                    loggedInRole === 'Systemadmin' ||
                                    loggedInRole === 'Teacher'
                                ) && <Button variant="contained" onClick={() => setOpenSaveDialog(true)}>{`Save ${test_type}`}</Button>
                                } */}
                                {(!isSubmitted && videoUrl) &&
                                    <Button variant="contained"
                                        onClick={() => setOpen(true)}
                                        className="me-3"
                                        startIcon={<PlayCircle />}
                                    >Help Video</Button>
                                }
                                {(loggedInRole === 'Student' && !isSubmitted) && <Button variant="contained" className="ms-auto" onClick={Submit}>Submit</Button>}
                                {(isSubmitted || loggedInRole !== 'Student') && <Button variant="contained" className="me-auto" onClick={() => RegenerateTest()}>Generate New Assignment</Button>}
                                {(isSubmitted || loggedInRole !== 'Student') && <Button variant="contained" className="ms-3" onClick={() => navigate('/generator')}>Back To Generator</Button>}

                            </div>
                        </div>


                        <div className="row my-5">
                            <div className="col-12 mx-auto ">
                                {questions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {tableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>;
                                                })}

                                                {loggedInRole === 'Student' && <TableRow>
                                                    {inputFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={x} className="text-center">
                                                            <input autoComplete="off" type="number"
                                                                style={{
                                                                    width: "3em", textAlign: "center",
                                                                    border: isSubmitted ? (checkAnswer(x) ? '2px solid green' : '2px solid red') : '2px solid grey'
                                                                }} name={x} onChange={onChange} value={inputAnswers[x]} />
                                                        </TableCell>
                                                    ))}
                                                </TableRow>}
                                                {(loggedInRole !== 'Student' || isSubmitted) && <TableRow>
                                                    {actualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {answersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>;
                                })}
                            </div>
                        </div>
                        {/* after clicking  submit button below  Result will be shown on the screen  */}
                        {isSubmitted && (<ShowResult />)}
                    </div>
                }
            </div>
        </>
    );
};
export default AssignmentPreviewAndAttendComp;