import AssignmentPreviewComp from "../../Tabs/Setup/Test/Component/AssignmentPreviewComp";
import ExamPreviewComp from "../../Tabs/Setup/Test/Component/ExamPreviewComp";
import ModernTestExamPreviewComp from "../../Tabs/Setup/Test/Component/ModernTestExamPreviewComp";
import TestPreviewComp from "../../Tabs/Setup/Test/Component/TestPreviewComp";

const RenderTestExamAssignmentPreviewComponent = ({ data, showStudentAnswers = false, setOpenDrawer }) => {
    if (data.generator_test === 1) {
        return <ModernTestExamPreviewComp rowData={data} showStudentAnswers={showStudentAnswers} setOpenDrawer={setOpenDrawer} />;
    } else if (data.test_type === "Exam") {
        return <ExamPreviewComp rowData={data} showStudentAnswers={showStudentAnswers} setOpenDrawer={setOpenDrawer} />;
    } else if (data.test_type === "Assignment") {
        return <AssignmentPreviewComp rowData={data} showStudentAnswers={showStudentAnswers} setOpenDrawer={setOpenDrawer} />;
    } else {
        return <TestPreviewComp rowData={data} showStudentAnswers={showStudentAnswers} setOpenDrawer={setOpenDrawer} />;
    }
};

export default RenderTestExamAssignmentPreviewComponent;