import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../../Common/Components/Heading";
import LoadingSpinner from "../../../../../Common/Components/LoadingSpinner";
import { AnswerFieldStyle, RearrangeArray, styleColor, styleFontWeight, TableHeaderStyle } from "../../../../../Common/Question Generator/QuestionGeneratorFunctions";
import testQuestionsData, { GenerateTestQuestions } from "../../../../../Common/Question Generator/TestQuestionsLogic";
import url from "../../../../../Common/URL";
import Input from "../../../../Setup/Student/Component/Input";
import examQuestionsData, { GenerateExamQuestions } from "../../../../../Common/Question Generator/ExamQuestionsLogic";
import AlertBlockForQuestionPapers from "../../../../../Common/Components/AlertBlockForQuestionPapers";

const GeneratedTestExamPreviewComp = () => {
    let { level, test_type } = useParams();

    const navigate = useNavigate();
    const loggedInRole = sessionStorage.getItem('role');

    const [loading, setLoading] = useState(true);
    const [saveTestLoading, setSaveTestLoading] = useState(false);

    // Choosing whether to select 'testQuestionsData' or 'examQuestionsData'
    var questionsData = (test_type === 'Test' || test_type === 'Practice Test') ? testQuestionsData : examQuestionsData;

    // Choosing whether to select 'GenerateTestQuestions' or 'GenerateExamQuestions'
    var generateQuestions = (test_type === 'Test' || test_type === 'Practice Test') ? GenerateTestQuestions : GenerateExamQuestions;

    // console.log(questionsData);
    // =========================================================================================================
    var totalMmQuestion = questionsData[level].mm.totalQuestions;
    var totalAbQuestion = questionsData[level].ab.totalQuestions;
    var totalQuestions = totalMmQuestion + totalAbQuestion;

    var mmTableHeader = [...Array(totalMmQuestion).keys()]; // to create header number for a Mind-Math table
    var abTableHeader = [...Array(totalAbQuestion).keys()]; // to create header number for a Abacus table

    var mmActualAnswerFieldArray = [...Array(totalMmQuestion).keys()]; // to create the actual answer field for a Mind-Math table
    var abActualAnswerFieldArray = [...Array(totalAbQuestion).keys()]; // to create the actual answer field for a Abacus table

    // This will store generated questions separately for MM & AB
    const [mmQuestions, setMmQuestions] = useState([]);
    const [abQuestions, setAbQuestions] = useState([]);

    // This is to store actual answers
    const [mmAnswersArray, setMmAnswersArray] = useState([]);
    const [abAnswersArray, setAbAnswersArray] = useState([]);

    // This array store question, which is to be sent to db 
    const [generatedQuestions, setGeneratedQuestions] = useState([]);

    // This array store answers, which is to be sent to db 
    const [generatedAnswers, setGeneratedAnswers] = useState([]);


    // This data will be sent to DB 
    const [paperState, setPaperState] = useState({
        total_ab_questions: 0, total_mm_questions: 0
    });


    useEffect(() => {
        GenerateTest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GenerateTest = () => {
        // New Logic
        const [questions, questionsCopy, answers, questionsCount] = generateQuestions(level);
        setGeneratedQuestions(questionsCopy);
        setGeneratedAnswers(answers);

        setPaperState({ ...paperState, total_ab_questions: questionsCount.ab, total_mm_questions: questionsCount.mm });

        //-------- Getting Mind-Math Questions --------
        let mmQuestionsArray = questions.slice(0, questionsCount.mm);
        let mmAnswerArray = answers.slice(0, questionsCount.mm);

        var splicedArray = [];
        while (mmQuestionsArray.length > 0) {
            splicedArray.push(mmQuestionsArray.splice(0, 10));
        }

        for (let i = 0; i < splicedArray.length; i++) {
            mmQuestionsArray.push(RearrangeArray(splicedArray[i]));
        }

        setMmQuestions(mmQuestionsArray);
        setMmAnswersArray(mmAnswerArray);


        //-------- Getting Abacus Questions --------
        let abQuestionsArray = questions.slice(-questionsCount.ab); // -ve sign means we are taking 'N elements' from end of array
        let abAnswerArray = answers.slice(-questionsCount.ab); // -ve sign means we are taking 'N elements' from end of array

        var splicedArray = [];
        while (abQuestionsArray.length > 0) {
            splicedArray.push(abQuestionsArray.splice(0, 10));
        }

        for (let i = 0; i < splicedArray.length; i++) {
            abQuestionsArray.push(RearrangeArray(splicedArray[i]));
        }

        setAbQuestions(abQuestionsArray);
        setAbAnswersArray(abAnswerArray);

        setLoading(false);
        toast.success('Test generated successfully');
    };


    const RegenerateTest = () => {
        setGeneratedQuestions([]);
        setPaperState({ total_ab_questions: 0, total_mm_questions: 0 });

        setAbQuestions([]);
        setMmQuestions([]);

        setAbAnswersArray([]);
        setMmAnswersArray([]);
        // All the above setStates are providing each state variable their initial values

        GenerateTest();
    };

    // ============================== Save Test By Admin/Superadmin/Systemadmin/Teacher ================================
    // Below state is for Role = "Admin/SuperAdmin/Systemadmin/Teacher", which is used for Saving the generated Test/Exam/Assignment
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [saveFormState, setSaveFormState] = useState({
        test_name: '', description: ''
    });


    /*  The below function will return an "Array of Objects" where, 
        the object is of form
        obj = {question: [Number, Number] , answer: Number}
    */
    const GenerateQueAnsObjectArray = () => {
        let Array = [];
        for (let i = 0; i < totalQuestions; i++) {
            let obj = {};
            obj['question'] = generatedQuestions[i];
            obj['answer'] = generatedAnswers[i];
            obj['type'] = i < paperState.total_mm_questions ? 'mindmath' : 'abacus';
            Array.push(obj);
        }
        return Array;
    };

    // This function will be called when Admin/Superadmin/Systemadmin/Teacher Saves the Test/Exam/Assignment
    const SaveTest = (e) => {
        e.preventDefault();
        setSaveTestLoading(true);
        let obj = {
            test_name: saveFormState.test_name, test_desc: saveFormState.description,
            total_mm_questions: totalMmQuestion, total_ab_questions: totalAbQuestion,
            uname: sessionStorage.getItem('username'),
            test_type: test_type,
            level_name: questionsData[level].name,
            values: GenerateQueAnsObjectArray(),
            created_by: sessionStorage.getItem('username')
        };
        console.log(obj);
        axios.post(url + "add_generator_generated_test", obj)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    toast.success('Test saved successfully');
                    setOpenSaveDialog(false);
                    navigate('/generator');
                } else {
                    toast.error('An error occurred, please try later');
                    setOpenSaveDialog(false);
                }
                setSaveTestLoading(false);
            })
            .catch((err) => console.log(err));
    };


    return (
        <>

            {/* ========================================================= */}

            <Dialog fullWidth={true} open={openSaveDialog}>
                <DialogTitle id="max-width-dialog-title">
                    {`Save ${test_type}`} <hr className="p-0 mt-2 mb-0" />{" "}
                </DialogTitle>
                <DialogContent>
                    <form id='saveForm' onSubmit={SaveTest}>
                        <div className="row mt-3">
                            <div className="col-12 ">
                                <Input field={`${test_type} Name`} type="text" placeholder="Enter Name" autoComplete="off"
                                    className="form-control" onChange={(e) => setSaveFormState({ ...saveFormState, test_name: e.target.value })} name="test_name"
                                    value={saveFormState.test_name} required
                                />
                                <Input field={`${test_type} Description`} type="text" placeholder="Enter Name" autoComplete="off"
                                    className="form-control" onChange={(e) => setSaveFormState({ ...saveFormState, description: e.target.value })} name="description"
                                    value={saveFormState.description}
                                />
                            </div>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={() => {
                        setOpenSaveDialog(false);
                        setSaveFormState({ test_name: "", description: "" });
                    }}>Close</Button>
                    <Button color='primary' disabled={saveTestLoading} form='saveForm' type='submit'>{saveTestLoading ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                </DialogActions>
            </Dialog>

            {/* ========================================================= */}

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to='/generator'>Generator</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Test-Exam Preview</li>
                    </ol>
                </nav>

                <Heading name={`${test_type} : ${questionsData[level].name}`} />
                {loading ? <LoadingSpinner /> :
                    <div className="container-fluid mb-5">
                        <div className="row my-3">

                            <div className="col-12 mx-auto d-flex mb-3">
                                <Button variant="contained" onClick={RegenerateTest} >{`Regenerate ${test_type}`}</Button>
                                <Button variant="contained" className="ms-3" onClick={() => navigate('/generator')}>Back To Generator</Button>
                                <Button variant="contained" color="success" className="ms-auto" onClick={() => setOpenSaveDialog(true)} >{`Create ${test_type}`}</Button>
                            </div>
                            <AlertBlockForQuestionPapers />
                        </div>


                        {/* ================= Mind-Math Table ================= */}
                        <div className="row">

                            <div className="col-12 mx-auto ">
                                <h5><LabelImportantIcon /> Mind-Math</h5>
                                <hr />
                            </div>

                            <div className="col-12 mx-auto ">
                                {mmQuestions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {mmTableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>;
                                                })}

                                                <TableRow>
                                                    {mmActualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {mmAnswersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>;
                                })}

                            </div>
                        </div>

                        {/* ================= Abacus Table ================= */}
                        <div className="row ">
                            <div className="col-12 mx-auto ">
                                <h5><LabelImportantIcon /> Abacus</h5>
                                <hr />
                            </div>
                            <div className="col-12 mx-auto ">
                                {abQuestions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {abTableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>;
                                                })}

                                                <TableRow>
                                                    {abActualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {abAnswersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>;
                                })}

                            </div>
                        </div>

                        {/* ================================== */}

                    </div>
                }
            </div>
        </>
    );
};
export default GeneratedTestExamPreviewComp;

