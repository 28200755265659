import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../../../../Common/Components/Heading";

import { toast } from "react-toastify";


import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import VisibilityIcon from '@mui/icons-material/Visibility';

import axios from "axios";
import MaterialReactTable from 'material-react-table';
import url from "../../../../Common/URL";

// ------------------Imports for the Assign test Popup----------------------

import { Event, LocationOn } from "@mui/icons-material";
import { Box, Button, Chip, Drawer, IconButton, Tooltip } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import RenderTestExamAssignmentPreviewComponent from "../../../../Common/Components/RenderTestExamAssignmentPreviewComponent";

const AssignTestComp = () => {

  const [loading, setLoading] = useState(true);
  const [popupLoad, setPopupLoad] = useState(true); // This loading is for the popup student table

  const [state] = useState({
    uname: sessionStorage.getItem("username"),
  });

  const [openTestExamAssignmentPreviewDrawer, setOpenTestExamAssignmentPreviewDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showStudentAnswers, setShowStudentAnswers] = useState(false);


  const [open, setOpen] = useState(false);   //for the Assign test Popup
  const [axios_data, setAxios_data] = useState({
    test_type: "", test_name: "", level_name: "", assignment_type: "", page: "", test_id: ""
  });   // Variables created to store received api data  and send the data to axios

  const [stuData, setStuData] = useState([]);     //Data of assign test popup

  const [testStartDate, setTestStartDate] = useState("");

  const Student_Column = [
    { header: 'First Name', size: 40, accessorKey: 'fname' },
    { header: 'Last Name', size: 40, accessorKey: 'lname' },
    { header: "Batch", size: 40, accessorKey: "batch_name" },
    { header: 'Location', size: 40, accessorKey: 'location_name' },
    { header: 'Level', size: 40, accessorKey: 'level_name' },
  ];

  const teacherBatchesRef = useRef([]);
  const teacherLocationsRef = useRef([]);

  const Columns = [
    { header: 'Test Name', size: 40, accessorKey: 'test_name' },
    {
      header: 'Test Type', size: 40, id: 'test_type', accessorFn: rowData => rowData.generator_test === 1 ?
        <div className="hstack gap-2">
          <span>{rowData.test_type}</span>
          <Tooltip title="Generated from generator" placement="right" >
            <SettingsSuggestIcon className="ms-2" style={{ cursor: "pointer", fontSize: '20px' }} color="primary" fontSize="50" />
          </Tooltip>
        </div> : rowData.test_type
    },
    { header: 'Level', size: 40, accessorKey: 'level_name' },
    { header: 'Description', size: 40, accessorKey: 'test_desc' },
  ];

  const [test, setTest] = useState([]);

  useEffect(() => {
    loadTests();
    if (sessionStorage.getItem("role") === "Teacher") {
      loadTeacherParameters();
    }
  }, []);

  // ------------------------Fetching Test  -------------------------------------
  const loadTests = () => {
    axios.post(url + "fetch_tests", { uname: state.uname })
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          setTest(a);
          setLoading(false);
        } else {
          setTest([]);
          setLoading(false);
        }

      })
      .catch((err) => console.log(err));
  };

  const loadTeacherParameters = () => {

    axios.post(url + "select_teacher_params", { uname: state.uname })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          const batch_name_arr = res.data.batch_data.map(a => a.batch_name.toLowerCase());
          const location_name_arr = res.data.location_data.map(a => a.location_name.toLowerCase());
          console.log(batch_name_arr);
          console.log(location_name_arr);
          teacherBatchesRef.current = batch_name_arr;
          teacherLocationsRef.current = location_name_arr;
        } else {
          toast.error("Error while fetching teacher's parameters");
        }
      }).catch(res => {
        toast.error("Error while fetching teacher's parameters");
        console.log(res);
      });
  };

  // fetching students according to level of test selected ============
  const loadStudents = (levelName) => {
    setOpen(true);
    setPopupLoad(true);
    console.log(levelName);
    setStuData([]);

    const requestData = { uname: state.uname, level_name: levelName };

    axios.post(url + "stud_level", requestData)
      .then((response) => {
        if (response.data.success) {
          const students = response.data.data;

          // Filtering Students based on user role
          if (sessionStorage.getItem("role") === "Teacher") {
            // If user is a teacher, filter by teacher's locations and batches

            console.log("Teacher's Locations:", teacherLocationsRef.current);
            console.log("Teacher's Batches:", teacherBatchesRef.current);

            var filterData = students.filter(stu => {
              return teacherLocationsRef.current.indexOf(stu.location_name.toLowerCase()) >= 0 &&
                teacherBatchesRef.current.indexOf(stu.batch_name.toLowerCase()) >= 0;
            });
            filterData !== null ? setStuData(filterData) : setStuData([]);
            console.log("Teacher's Students:", filterData);

            // If user is a location manager, filter by manager's locations
          } else if (sessionStorage.getItem("role") === "Location Manager") {
            let managerLocations = sessionStorage.getItem("location").toLowerCase().split(',');
            let filter = students.filter(stu => managerLocations.indexOf(stu.location_name.toLowerCase()) >= 0);
            filter !== null ? setStuData(filter) : setStuData([]);
            console.log("Location Manager's Locations:", managerLocations);
            console.log("Location Manager's Students:", filterData);
          } else {
            // If user role is not specified, show all students
            setStuData(students);
          }
        } else {
          // If response indicates failure, clear student data
          setStuData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPopupLoad(false);
      });
  };

  // ------------------------Assigning Test to STudents-------------------------------------

  const OnAssignment = (rowData) => {

    let userArray = [];
    //Here id is coming as user_name, this is done with the help of MaterialReactTable 'getRowId' prop
    rowData.map(x => userArray.push(x.id));

    const obj = {
      stud_uname: userArray,
      assignment_name: axios_data.test_name, test_type: axios_data.test_type, assignment_type: axios_data.assignment_type,
      uname: state.uname, level_name: axios_data.level_name, page: axios_data.page,
      test_date: axios_data.test_type === "Assignment" ? "--" : testStartDate, test_id: axios_data.test_id
    };
    console.log(obj);

    axios.post(url + "assign_multi_stud", obj)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          toast.success(axios_data.test_name + " Assigned to " + rowData.length + " Student(s)");
          setOpen(false);
          setTestStartDate("");
        }
        else {
          toast.error("An error occurred, please try again later.");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occurred, please try again later.");
      });
  };

  const handleChangeTime = (e) => {
    setTestStartDate(e.target.value);
  };

  const TeacherBatchList = () => {
    return (
      <div>
        <span className="small fw-semibold">My Batches:</span>
        {teacherBatchesRef.current.map((batch, index) => (
          <Chip size="small" key={index} label={batch} className="mx-1 p-2" />
        ))}
      </div>
    );
  };

  const TeacherLocationList = () => {
    return (
      <div>
        <span className="small fw-semibold">My Locations:</span>
        {teacherLocationsRef.current.map((location, index) => (
          <Chip size="small" key={index} label={location} className="mx-1 p-2" />
        ))}
      </div>
    );
  };

  const ManagerLocationList = () => {
    let managerLocations = sessionStorage.getItem("location").toLowerCase().split(',');
    return (
      <div>
        <span className="small fw-semibold">My Locations:</span>
        {managerLocations.map((location, index) => (
          <Chip size="small" key={index} label={location} className="mx-1 p-2" />
        ))}
      </div>
    );
  };

  return (
    <>

      {/* Side Drawer for Previewing TEST/EXAM/ASSIGNMENT Answers given by the student */}
      <Drawer
        PaperProps={{ style: { width: '95%' } }}
        anchor='right'
        open={openTestExamAssignmentPreviewDrawer}
        onClose={() => setOpenTestExamAssignmentPreviewDrawer(false)}
      >
        <RenderTestExamAssignmentPreviewComponent data={selectedRowData} showStudentAnswers={showStudentAnswers} setOpenDrawer={setOpenTestExamAssignmentPreviewDrawer} />
      </Drawer >


      {/* Select Students To Assign Test Dialog */}
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}>
        <DialogTitle id="max-width-dialog-title">
          {`Assign '${axios_data.test_name}' To '${axios_data.level_name}' Students`}
        </DialogTitle>
        <DialogContent>

          <div className="row">
            {sessionStorage.getItem('role') === 'Teacher' && (
              <div className="vstack gap-2">
                <TeacherLocationList />
                <TeacherBatchList />

              </div>
            )}

            {sessionStorage.getItem('role') === 'Location Manager' && (
              <div className="vstack gap-2">
                <ManagerLocationList />
              </div>
            )}

            <div className="col-12">
              <hr />
              {(axios_data.test_type !== "Assignment") &&

                <div className="mb-3 col-md-3">
                  <label htmlFor="dateTime" className="form-label" style={{ fontWeight: "500" }}>Schedule Test </label>
                  <input type="datetime-local"
                    className="form-control " id="dateTime"
                    value={testStartDate}
                    onChange={handleChangeTime} />
                </div>
              }

              <MaterialReactTable
                state={{ isLoading: popupLoad }}
                columns={Student_Column}
                data={stuData}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                getRowId={(originalRow) => originalRow.user_name}
                renderTopToolbarCustomActions={({ table }) => (
                  <Button
                    variant="contained"
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      // let selectedRows = table.getSelectedRowModel().rows;
                      // console.log(selectedRows);
                      OnAssignment(table.getSelectedRowModel().rows);
                    }}
                  >Assign Test
                  </Button>
                )}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions> <Button color="primary" onClick={() => setOpen(false)}>Close</Button> </DialogActions>
      </Dialog>


      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item"><strong>Students</strong></li>
            <li className="breadcrumb-item active" aria-current="page">Assign Test</li>
          </ol>
        </nav>


        <Heading name="Assign Test" />

        <div className="container-fluid">
          <div className="row my-5">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={test}
                enableRowNumbers
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                positionActionsColumn="first"
                enableRowActions
                renderRowActions={(row, index) => (
                  <Box>
                    <Tooltip placement="right" title="View">
                      <IconButton onClick={() => {
                        let rowData = row.row.original;
                        setSelectedRowData(rowData);
                        setShowStudentAnswers(false);
                        setOpenTestExamAssignmentPreviewDrawer(true);
                      }}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip placement="right" title="Assign">
                      <IconButton onClick={() => {
                        let rowData = row.row.original;
                        setAxios_data({
                          test_name: rowData.test_name, test_type: rowData.test_type,
                          level_name: rowData.level_name,
                          assignment_type: rowData.assignment_type, page: rowData.page, test_id: rowData.test_id
                        });
                        loadStudents(rowData.level_name);
                      }}>
                        <AssignmentIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignTestComp;