import Axios from "axios";
import url from "../URL";
import { toast } from 'react-toastify';

// -----------This function will Fetch the User Id of logged user  --------------------
const SetUserId = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    Axios
        .post(url + "select_user_id", obj)
        .then((response) => {
            let resData = response.data.data;
            // console.log(resData);
            sessionStorage.setItem('userId', resData[0].id);
        }).catch((err) => {
            console.log(err);
            toast.error('Unable to fetch user-id!');
        });
};

export default SetUserId;