import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PaymentGateway from "./PaymentGateway";
import url from "../../Common/URL";
import axios from "axios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button } from "@mui/material";

import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

const CheckoutTable = () => {
  const [fee, setFee] = useState([]);

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    LoadFees();
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      toast.success("Payment Success");
    }

    if (query.get("canceled")) {
      toast.error("Payment Failed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const LoadFees = (res) => {
    const obj = {
      uname: sessionStorage.getItem("username"),
      id: sessionStorage.getItem("id"),
    };

    axios
      .post(url + "stud_fee", obj)
      .then((response) => {
        let resData = response.data.data;
        // console.log(resData);
        setFee(resData);
      })
      .catch((err) => console.log(err));
    res !== undefined && res();
  };

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleClick = async (fee, duration) => {
    const stripe = await stripePromise;

    const response = await fetch(url + "create-checkout-session", {
      method: "POST",
      body: JSON.stringify({
        amount: fee,
        product_name: `Duration ${duration}`,
        quantity: 1,
      }),
    });

    const session = await response.json();
    // console.log(session);
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    // console.log(result);

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      toast.error("An error occurred, please try again later.");
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr No.</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Duration</TableCell>
              <TableCell align="center">Valid From</TableCell>
              <TableCell align="center">Valid To</TableCell>
              <TableCell align="center">Checkout</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fee !== null &&
              fee.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell align="center">{row.fee}</TableCell>
                  <TableCell align="center">{row.duration}</TableCell>
                  <TableCell align="center">{row.valid_from === "0000-00-00" ? "--" : row.valid_from}</TableCell>
                  <TableCell align="center">{row.valid_to === "0000-00-00" ? "--" : row.valid_to}</TableCell>
                  <TableCell align="center">
                    {row.paid === "1" ? (
                      <h6
                        className="fw-bold d-flex align-items-center justify-content-center"
                        style={{ color: "#1e6f5c" }}>
                        <CheckCircleOutlineIcon />
                        &nbsp;Paid
                      </h6>
                    ) : (
                      <div className="mx-auto">
                        <PaymentGateway func={LoadFees} fee={row.fee} id={row.stud_fees_id} />
                        <hr />
                        <Button variant="contained"
                          target="_blank_"
                          type="button"
                          id="checkout-button"
                          role="link"
                          onClick={() => {
                            handleClick(row.fee, row.duration);
                          }}>
                          iDEAL Checkout
                        </Button>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CheckoutTable;
