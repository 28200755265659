import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Heading from "../../../../Common/Components/Heading";

import Add from '@mui/icons-material/Add';
import { Button } from "@mui/material";

import axios from "axios";
import url from "../../../../Common/URL";

import { Delete } from "@mui/icons-material";
import MaterialReactTable from 'material-react-table';
import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";
import StringToTime from "../../../../Common/Functions/StringDateTime";


const SlotsTableComp = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  });



  const Columns = useMemo(
    () => [
      { header: 'Location', accessorKey: 'location_string' },
      { header: 'Slot Date', accessorKey: 'slot_date' },
      { header: 'Slot Timing', accessorKey: 'slot_time', accessorFn: rowData => StringToTime(rowData.slot_time) },
      { header: 'Slot Type', accessorKey: 'slot_type', accessorFn: rowData => rowData.slot_type === 0 ? 'Trial Class Slot' : 'Parent Orientation Slot' },
      { header: 'Slot Status', accessorKey: 'slot_status', accessorFn: rowData => rowData.slot_status === 1 ? 'Full' : 'Available' },
      { header: 'Assigned To', accessorKey: 'teacher_string' },
    ],
    [],
  );
  const [data, setData] = useState([]);


  // ------------------------Fetching Batch table's data-------------------------------------
  useEffect(() => {
    loadSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSlots = async () => {
    await axios.get(url + "slots")
      .then((response) => {
        let a = response.data;
        console.log(a);
        !a.length ? setData([]) : setData(a);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occurred while fetching slots.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // ------------------------Deleting Batch table's data-------------------------------------

  const onDelete = (rows, table) => {

    const delObj = {
      slot_id: rows.map(x => x.id),
      uname: state.uname,
    };
    // console.log(delObj);

    axios.post(url + "delete_slot", delObj)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          loadSlots();
          table.resetRowSelection();
          toast.info(rows.length + " Row(s) Deleted!");
        } else {
          toast.error("An error occurred, please try again later");
        }
      })
      .catch((err) => console.log(err));
  };


  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">Slots</li>
          </ol>
        </nav>

        <Heading name="Slots" />

        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end">
              <Button variant='contained' onClick={() => navigate("/add-slot")} >Add Slot <Add /></Button>
            </div>
          </div>
          <div className="row ">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                // enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.slot_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/edit-slot/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <Button variant="contained" color="error" startIcon={<Delete />}
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          onDelete(selectedRows, table);
                        });
                    }}
                  >Delete
                  </Button>
                )}
              />

            </div>
          </div>

        </div>
      </div>

    </>
  );

};
export default SlotsTableComp





