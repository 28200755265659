import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Heading from "../../../../../Common/Components/Heading";
import { ReadonlyInput } from "../../../Student/Component/Input";
import axios from "axios";
import url from "../../../../../Common/URL";
import { Button } from "@mui/material";

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";


const TsTaskViewComp = () => {
    const confirm = useConfirm();
    const navigate = useNavigate();
    const { id } = useParams();


    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),
        task_name: "",
        task_desc: "", project_name: ""
    });

    // ======================================================================

    useEffect(() => {
        loadTask();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ======================================================================     

    const loadTask = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };
        axios.post(url + "fetch_single_task", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setField({
                    task_name: resData[0].task_name,
                    task_desc: resData[0].task_desc,
                    project_name: resData[0].project_name,
                });
            })
            .catch((err) => console.log(err));
    };

    // ------------------------Deleting Task table's data-------------------------------------

    function onDelete() {
        confirm({ title: "Delete Confirmation", description: 'This Task will be deleted permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_task", delObj)
                    .then((res) => {
                        // console.log(res)
                        toast.info("Task deleted");
                        navigate("/ts-task");
                    })
                    .catch((err) => console.log(err));
            });
    }

    // ======================================================================

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item">Timesheet</li>
                        <li className="breadcrumb-item active"><Link to="/ts-task">Tasks</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">View Task</li>
                    </ol>
                </nav>

                <Heading name={`View Task - ${field.task_name}`} />

                <div className="container-fluid">

                    <div className="row mb-4">
                        <div className="col-12 d-flex justify-content-between align-items-end ">
                            <h5>Task Information</h5>
                            <div>
                                <Button variant="contained" color="error" onClick={onDelete} >Delete</Button>
                                <Button variant="contained" onClick={() => navigate("/edit-ts-task/" + id)} className="ms-3">Edit</Button>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-11 col-md-6 mx-auto mx-md-0">

                            <ReadonlyInput field="Project Name"
                                readOnly
                                type="text" placeholder="Add text"
                                className="form-control border-0 bg-light"
                                value={field.project_name} />


                            <ReadonlyInput field="Task Name"
                                readOnly
                                type="text" placeholder="Add text"
                                className="form-control border-0 bg-light"
                                value={field.task_name} />

                            <ReadonlyInput field="Task Description"
                                readOnly
                                type="text" placeholder="Add text"
                                className="form-control border-0 bg-light"
                                value={field.task_desc} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default TsTaskViewComp;