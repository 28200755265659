import React from "react";
// import PaypalExpressBtn from "react-paypal-express-checkout";
import url from "../../Common/URL";
import axios from "axios";
import { toast } from "react-toastify";

const PaymentGateway = (props) => {
  const onSuccess = (payment) => {
    // Congratulation, it came here means everything's fine!
    // console.log("The payment was succeeded!", payment);
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data

    const obj = {
      id: props.id,
      paypal_email: payment.email,
      paid: payment.paid,
      payerID: payment.payerID,
      paymentID: payment.paymentID,
      paymentToken: payment.paymentToken,
      updated_by: sessionStorage.getItem("username"),
    };
    axios
      .post(url + "stud_fee_paid", obj)
      .then((response) => {
        // console.log(response);
        if (response.data.success) {
          const promise = new Promise((res, rej) => {
            props.func(res);
          });
          promise.then(() => toast.success("Payment done successfully."));
        }
      })
      .catch((err) => console.log(err));
  };

  const onCancel = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    // console.log('The payment was cancelled!', data);
    toast.error("Payment failed, please try again later.");
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onError = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    // console.log("Error!", err);
    toast.error("Payment failed, please try again later.");
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  let env = "sandbox"; // you can set here to 'production' for production
  let currency = "EUR"; // or you can set this value from your props or state
  let total = Number(props.fee); // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
    sandbox: "AXGwFJHsXGOnaA8fuY2aewhCd7a9L26mHTqP-kHThw43V5C9iRIhXE67bqaXDMOSMrPjTa3PIMzmfKlJ",
    production: "YOUR-PRODUCTION-APP-ID",
  };
  // In order to get production's app-ID, you will have to send your app to Paypal for approval first
  // For sandbox app-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App"):
  //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
  // For production app-ID:
  //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

  // NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!
  return (
    // <PaypalExpressBtn
    //   env={env}
    //   client={client}
    //   currency={currency}
    //   total={total}
    //   onError={onError}
    //   onSuccess={onSuccess}
    //   onCancel={onCancel}
    // />
    <></>
  );
};
export default PaymentGateway;
