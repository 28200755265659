import React from "react"
import Navbar from "../../Common/Components/Navbar"
import Footer from "../../Common/Components/Footer"
import Sidebar from "../../Sidebar/Sidebar"
import BulletinComp from "./Component/BulletinComp"



const Bulletin = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                <Sidebar />
                <BulletinComp />
            </div>
            <Footer />
        </>
    )
}

export default Bulletin;