import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";

import Add from "@mui/icons-material/Add";


import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import url, {devUrl} from "../../../../Common/URL";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import xlsxParser from 'xlsx-parse-json';
import { StudentTableHeaders, StudentTableRow } from "../../../../Common/Functions/BulkUploadHelper";
import StudentUploadValidator from "../../../../Common/UploadValidators/StudentUploadValidator";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";


const StudentTable = () => {
  const confirm = useConfirm();
  const [loc, setLoc] = useState([]);
  const [batch, setBatch] = useState([]);
  const [fileArrayObj, setFileArrayObj] = useState([]);

  const [validated, setValidated] = useState(false);
  const [validatorDialog, setValidatorDialog] = useState(false);
  const [validatorData, setValidatorData] = useState({
    data: [], error_message: []
  });

  // ============= For Bulk Upload of Studnets in DB=======================

  const [load, setLoad] = useState(false);
  const [file_import, setFile_import] = useState(null);
  const [open, setOpen] = useState(false);
  // ============= For Bulk Upload of Studnets in DB=======================

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [muiAlert, setMuiAlert] = useState({
    show: false, length: null, duplicate_data: [], invalid_data: [], data_inserted: null
  });

  const Columns = useMemo(
    () => [
      { header: "First Name", accessorKey: "fname" },
      { header: "Last Name", accessorKey: "lname" },
      { header: "Location", accessorKey: "location_name" },
      { header: "Batch", accessorKey: "batch_name" },
      { header: "Level", accessorKey: "level_name" },
      { header: "Join Date", accessorKey: "join_date" },
    ],
    [],
  );

  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(() => {
    loadStudents();
    LoadLocations();
    LoadBatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStudents = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios
      .post(url + "fetch_students", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);

          if (sessionStorage.getItem("role") === "Location Manager") {
            let loc = sessionStorage.getItem("location").split(",");

            let filter = a.filter(stu => loc.indexOf(stu.location_name) >= 0);

            filter === null ? setData([]) : setData(filter);
            setLoading(false);
          } else {
           !a.length ? setData([]) : setData(a);
            setLoading(false);
          }

        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // -----------This function will Fetch all the Existing Locations--------------------
  const LoadLocations = async () => {
    const obj = { uname: sessionStorage.getItem("username") };

    await axios.post(url + "fetch_locations", obj)
      .then((response) => {
        var a = response.data.data;
        var arr = a.map(x => x.name.toLowerCase());
        setLoc(arr);
      })
      .catch((err) => console.log(err));
  };

  // -----------  This function will Fetch all the Existing Batch--------------------
  const LoadBatches = async () => {
    const obj = { uname: sessionStorage.getItem("username") };

    await axios.post(url + "fetch_batches", obj)
      .then((response) => {
        var a = response.data.data;
        var arr = a.map(x => x.batch_name.toLowerCase());
        setBatch(arr);
      })
      .catch((err) => console.log(err));
  };

  // Below function will convert an 'Array of Arrays' into 'Array of objects' with Custom keys

  const ConvertArrayToObject = (ArrayOfArray) => {

    let keyNames = ["fname", "mname", "lname", "addressline1", "addressline2", "city", "state", "country", "zip", "gsc", "stud_code", "dob", "acc_name", "acc_number", "mobile_no", "home_no", "parent_fname", "parent_lname", "parent_mobile_no", "location_name", "level_name", "batch_name", "email_1", "parent_email", "parent_email_2", "parent_mobile_no_2", "join_date", "leave_date"];

    let ArrayOfObj = [];

    ArrayOfArray.map(x => {
      let simpleObj = Object.assign({}, x);
      // Renaming the simpleObj object's key according to KeyNames array
      for (let i = 0; i < keyNames.length; i++) {
        simpleObj[keyNames[i]] = simpleObj[i];
        delete simpleObj[i];
      }
      simpleObj.uname = sessionStorage.getItem("username"); // Adding "uname" field in every simpleObj
      // console.log(simpleObj);
      ArrayOfObj.push((simpleObj));
    });
    console.log(ArrayOfObj);
    setFileArrayObj(ArrayOfObj);
  };

  // ############################# For uploading the Bulk Student upload Modal  #############################

  const handleOpen = () => {
    setOpen(true);
  };

  const onChange = (e) => {
    let file = e.target.files[0];
    setFile_import(file);
  };

  const UploadFile = async () => {
    setLoad(true);


    await axios
      .post(url + "php/students/excel_upload.php", fileArrayObj)
      .then((res) => {
        // console.log(res);

        if (res.data.success) {

          if (res.data.duplicate_data !== null || res.data.invalid_data !== null) {

            let x = res.data.duplicate_data !== null ? res.data.duplicate_data.map(x => `${x.fname} ${x.mname} ${x.lname} Email - ${x.email_1}`) : false;
            let y = res.data.invalid_data !== null ? res.data.invalid_data.map(x => `${x.fname} ${x.mname} ${x.lname} Email - ${x.email_1}`) : false;

            setMuiAlert({
              ...muiAlert,
              show: true, duplicate_data: x, invalid_data: y, data_inserted: true
            });

          } else {
            toast.success(`Data inserted successfully`);
          }
        } else {
          if (res.data.duplicate_data !== null || res.data.invalid_data !== null) {

            let x = res.data.duplicate_data !== null ? res.data.duplicate_data.map(x => `${x.fname} ${x.mname} ${x.lname} Email - ${x.email_1}`) : false;
            let y = res.data.invalid_data !== null ? res.data.invalid_data.map(x => `${x.fname} ${x.mname} ${x.lname} Email - ${x.email_1}`) : false;

            setMuiAlert({
              ...muiAlert,
              show: true, duplicate_data: x, invalid_data: y, data_inserted: false
            });
          } else {
            toast.error("Data not inserted, please check all the mandatory fields in all the records in the file.");
          }
        }
        loadStudents();
        setOpen(false);
        setLoad(false);
        setValidated(false);
        setFile_import(null);
      })
      .catch((err) => console.log(err));
  };

  const ValidateFile = () => {
    setLoad(true);
    xlsxParser
      .onFileSelection(file_import, { showNullProperties: true, hideEmptyRows: true })
      .then(data => {
        // console.log(data.Sheet1);
        let ArrayOfArray = [];
        data.Sheet1.map(x => ArrayOfArray.push(Object.values(x)));

        // console.log(ArrayOfArray);
        ConvertArrayToObject(ArrayOfArray); // this is creating Array of objects form the excel file.  

        const errorArray = StudentUploadValidator(ArrayOfArray, loc, batch);
        // console.log(errorArray)
        if (errorArray.length) {
          setValidatorData({ data: ArrayOfArray, error_message: errorArray });
          setLoad(false);
          setOpen(false);
          setFile_import(null);
          setValidatorDialog(true);
        } else {
          setLoad(false);
          setValidated(true);
        }
      }).catch(err => { console.log(err); });
  };

  // ------------------------Deleting Teacher table's data-------------------------------------

  const onDelete = (id, resolve) => {
    const delObj = {
      id: id,
      uname: sessionStorage.getItem("username"),
      tokenId: 123,
    };
    axios.post(url + "delete_student", delObj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  };


  // #############################################################################################



  return (
    <>
      {/* ############################################ Modal for Bulk Upload of Students in Database ############################################ */}

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Bulk Upload"}</DialogTitle>
        {validated ?
          <>
            <DialogContent>

              <List dense={false}>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>A variety of validations have been conducted on the file.</ListItemText>
                </ListItem>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>It appears error-free.</ListItemText>
                </ListItem>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>The file can now be uploaded.</ListItemText>
                </ListItem>
              </List>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                confirm({ title: "Close Confirmation", description: 'Are you sure, you want to close the popup?' })
                  .then(() => {
                    setFile_import(null);
                    setOpen(false);
                    setValidated(false);
                  });
              }} color="primary" >Close</Button>
              <Button onClick={UploadFile} disabled={file_import === null ? true : false} color="primary" size="small">
                {load ? <CircularProgress size={20} /> : "Upload"}
              </Button>

            </DialogActions>
          </> :
          <>
            <DialogContent>
              <div className="d-flex flex-column ">
                <div>
                  <hr className="mt-0" />
                  <div className="d-flex justify-content-between ">
                    <h6>Validate File before Upload</h6>
                    <a href={`${devUrl}import/Student%20Upload%20Sample%20File.xlsx`}>File Format</a>
                  </div>
                </div>
                {/* <div className="alert alert-danger mt-3">Only <strong>".csv"</strong> format file allowed.</div> */}

                <input type="file" className="form-control-file mt-1 mb-2" onChange={onChange} />

              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setOpen(false);
                setFile_import(null);
              }} color="primary" >Close</Button>
              <Button onClick={ValidateFile} disabled={file_import === null ? true : false} color="primary" size="small" >
                {load ? <CircularProgress style={{ zIndex: 100 }} size={20} /> : "Validate"}
              </Button>

            </DialogActions>
          </>
        }
      </Dialog>


      {/* ================= Validator Dialog ============================ */}

      <Dialog
        open={validatorDialog}
        // fullWidth= {true}
        // maxWidth="lg"
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title ">{"Data Validation Report Summary"}</DialogTitle>
        <DialogContent >

          {/* ---------- Showing file data in Table Format -----------------  */}
          <table className="table table-responsive table-borderless shadow-sm " style={{ height: '45%' }}>
            <thead className="shadow-sm border-bottom bg-light table-sm position-sticky" style={{ top: "0" }}>
              <tr>
                <th key={0} scope="col">No.</th>
                {StudentTableHeaders.map((x, i) => <th key={i} scope="col">{x}</th>)}
              </tr>
            </thead>
            <tbody className="overflow-scroll">
              {validatorData.data.map((x, i) => {
                //  Checking location name, whether it is proper or not. 
                var loc_exists = (x[19] ? (loc.indexOf(x[19].trim().toLowerCase()) >= 0) : false);
                //  Checking batch name, whether it is proper or not. 
                var batch_exists = (x[21] ? (batch.indexOf(x[21].trim().toLowerCase()) >= 0) : false);

                return StudentTableRow(x, i, loc_exists, batch_exists);
              })}
            </tbody>
          </table>
          {/* ---------- Showing file Error Messages in List Format -----------------  */}

          <h6 className="d-flex align-items-center"> <ErrorOutlineIcon fontSize="large" color="action" className="ms-2 me-3" />Errors Encountered are mentioned below</h6>

          <div style={{ height: "40%", overflowY: "scroll" }}>
            {validatorData.error_message.map((x, i) => {
              return <List key={i} dense={true}>
                <ListItem>
                  <ListItemIcon><ErrorIcon color="error" /></ListItemIcon>
                  <ListItemText>{x}</ListItemText>
                </ListItem>
              </List>;
            })}
          </div>

        </DialogContent>
        <DialogActions className="border-top">
          <Button onClick={() => setValidatorDialog(false)} color="primary" autoFocus>Close</Button>
        </DialogActions>
      </Dialog>

      {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

      <Dialog
        open={muiAlert.show}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Improper/Invalid Data Alert!"}</DialogTitle>
        <DialogContent>
          {muiAlert.data_inserted ?
            <>
              <p>
                DATA INSERTED SUCCESSFULLY.<br />
                But below record(s) are having error, and can't be inserted. Please correct it and try to upload again.
              </p><hr />

              {muiAlert.duplicate_data !== false && <div>
                <p>List of Duplicate data :</p>

                <ol className="ms-3">
                  {muiAlert.duplicate_data.map((x, i) => <li key={i}>{x}</li>)}
                </ol>
              </div>}

              {muiAlert.invalid_data !== false && <div>
                <p>List of Invalid data :</p>

                <ol className="ms-3">
                  {muiAlert.invalid_data.map((x, i) => <li key={i}>{x}</li>)}
                </ol>
              </div>}
            </>
            :
            <>
              <p>
                DATA NOT INSERTED.<br />
                Below record(s) are having error, and can't be inserted. Please correct it and try to upload again.
              </p>
              <hr />

              {muiAlert.duplicate_data !== false && <div>
                <p>List of Duplicate data :</p>

                <ol className="ms-3">
                  {muiAlert.duplicate_data.map((x, i) => <li key={i}>{x}</li>)}
                </ol>
              </div>}

              {muiAlert.invalid_data !== false && <div>
                <p>List of Invalid data :</p>

                <ol className="ms-3">
                  {muiAlert.invalid_data.map((x, i) => <li key={i}>{x}</li>)}
                </ol>
              </div>}
            </>
          }
        </DialogContent>
        <DialogActions>

          <Button onClick={() => setMuiAlert({ ...muiAlert, show: false })} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* ###################################################################################################################################### */}
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">
              Students list
            </li>
          </ol>
        </nav>

        <Heading name="Students List" />

        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex ">
              {sessionStorage.getItem("role") !== "Location Manager" && <Button variant="contained" onClick={handleOpen} className="px-4" startIcon={<CloudUploadIcon />}>
                Add Students in Bulk
              </Button>}
              <Button variant="contained" onClick={() => navigate("/add-student")} className="ms-auto">
                Add Student <Add />
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto " >

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                // enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.stud_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => navigate("/view-student/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

              // renderTopToolbarCustomActions={({ table }) => (
              //   <IconButton
              //     disabled={table.getSelectedRowModel().rows.length ? false : true}
              //     onClick={() => {
              //       confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
              //         .then(() => {
              //           let selectedRows = table.getSelectedRowModel().rows;
              //           // console.log(selectedRows);
              //           const delPromise = selectedRows.map((row) => {
              //             return new Promise((resolve, reject) => {
              //               onDelete(row.id, resolve);
              //             })
              //           })
              //           Promise.all(delPromise).then(() => {
              //             loadStudents();
              //             table.resetRowSelection();
              //             toast.info(selectedRows.length + " Row(s) Deleted!")
              //           });
              //         })
              //     }}
              //   ><DeleteOutline />
              //   </IconButton>
              // )}
              />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default StudentTable;
