import React from "react"
import Navbar from "../../Common/Components/Navbar"
import Footer from "../../Common/Components/Footer"
import Sidebar from "../../Sidebar/Sidebar"
import ComposeComp from "./Component/ComposeComp"


const Compose=()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea" >
            <Sidebar />
            <ComposeComp />
        </div>
        <Footer />
        </>
    )
}

export default Compose