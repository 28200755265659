import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Input, { ReadonlyInput } from "../../../Setup/Student/Component/Input";
import Heading from "../../../../Common/Components/Heading";
import axios from "axios";
import url from "../../../../Common/URL";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const AddTransactionComp = () => {

    let navigate = useNavigate();
    const [loc, setLoc] = useState([]);
    const [file, setFile] = useState("");
    const [user, setUser] = useState({
        student: [], staff: []
    });
    const [invItem, setInvItem] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [step, setStep] = useState(1); // Two step form

    const [state, setState] = useState({
        item_name: "", location: "", created_by: "", quantity: "", student: "", price: "", stud_id: "", fname: "", lname: "",
        comments: "", trans_date: "", transaction_type: "", assigned_to: "", transfer_to_location: ""
    });

    const transaction_option = [{ sign: "+", name: "Add" },
    { sign: "+", name: "Buy" }, { sign: "-", name: "Sell" }, { sign: "+", name: "Return" },
    // {sign:"-" ,name:"Issue" }, {sign:"+" ,name:"Transfer-In"} ,  {sign:"-" ,name:"Transfer-Out"} , 
    { sign: "-", name: "Issue" }, { sign: "-", name: "Transfer-Out" },
    { sign: "-", name: "Lost" }, { sign: "-", name: "Damaged" }];

    const [loc_item, setLoc_item] = useState({
        data: "", quantity: ""
    });

    const [transferIn_transType, settransferIn_transType] = useState("0");

    useEffect(() => {
        loadLocation();
        loadInventoryItems();
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = async () => {
        const obj = { uname: sessionStorage.getItem("username") };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                // console.log(response)
                let a = response.data.data;
                //   console.log(a);
                if (sessionStorage.getItem("role") === "Location Manager") {
                    let locations = sessionStorage.getItem("location").toLowerCase().split(',');
                    let filter = a.filter(x => locations.indexOf(x.name.toLowerCase()) >= 0);
                    setLoc(filter);
                } else {
                    setLoc(a);
                }
            })
            .catch((err) => console.log(err));
    };

    // -----------This function will Fetch all the Existing Inventory Items--------------------

    const loadInventoryItems = () => {
        const obj = { uname: sessionStorage.getItem("username") };

        axios.post(url + "fetch_inventorys", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    a !== null ? setInvItem(a.filter(x => x.status === "Active")) : setInvItem([]);
                } else {
                    setInvItem([]);
                }
            }).catch((err) => console.log(err));
    };
    // ------------------------Fetching users table data-------------------------------------
    const loadUsers = async () => {
        const obj = { uname: sessionStorage.getItem("username") };
        axios.post(url + "fetch_students", obj)
            .then((response) => {
                let a = response.data.data;
                // console.log(a);
                setUser({ ...user, student: a });
            }).catch((err) => console.log(err));
    };

    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        // var loc_A , loc_B;
        if (loc_item.data !== "null" && state.quantity > Number(loc_item.quantity)) {
            toast.warning("The transaction can't proceed, entered quantity is greater than the maximum quantity available");
        } else {

            setFormLoading(true);

            /* Below if Else is due to Smith Sir's backend code, He has coded such that, 
                I have to alter the values of loc_A & loc_B for Transaction_type === "Transfer-In". 
            */
            // if(state.transaction_type === "Transfer-In"){
            //     loc_A = state.transfer_to_location ;
            //     loc_B = state.location ;
            // }else{
            //     loc_A = state.location;
            //     loc_B = state.transfer_to_location ;
            // }

            // ------------Storing form data in "obj" object fetched from various input fields-------------
            var obj = {
                item_name: state.item_name, location: state.location, location_from: state.transfer_to_location, price: state.price,
                comments: state.comments, trans_date: state.trans_date, transaction_type: state.transaction_type,

                stud_id: state.transaction_type === "Return" || state.transaction_type === "Issue" || state.transaction_type === "Sell" ? state.stud_id : "",
                fname: state.transaction_type === "Return" || state.transaction_type === "Issue" || state.transaction_type === "Sell" ? state.fname : "",
                lname: state.transaction_type === "Return" || state.transaction_type === "Issue" || state.transaction_type === "Sell" ? state.lname : "",

                assigned_to: state.transaction_type === "Return" || state.transaction_type === "Issue" || state.transaction_type === "Sell" ? state.assigned_to : "",
                transfer_to_location: state.transaction_type === "Lost" || state.transaction_type === "Damaged" ? "" : state.transfer_to_location,
                created_by: sessionStorage.getItem("username"), uname: sessionStorage.getItem("username"),
                creator_fname: sessionStorage.getItem("userFname"), creator_lname: sessionStorage.getItem("userLname"),
                quantity: (state.transaction_type !== "Return" && state.transaction_type !== "Issue") ? state.quantity : 1
            };


            const formData = new FormData();
            Object.keys(obj).forEach(key => formData.append(key, obj[key]));

            formData.append("image", file);
            // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------


            // console.log(...formData);
            axios.post(url + "add_inventory_transaction", formData)
                .then((res) => {
                    // console.log(res)
                    if (res.data.success) {
                        toast.success("Transaction added successfully.");
                        navigate("/inv-transaction");
                    } else {
                        if (state.transaction_type === "Transfer-In") {
                            toast.error(`The selected location does not have the quantity available for the selected item!`);
                        } else {
                            res.data.err === null ? toast.error(`Can't perform the action, an unexpected error has occurred`)
                                : toast.error(`Can't perform action, ${res.data.err}!`);
                        }
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setFormLoading(false);
                });
        }
    };

    const onChnage_File = (e) => {
        let file = e.target.files[0];
        setFile(file);
    };

    const ProceedStep = (e) => {
        e.preventDefault();
        setFormLoading(true);
        var obj = {
            item_name: state.item_name, location: state.location,
            uname: sessionStorage.getItem("username")
        };
        axios.post(url + "select_quantity", obj)
            .then((res) => {
                console.log(res);
                if (res.data.success) {
                    let a = res.data.data;
                    console.log(a);
                    if (!a.length) {

                        if (transferIn_transType === "1") {
                            toast.error("The selected location has zero quantity of the selected item.");
                        } else {
                            setLoc_item({ ...loc_item, data: "null" });
                            setStep(2);
                        }
                    }
                    else if (a[0].quantity === "0") {
                        if (transferIn_transType === "1") {
                            toast.error("The selected location has zero quantity of the selected item.");
                        } else {
                            setLoc_item({ ...loc_item, data: "null" });
                            setStep(2);
                        }
                    }
                    else if (a[0].quantity !== "0") {
                        setLoc_item({ ...loc_item, quantity: a[0].quantity });
                        setStep(2);
                    }

                } else {
                    toast.error("Error While Fetching Data");
                    setState({ ...state, location: "", item_name: "" });
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setFormLoading(false);
            });

    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Inventory</li>
                        <li className="breadcrumb-item "><Link to="/inv-transaction">Internal Transactions</Link></li>
                        <li className="breadcrumb-item active">Add Transaction</li>
                    </ol>
                </nav>
                <Heading name="Add Transaction" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                {step === 1 ?
                    <form onSubmit={ProceedStep} id="myform">
                        <div className="container-fluid mt-3">

                            <div className="row">
                                <div className="col-11 col-md-auto ">

                                    <div className="mb-3 row">
                                        <label className="col-auto col-form-label">Do you want to perform Transfer-In Transaction ?</label>
                                        <div className="ms-4">
                                            <RadioGroup row value={transferIn_transType}
                                                onChange={(e) => {
                                                    // console.log(e.target.value)
                                                    setState({ ...state, transaction_type: e.target.value === "1" ? "Transfer-In" : "" });
                                                    settransferIn_transType(e.target.value);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color="primary" />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio color="default" />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                        </div>
                                    </div>

                                    <h5 className="mb-4">Step 1 - Item Information</h5>



                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">{transferIn_transType === "1" ? "Transfer In From Location" : "Location Name"}</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select required name="location" value={state.location} onChange={onChange} className="form-select">
                                                <option value="" >Choose</option>
                                                {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Select Item</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select required name="item_name" value={`${state.item_name}^${state.price}`}
                                                onChange={(e) => {
                                                    let data = e.target.value;
                                                    setState({ ...state, price: data.split("^")[1], item_name: data.split("^")[0] });
                                                }}
                                                className="form-select">
                                                <option value="" >Choose</option>
                                                {invItem.map((data, i) => (<option key={i} value={`${data.product_name}^${data.price}`}>{data.product_name}</option>))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row my-4">
                                        <div className="col-12 d-flex justify-content-end mx-auto">
                                            <Button variant="contained" type="submit" disabled={formLoading}>{formLoading ? <CircularProgress color="inherit" size={20} /> : 'Proceed'}</Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>

                    :
                    <form onSubmit={onSubmit} id="myform">
                        <div className="container-fluid mt-3">

                            <div className="row">
                                <div className="col-11 col-md-6 ">

                                    <h5 className="mb-4">Step 2 -Other Information</h5>



                                    <ReadonlyInput value={state.location} field={transferIn_transType === "1" ? "Transfering In From Location" : "Selected Location"} />


                                    <ReadonlyInput value={state.item_name} field="Selected Item" />

                                    {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Assigned To</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <select  name="assigned_to" value={state.assigned_to}  onChange={onChange} className="form-select">
                                    <option value="" >Choose</option>
                                    {user.staff.map((x,i) =><option key={i} value={x.username}>{x.username}</option>)}
                                    </select>
                                </div>
                            </div> */}

                                    {transferIn_transType !== "1" &&
                                        <div className="mb-3 row">
                                            <label className="col-sm-5 col-form-label red-asterisk">Transaction Type</label>
                                            <div className="col-sm-7 col-md-7 ">
                                                <select required name="transaction_type" value={state.transaction_type} onChange={onChange} className="form-select">
                                                    <option value="" >Choose</option>
                                                    {loc_item.data === "null" ?
                                                        transaction_option.filter(x => x.sign === "+").map((x, i) => <option key={i} value={x.name}>{x.name}</option>)
                                                        :
                                                        transaction_option.map((x, i) => <option key={i} value={x.name}>{x.name}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>}

                                    {(state.transaction_type !== "" && state.transaction_type !== "Return" && state.transaction_type !== "Issue") &&

                                        <div className="mb-3 row">
                                            <label className="col-sm-5 col-form-label red-asterisk">Quantity</label>
                                            <div className="col-sm-7 col-md-7 ">
                                                <input type="number"
                                                    placeholder="Add Quantity"
                                                    className="form-control"
                                                    value={state.quantity}
                                                    name="quantity"
                                                    onChange={onChange}
                                                    required
                                                />
                                                {(loc_item.data !== "null" && state.transaction_type === "Lost" ||
                                                    state.transaction_type === "Damaged" || state.transaction_type === "Sell" ||
                                                    state.transaction_type === "Transfer-Out" || state.transaction_type === "Issue" || state.transaction_type === "Transfer-In"
                                                ) &&
                                                    (<small className="form-text text-end" style={{ color: "#ce1212" }}>Maximun Quantity Available is {`${loc_item.quantity}`}</small>)}
                                            </div>
                                        </div>
                                    }

                                    {/* Transaction type equals to Issue or Return */}
                                    {(state.transaction_type === "Return" || state.transaction_type === "Issue") && <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">{state.transaction_type === "Issue" ? 'To Student' : "From Student"}</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select name="assigned_to" className="form-select"
                                                value={`${state.stud_id}^${state.fname}^${state.lname}^${state.assigned_to}`}
                                                onChange={(e) => {
                                                    let data = e.target.value;
                                                    setState({
                                                        ...state, stud_id: data.split("^")[0], fname: data.split("^")[1],
                                                        lname: data.split("^")[2], assigned_to: data.split("^")[3]
                                                    });
                                                }}
                                            >
                                                <option value="" >Choose</option>
                                                {user.student.map((x, i) => <option key={i}
                                                    value={`${x.stud_id}^${x.fname}^${x.lname}^${x.user_name}`}
                                                >{`${x.fname} ${x.lname}`}</option>)}
                                            </select>
                                        </div>
                                    </div>}

                                    {/* Transaction type equals Sell */}
                                    {(state.transaction_type === "Sell") && <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">To Student</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select name="assigned_to" className="form-select"
                                                value={`${state.stud_id}^${state.fname}^${state.lname}^${state.assigned_to}`}
                                                onChange={(e) => {
                                                    let data = e.target.value;
                                                    setState({
                                                        ...state, stud_id: data.split("^")[0], fname: data.split("^")[1],
                                                        lname: data.split("^")[2], assigned_to: data.split("^")[3]
                                                    });
                                                }}
                                            >
                                                <option value="" >Choose</option>
                                                {user.student.map((x, i) => <option key={i}
                                                    value={`${x.stud_id}^${x.fname}^${x.lname}^${x.user_name}`}
                                                >{`${x.fname} ${x.lname}`}</option>)}
                                            </select>
                                        </div>
                                    </div>}


                                    {(state.transaction_type === "Transfer-Out" || state.transaction_type === "Transfer-In") &&
                                        <div className="mb-3 row">
                                            <label className="col-sm-5 col-form-label red-asterisk">To Location</label>
                                            <div className="col-sm-7 col-md-7 ">
                                                <select required name="transfer_to_location" value={state.transfer_to_location} onChange={onChange} className="form-select">
                                                    <option value="" >Choose</option>
                                                    {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                                </select>
                                            </div>
                                        </div>}



                                    <Input name="trans_date" onChange={onChange} field="Transaction Date" type="date" placeholder="Add Date" className="form-control" value={state.trans_date} required={true} />

                                    <Input name="comments" onChange={onChange} field="Comments" type="text" placeholder="Add Comment" className="form-control" value={state.comments} />

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label ">Add File</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <input type="file" className="form-control-file" onChange={onChnage_File} />
                                        </div>
                                    </div>

                                </div>

                                <div className="col-11 col-md-6 d-flex justify-content-end align-items-start">
                                    <Button variant="contained" type="submit" disabled={formLoading} >{formLoading ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                                </div>

                            </div>
                        </div>
                    </form>
                }

            </div>

        </>
    );
};
export default AddTransactionComp;  