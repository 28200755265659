import React from "react"
import Navbar from "../../../../Common/Components/Navbar"
import Footer from "../../../../Common/Components/Footer"
import Sidebar from "../../../../Sidebar/Sidebar"
import TsTaskEditComp from "./Component/TsTaskEditComp"


const TsTaskEdit =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <TsTaskEditComp />
        </div>
        <Footer />
        </>
    )
}

export default TsTaskEdit