import React, { useState, useEffect } from "react";
import Input, { ReadonlyInput } from "../../Student/Component/Input";
import Heading from "../../../../Common/Components/Heading.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import url from "../../../../Common/URL";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import CircularProgress from '@mui/material/CircularProgress';
import { useConfirm } from "material-ui-confirm";
import { CurrentDate } from "../../../../Common/Functions/StringDateTime.jsx";
import { useSelector } from "react-redux";

const EditUserComp = () => {

  const confirm = useConfirm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let message = "";
  const { id } = useParams();

  const [field, setField] = useState({
    uname: "", password: "", email: "", firstname: "", lastname: "", status: "", role: ""
  });

  const [spinner, setSpinner] = useState(false);

  const [data, setData] = useState({
    uname: "", new_uname: "", id: "", updated_by: "", role: "",
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadUser();
    // loadRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // -----------This function will Fetch all the Existing Roles--------------------
  // const loadRoles =  () => {
  //       const obj = {
  //         id: id,
  //         uname: sessionStorage.getItem("username"),
  //       };

  //        axios.post(url+"roles", obj)
  //       .then( (response) => {
  //         let a = response.data.data;
  //         // console.log(a);
  //         setData(a);
  //       })
  //       .catch( (err) => console.log(err) );
  //       }

  // -----------This function will Fetch the User's Data--------------------
  const loadUser = (res) => {
    const obj = {
      id: id,
      uname: sessionStorage.getItem("username"),
    };

    axios.post(url + "fetch_single_user", obj)
      .then((response) => {
        let resData = response.data.data;
        // console.log(resData)
        setField({
          uname: resData[0].username,
          email: resData[0].email,
          firstname: resData[0].first_name,
          lastname: resData[0].last_name,
          status: resData[0].active,
          role: resData[0].role
        });
        res !== undefined && res();
      })
      .catch((err) => console.log(err));
  };


  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    setField((preValue) => {
      return {
        ...preValue,
        [name]: value
      };
    });
  };

  const onUserNameChange = (e) => {
    e.preventDefault();
    setSpinner(true);

    /*---------------Creating object to send data through Axios------------------------- */
    const obj = {
      uname: field.uname, new_uname: data.new_uname, updated_on: CurrentDate(),
      id: id, updated_by: sessionStorage.getItem("username"), role: field.role,
    };

    axios.post(url + "update_username", obj)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          let promise = new Promise((res, rej) => {
            loadUser(res);
          });

          promise.then(() => {
            setSpinner(false);
            setOpen(false);
            toast.success("Updated successfully!");
          });
        } else {
          setSpinner(false);
          setOpen(false);
          toast.warning("Username not updated, please try again later.");
        }

      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    /*---------------Creating object to send data through Axios------------------------- */
    const obj = {
      uname: field.uname, email: field.email, firstname: field.firstname, role: field.role,
      lastname: field.lastname, status: field.status, id: id, updated_by: sessionStorage.getItem("username"),
      updated_on: CurrentDate()
    };

    axios.post(url + "edit_user", obj)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          loadUser();
          toast.success("Updated successfully!");
        } else {
          message = res.data.message;
          toast.error(message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  // ======================


  return (
    <>

      {/* ==================Dialog popup for Edit UserName===================== */}

      <Dialog fullWidth={true} maxWidth="sm" open={open}>
        <DialogTitle id="max-width-dialog-title"> Update Username<hr className="p-0 mt-2" /> </DialogTitle>
        <DialogContent>
          <form onSubmit={onUserNameChange} id="usernameChangeForm">
            <div className="mb-3 row">
              <label className="col-auto col-form-label">Enter Updated Username</label>
              <div className="col">
                <input
                  onChange={(e) => {
                    setData({ ...data, new_uname: e.target.value });
                  }}
                  value={data.new_uname}
                  type="text"
                  className="form-control"
                  required
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              confirm({
                title: "Discard Confirmation",
                description: "Are you sure, you want to discard this?",
                confirmationText: 'Yes'
              }).then(() => {
                setOpen(false);
                setData({ ...data, new_uname: "" });
              });
            }}
            color="primary">Close</Button>
          <Button form="usernameChangeForm" color="primary" type="submit"> {!spinner ? "Save" : <CircularProgress size={20} />}</Button>
        </DialogActions>
      </Dialog>
      {/* ============================================================================= */}

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        {/*----------------------- Bread crumb ------------------------- */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item"><Link to="/user">Users</Link></li>
            <li className="breadcrumb-item active">Edit User</li>
          </ol>
        </nav>
        <Heading name={`Edit User - ${field.firstname}`} />
        <form onSubmit={onSubmit} id="editUserForm">

          <div className="container-fluid mt-3">


            <div className="row mb-4 ">
              <div className="col-12 d-flex justify-content-between align-items-end pe-md-5">
                <h5>Edit Information</h5>
                <div>
                  {(field.role !== "" && field.role !== "Superadmin" && field.role !== "Systemadmin") &&
                    <Button variant="contained" color="error" onClick={() => setOpen(true)} className="me-3 ">Change Username</Button>}
                  <Button variant="contained" onClick={() => navigate("/view-user/" + id)} className="me-3 ">Back</Button>
                  <Button variant="contained" type="submit" disabled={loading} form="editUserForm" >{loading ? <CircularProgress color="inherit" size={20} /> :'Save'}</Button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-11 col-md-8">
                <div>
                  {/* <ReadonlyInput field="Sr No" type="text" placeholder="Add text" className="form-control border-0 fw-bold bg-light" value={id} /> */}


                  <ReadonlyInput field="Username" name="uname" type="text" placeholder="Add text" className="form-control border-0 fw-bold bg-light " value={field.uname} />

                  <Input field="Email" name="email" type="email" placeholder="Add text" className="form-control" value={field.email} onChange={onChange} required={true} />

                  <Input field="First Name" name="firstname" type="text" placeholder="Add text" className="form-control" value={field.firstname} onChange={onChange} required={false} />

                  <Input field="Last Name" name="lastname" type="text" placeholder="Add text" className="form-control" value={field.lastname} onChange={onChange} required={false} />


                  {/* // <div className="mb-3 row">
                            //     <label className="col-sm-5 col-form-label">Student ID</label>
                            //     <div className="col-sm-7 col-md-6 ">
                            //     <input type="text" readonly className="form-control-plaintext" value="" />
                            //     </div>
                            // </div> */}

                  {(field.role !== "" && field.role !== "Student" && field.role !== "Teacher" && field.role !== "Location Manager") && <div className="mb-3 row">
                    <label className="col-sm-5 col-form-label ">Role</label>
                    <div className="col-sm-7 col-md-7 ">
                      <select className="form-select" value={field.role} name="role" onChange={onChange} >
                        <option value="" >Choose Roles</option>

                        {sessionStorage.getItem("role") === "Systemadmin" && <option value="Systemadmin" >Systemadmin</option>}
                        <option value="Superadmin" >Superadmin</option>
                        <option value="Admin" >Admin</option>
                        <option value="Account" >Accountant</option>
                      </select>
                    </div>
                  </div>}


                  {/* <ReadonlyInput field="Role" name="role"  className="form-control border-0 fw-bold bg-light " value={field.role}  /> */}

                  <div className="mb-3 row">
                    <label className="col-sm-5 col-form-label">Status</label>
                    <div className="col-sm-7 col-md-6 ">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input"
                          type="radio"
                          id="radio1"
                          value="1"
                          checked={field.status === "1"}
                          name="status" onChange={onChange} />
                        <label className="form-check-label" htmlFor="radio1">Active</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input"
                          type="radio"
                          id="radio2"
                          value="0"
                          checked={field.status === "0"}
                          name="status" onChange={onChange} />
                        <label className="form-check-label" htmlFor="radio2">Inactive</label>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditUserComp

