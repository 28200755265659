import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import Heading from "../../../Common/Components/Heading"
import LoadingSpinner from "../../../Common/Components/LoadingSpinner";
import axios from "axios";
import url from "../../../Common/URL"
import { ReadableDateTime } from "../../../Common/Functions/StringDateTime";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
const ActivityLogComp = () => {

  const [loading, setLoading] = useState(true);


  const Columns = useMemo(
    () => [
      { header: 'Name', id: 'name', accessorFn: rowData => `${rowData.first_name} ${rowData.last_name}` },
      { header: 'Role', accessorKey: 'role' },
      { header: 'Logged In At', id: 'time_loged_in', accessorFn: rowData => ReadableDateTime(rowData.time_loged_in) }
    ],
    [],
  );

  const [data, setData] = useState([]);


  // ------------------------Fetching Level table's data-------------------------------------
  useEffect(() => {
    loadActivityLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadActivityLog = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios.post(url + "activity", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
         !a.length ? setData([]) : setData(a.reverse());
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>

      {/* ###################################################################################################################################### */}
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>

            <li className="breadcrumb-item " aria-current="page">
              System Health
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Activity Log
            </li>
          </ol>
        </nav>

        <Heading name="Activity Log" />

        <div className="container-fluid mb-5">
         
          <div className="row">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                // enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.id}
              />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default ActivityLogComp;
