import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";


import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";


import Add from '@mui/icons-material/Add';

import axios from "axios";
import { useConfirm } from 'material-ui-confirm';
import url from "../../../../Common/URL";

import Delete from "@mui/icons-material/Delete";
import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const ActTransactionComp = () => {

  const confirm = useConfirm();


  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  });

  const Columns = useMemo(
    () => [
      { header: 'Name', id: 'name', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
      { header: 'Item Name', accessorKey: 'item_name' },
      { header: 'Transaction Type', accessorKey: 'type' },
      { header: 'Created By', id: 'created_by', accessorFn: rowData => `${rowData.creator_fname} ${rowData.creator_lname}` },
      { header: 'Transaction Date', accessorKey: 'acc_trans_date' },
    ],
    [],
  );


  // ------------------------Fetching Transaction table's data-------------------------------------
  useEffect(() => {
    loadTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTransactions = () => {
    const obj = { uname: state.uname };
    axios.post(url + "fetch_account_transactions", obj)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          setData(a);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // ------------------------Deleting Transaction table's data-------------------------------------

  const onDelete = (id, resolve) => {
    const delObj = {
      id: id,
      uname: state.uname,
      tokenId: 123,
    };
    axios.post(url + "delete_acc_trans", delObj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Accounts</li>
            <li className="breadcrumb-item active" aria-current="page">Inventory Transactions</li>
          </ol>
        </nav>

        <Heading name="Inventory Transactions" />

        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end ">
              <Button variant="contained" onClick={() => navigate("/add-act-trans")} >Add Transaction <Add /></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.acc_trans_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/edit-act-trans/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <Button variant="contained" color="error" startIcon={<Delete />}
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            });
                          });
                          Promise.all(delPromise).then(() => {
                            loadTransactions();
                            table.resetRowSelection();
                            toast.info(selectedRows.length + " Row(s) Deleted!");
                          });
                        });
                    }}
                  >Delete
                  </Button>
                )}
              />
            </div>
          </div>
        </div>

      </div>

    </>
  );

};
export default ActTransactionComp;