import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../../../Common/URL";
import { toast } from "react-toastify";
import Input from "../../Student/Component/Input";
import Heading from "../../../../Common/Components/Heading";
import { Button, CircularProgress } from "@mui/material";

import StringToTime from "../../../../Common/Functions/StringDateTime";
import { useSelector } from "react-redux";

const AddBatchComp = () => {
  let navigate = useNavigate();

  const [loc, setLoc] = useState([]);
  // const [lev , setLev] = useState([]);

  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
    batch_name: "",
    location_name: "",
    level_name: "",
    day_of_week: "",
    city: "",
    time_of_day: "",
    batch_calendar_link: "",
    remarks: "",
    location_id: "",
  });


  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------One onChange function is used for setting the state of various types of input fields ---------------

  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;
    setState((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const onLoc_Change = (e) => {
    var data = e.target.value.split("*");
    var loc_name,
      loc_city = "",
      loc_id;

    if (data.length === 2) {
      loc_name = data[0];
      loc_id = data[1];
    } else {
      loc_name = data[0];
      loc_city = data[1];
      loc_id = data[2];
    }
    // console.log(loc_name);console.log(loc_city);console.log(loc_id);

    setState((pre) => {
      return {
        ...pre,
        location_id: loc_id,
        location_name: loc_name,
        city: loc_city,
      };
    });
  };

  // -----------This function will Fetch all the Existing Locations--------------------
  const loadLocation = async () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    await axios
      .post(url + "fetch_locations", obj)
      .then((response) => {
        let a = response.data.data;
        //   console.log(a);
        setLoc(a);
      })
      .catch((err) => console.log(err));
  };

  //-------------------------- onSubmit function for Submitting the  Forms---------------------------

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    var newdate = year + "-" + month + "-" + day;

    // ------------Storing form data in "obj" object fetched from various input fields-------------
    const obj = {
      uname: state.uname,
      batch_name: `${state.day_of_week} - ${state.city === "" ? state.location_name : `${state.location_name} - ${state.city}`
        } - ${StringToTime(state.time_of_day)}`,
      location_name: state.location_name,
      level_name: state.level_name,
      city: state.city,
      day_of_week: state.day_of_week,
      time_of_day: state.time_of_day,
      batch_calendar_link: state.batch_calendar_link,
      remarks: state.remarks,
      location_id: state.location_id,
      created_by: state.uname,
      created_at: newdate,
    };
    // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------
    // console.log(obj);
    axios
      .post(url + "add_batch", obj)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          toast.success("Batch created successfully ");
          navigate("/batch");
        } else {
          toast.error("Batch not created, please try again");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Batch not created, please try again");
      }).finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item">
              <Link to="/batch">Batch List</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add Batch
            </li>
          </ol>
        </nav>

        <Heading name="Add Batch" />

        <div className="container-fluid">
          <form onSubmit={onSubmit} id="myform">
            <div className="row mb-4">
              <div className="col-12 d-flex justify-content-between ">
                <h5>Batch Information</h5>
                <div>
                  <Button variant="contained" onClick={() => navigate("/batch")} >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" className=" mx-3 " disabled={loading}>
                    {loading ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                  </Button>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-11 col-md-7 mx-auto mx-md-0">
                {/* <Input name="batch_name" onChange={onChange} value={state.batch_name} field="Batch Name" type="text" placeholder="Add Name" className="form-control"/> */}

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                  <div className="col-sm-7 col-md-7 ">
                    <select
                      name="location_name"
                      value={
                        state.city === ""
                          ? `${state.location_name}*${state.location_id}`
                          : `${state.location_name}*${state.city}*${state.location_id}`
                      }
                      onChange={onLoc_Change}
                      className="form-select"
                      required>
                      <option value="">Choose</option>
                      {loc.map((data, i) => (
                        <option
                          key={i}
                          value={
                            data.city === ""
                              ? `${data.name}*${data.location_id}`
                              : `${data.name}*${data.city}*${data.location_id}`
                          }>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label">Level</label>
                            <div className="col-sm-7 col-md-7 ">
                                <select name="level_name" value={state.level_name}  onChange={onChange} className="form-select">
                                <option >Choose</option>
                                {lev.map((data , i)=>(<option key={i} value={data.level_name}>{data.level_name}</option>)) } 
                                </select>
                            </div>
                    </div> */}

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Day of Week</label>
                  <div className="col-md-7 col-sm-7">
                    <select
                      name="day_of_week"
                      value={state.day_of_week}
                      onChange={onChange}
                      className="form-select"
                      required>
                      <option value="">Choose</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select>
                  </div>
                </div>
                <Input
                  name="time_of_day"
                  onChange={onChange}
                  value={state.time_of_day}
                  field="Time of day"
                  type="time"
                  className="form-control"
                  required={true}
                />

                <div className="mb-3 row">
                  <label className=" col-sm-5 col-form-label">Batch Calendar Link</label>
                  <div className="col-sm-7 col-md-7">
                    <input
                      name="batch_calendar_link"
                      onChange={onChange}
                      value={state.batch_calendar_link}
                      type="text"
                      className="form-control"
                      placeholder="Add link"
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className=" col-sm-5 col-form-label">Remarks</label>
                  <div className="col-sm-7 col-md-7">
                    <textarea
                      name="remarks"
                      onChange={onChange}
                      value={state.remarks}
                      type="text"
                      className="form-control"
                      rows="2"
                      placeholder="Add remarks here..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddBatchComp;
