import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../Common/Components/Heading";
import LoadingSpinner from "../../../Common/Components/LoadingSpinner";
import RestorePageIcon from '@mui/icons-material/RestorePage';
import axios from "axios";
import url from "../../../Common/URL";
import assignmentQuestionsData from "../../../Common/Question Generator/AssignmentQuestionsLogic";
import { Box, Button, Drawer, IconButton, Tooltip } from "@mui/material";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import { ReadableDateTime } from "../../../Common/Functions/StringDateTime";
import AssignmentGeneratorHistoryComp from "./Assignments/Component/AssignmentGeneratorHistoryComp";


const GeneratorComp = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const role = sessionStorage.getItem("role");

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        // ------------normal input fields--------------
        test_type: "Assignment", level_name: "", level_no: "", page_type: "", no_of_rows: "", no_of_questions: 20
    });

    const [score, setScore] = useState({
        exam_test: [], assignment: []
    });

    useEffect(() => {
        loadScores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Columns = useMemo(
        () => [
            { header: 'Level', size: 40, id: 'level', accessorFn: rowData => `Level ${rowData.test_level}` },
            { header: 'Test Name', size: 40, accessorKey: 'test_name', },
            { header: 'Questions', size: 40, accessorKey: 'total_questions', },
            { header: 'Attempted %', size: 40, id: 'attempt_per', accessorFn: rowData => `${rowData.attempted_percentage} %` },
            { header: 'Accuracy %', size: 40, id: 'accuracy_per', accessorFn: rowData => `${rowData.total_accuracy_per} %` },
            { header: 'Date', size: 40, id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },
        ],
        [],
    );

    const loadScores = async () => {
        setLoading(true);
        const obj = { uname: sessionStorage.getItem("username") };
        try {
            const response = await axios.post(url + "view_test_list_stud", obj);
            if (response.data.success) {
                let b = response.data.data || [];
                let assignment_arr = b.filter(x => x.test_type === "Assignment").reverse();
                setScore({ ...score, assignment: assignment_arr });
            } else {
                setScore({ ...score, assignment: [], exam_test: [] });
            }
        } catch (err) {
            console.error(err);
            setScore({ ...score, assignment: [], exam_test: [] });
        } finally {
            setLoading(false);
        }
    };



    // -----------  This function will Provide levels according to student's level--------------------
    const ProvideLevels = () => {
        let selectOptions = [];

        if (state.test_type === 'Assignment') {
            let total_levels;
            if (role === 'Student')
                total_levels = Number(sessionStorage.getItem('level_name').split(' ')[1]);
            else total_levels = 10;

            for (let i = 1; i <= total_levels; i++) {
                selectOptions.push(<option key={i} value={i}>{`Level ${i}`}</option>);
            }
            return selectOptions;
        } else if (state.test_type === 'Test' || state.test_type === 'Practice Test') {
            let levels = [
                { value: 0, display: 'Level 0' }, { value: '5s', display: "Level 5's Complement" },
                { value: 1, display: 'Level 1' },
                { value: 2, display: 'Level 2' }, { value: 3, display: 'Level 3' }, { value: 4, display: 'Level 4' },
                { value: 5, display: 'Level 5' }, { value: 6, display: 'Level 6' }, { value: 7, display: 'Level 7' },
                { value: 8, display: 'Level 8' }, { value: 9, display: 'Level 9' }, { value: 10, display: 'Level 10' }
            ];

            levels.forEach((x, i) => {
                selectOptions.push(<option key={i} value={x.value}>{x.display}</option>);
            });
            return selectOptions;
        } else { // Test type = "Exam" OR 'Practice Exam'
            let levels = [
                { value: 1, display: 'Level 1' }, { value: '2B', display: "Level 2's Beginner" },
                { value: 2, display: 'Level 2' }, { value: 3, display: 'Level 3' }, { value: 4, display: 'Level 4' },
                { value: 5, display: 'Level 5' }, { value: 6, display: 'Level 6' }, { value: 7, display: 'Level 7' },
                { value: 8, display: 'Level 8' }, { value: 9, display: 'Level 9' }, { value: 10, display: 'Level 10' }
            ];

            levels.forEach((x, i) => {
                selectOptions.push(<option key={i} value={x.value}>{x.display}</option>);
            });
            return selectOptions;
        }

    };

    const onChange = (e) => {
        let input = e.target;
        let name = input.name;
        let value = input.type === 'checkbox' ? input.checked : input.value;
        if (name === 'test_type')
            setState((preValue) => ({ ...preValue, [name]: value, level_name: '', level_no: '' }));
        else
            setState((preValue) => ({ ...preValue, [name]: value }));
    };

    const onLevelChange = (e) => {
        let input = e.target;
        let value = input.value;
        if (value === '') {
            setState((preValue) => ({
                ...preValue, level_name: value, page_type: '', no_of_rows: '',
                level_no: ''
            }));

        } else {
            let x = input.value.trim().split(/\s+/);
            setState((preValue) => ({ ...preValue, level_name: value, level_no: x[0], page_type: '', no_of_rows: '' }));
        }
    };

    const onGenerate = (e) => {
        e.preventDefault();
        let level = state.level_no;
        let testType = state.test_type;

        if (state.test_type === 'Assignment') {
            let typeId = state.page_type;
            let noOfQuestions = state.no_of_questions;
            let rowArray = assignmentQuestionsData[state.level_no][state.page_type].rows;
            let noOfRows = rowArray.length > 1 ? state.no_of_rows : rowArray[0];
            navigate(`/assignment-preview-and-attend/${level}/${typeId}/${noOfRows}/${noOfQuestions}/${testType}`);
        } else navigate(`/test-exam-preview/${testType}/${level}`);
    };


    // Drawer 1 - Side Drawer for Previewing Generator Generated Assignments History
    const [openGeneratorAssignmentPreviewDrawer, setOpenGeneratorAssignmentPreviewDrawer] = useState(false);
    const [generatorAssignmentData, setGeneratorAssignmentData] = useState({
        studentUname: '', studentAnswerID: ''
    });

    return (
        <>
            {/* Side Drawer for Previewing Generator Generated Assignments History */}
            <Drawer
                PaperProps={{ style: { width: '95%' } }}
                anchor='right'
                open={openGeneratorAssignmentPreviewDrawer}
                onClose={() => setOpenGeneratorAssignmentPreviewDrawer(false)}
            >
                <AssignmentGeneratorHistoryComp
                    studentUname={generatorAssignmentData.studentUname}
                    studentAnswerID={generatorAssignmentData.studentAnswerID}
                    setOpenDrawer={setOpenGeneratorAssignmentPreviewDrawer} />
            </Drawer >

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item active" aria-current="page">Generator</li>
                    </ol>
                </nav>

                <Heading name="Generator" />
                {loading ? <LoadingSpinner /> :
                    <div className="container-fluid mb-5 mt-4">
                        <nav>
                            <div className="nav mb-4  border-bottom" id="nav-tab" role="tablist">
                                <a className="nav_tab nav-link active" data-bs-toggle="tab" href="#nav-generator" role="tab" aria-controls="nav-generator" aria-selected="true">Question Generator</a>
                                {role === 'Student' &&
                                    <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-assignment-history" role="tab" aria-controls="nav-assignment-history" aria-selected="false">Generated Assignments History</a>
                                }
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-generator" role="tabpanel" aria-labelledby="nav-generator">
                                <form onSubmit={onGenerate}>
                                    <div className="row">
                                        {/*-----------------------Column-1 for Inputs------------------------- */}
                                        <div className="col-11 col-md-6 mx-auto">

                                            {/* <h5 className="mb-4">Test Information</h5> */}

                                            <div className="mb-3 row">
                                                <label className="col-sm-5 col-form-label red-asterisk">Test Type</label>
                                                <div className="col-sm-7 col-md-7 ">
                                                    <select name="test_type" value={state.test_type} onChange={onChange} className="form-select" required>
                                                        <option value="" >Select Test</option>
                                                        <option value="Assignment">Assignment</option>
                                                        {(role === 'Admin' || role === 'Superadmin' || role === 'Systemadmin') && <option value="Test">Test</option>}
                                                        {(role === 'Admin' || role === 'Superadmin' || role === 'Systemadmin' || role === 'Teacher') && <option value="Practice Test">Practice Test</option>}
                                                        {(role === 'Admin' || role === 'Superadmin' || role === 'Systemadmin') && <option value="Exam">Exam</option>}
                                                        {(role === 'Admin' || role === 'Superadmin' || role === 'Systemadmin' || role === 'Teacher') && <option value="Practice Exam">Practice Exam</option>}
                                                    </select>
                                                </div>
                                            </div>

                                            {state.test_type !== '' && <div className="mb-3 row">
                                                <label className="col-sm-5 col-form-label red-asterisk">Level</label>
                                                <div className="col-sm-7 col-md-7 ">
                                                    <select required name="level" value={state.level_name} onChange={onLevelChange} className="form-select">
                                                        <option value="">Choose</option>
                                                        <ProvideLevels />
                                                    </select>
                                                </div>
                                            </div>}

                                            {state.test_type === 'Assignment' && <div className="mb-3 row">
                                                <label className="col-sm-5 col-form-label red-asterisk">Page type</label>
                                                <div className="col-sm-7 col-md-7 ">
                                                    <select name="page_type" value={state.page_type} onChange={onChange} className="form-select" required>
                                                        <option value="" >Select Type</option>
                                                        {state.level_no !== '' && Object.values(assignmentQuestionsData[state.level_no]).map((data, i) => {
                                                            return (<option key={i} value={data.typeId}>{data.name}</option>);
                                                        })}
                                                    </select>
                                                </div>
                                            </div>}

                                            {state.test_type === 'Assignment' && (state.level_no !== '' && state.page_type !== '') && assignmentQuestionsData[state.level_no][state.page_type].rows.length > 1 &&
                                                <div className="mb-3 row">
                                                    <label className="col-sm-5 col-form-label red-asterisk">Number of Rows</label>
                                                    <div className="col-sm-7 col-md-7 ">
                                                        <select name="no_of_rows" value={state.no_of_rows} onChange={onChange} className="form-select" required>
                                                            <option value="" >Select Rows</option>
                                                            {state.page_type !== '' && assignmentQuestionsData[state.level_no][state.page_type].rows.map((data, i) => {
                                                                return (<option key={i} value={data}>{data}</option>);
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>}

                                            {state.test_type === 'Assignment' && <div className="mb-3 row">
                                                <label className="col-sm-5 col-form-label red-asterisk">No. Of Questions</label>
                                                <div className="col-sm-7 col-md-7 ">
                                                    <select
                                                        name="no_of_questions" value={state.no_of_questions}
                                                        onChange={onChange} className="form-select" required>
                                                        <option value="" >Select Questions</option>
                                                        {state.level_no !== '' && state.page_type !== '' &&
                                                            assignmentQuestionsData[state.level_no][state.page_type].questionsCount.map((data, i) => {
                                                                return (<option key={i} value={data}>{data}</option>);
                                                            })}
                                                    </select>
                                                </div>
                                            </div>}
                                        </div>
                                        {/*-----------------------Column-2 for Inputs------------------------- */}
                                        <div className="col-11 col-md-6 mx-auto d-flex justify-content-end align-items-start">
                                            <Button variant="contained" type="submit" >Generate</Button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="tab-pane fade show" id="nav-assignment-history" role="tabpanel" aria-labelledby="nav-assignment-history">
                                <div className="row mb-5">
                                    <div className="col-12 mx-auto">

                                        <MaterialReactTable
                                            columns={Columns}
                                            data={score.assignment}
                                            enableRowNumbers
                                            initialState={{ density: 'compact' }}
                                            enableDensityToggle={false}
                                            getRowId={(originalRow) => originalRow.id}
                                            positionActionsColumn="first"
                                            enableRowActions
                                            renderRowActions={(row, index) => (
                                                <Box>
                                                    <Tooltip title="View Answers" placement="right">
                                                        <IconButton onClick={() => {
                                                            let rowData = row.row.original;
                                                            var stud_uname = rowData.created_by;
                                                            var stud_ans_id = rowData.stud_ans_id;
                                                            setGeneratorAssignmentData({ studentUname: stud_uname, studentAnswerID: stud_ans_id });
                                                            setOpenGeneratorAssignmentPreviewDrawer(true);
                                                        }}>
                                                            <RestorePageIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};
export default GeneratorComp;