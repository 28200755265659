import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ReadonlyInput } from "../../../Tabs/Setup/Student/Component/Input"
import axios from "axios"
import url from "../../../Common/URL"
import { Button } from "@mui/material";


const StudentDetailProfileComp = () => {
    const navigate = useNavigate();

    // ################################################Below Data for 1st Tab ########################################################

    const [field, setField] = useState({

        uname: sessionStorage.getItem("username"), stud_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_no: "", home_no: "", email: "",
        parent_fname: "", parent_lname: "", parent_mobile_no: "", parent_email: "", parent_mobile_no_2: "",
        parent_email_2: "", join_date: "", leave_date: "", dob: "", gsc: "", stud_code: "", acc_name: "", acc_number: "",

        // ------------Textarea input fields--------------
        addressline1: "", addressline2: "",

        // ------------Checkbox input fields--------------
        active: false, photo_consent: false, disclaimer_signed: false, status: "",

        // ------------Select inputs fields--------------
        state: "", country: "", location_name: "", batch_name: "", level_name: "", user_name: "",
        picture: "",
    })

    useEffect(() => {
        StudentDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ------------------------------
    const StudentDetail = () => {
        const obj = {
            uname: field.uname,
            id: sessionStorage.getItem("id")
        }
        axios.post(url + "fetch_single_student", obj)
            .then((response) => {
                let resData = response.data.data;
                console.log(resData);
                setField({
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city, zip: resData[0].zip,
                    mobile_no: resData[0].mobile_no, home_no: resData[0].home_no, email: resData[0].email,
                    parent_fname: resData[0].parent_fname, parent_lname: resData[0].parent_lname, parent_mobile_no: resData[0].parent_mobile_no,
                    parent_email: resData[0].parent_email, parent_mobile_no_2: resData[0].parent_mobile_no_2, approved_date: resData[0].approved_date,
                    parent_email_2: resData[0].parent_email_2, join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    stud_id: resData[0].stud_id,
                    gsc: resData[0].gsc, stud_code: resData[0].stud_code, dob: resData[0].dob, acc_name: resData[0].acc_name, acc_number: resData[0].acc_number,
                    // ------------Textarea input fields--------------
                    addressline1: resData[0].addressline1, addressline2: resData[0].addressline2,

                    // ------------Checkbox input fields--------------
                    active: resData[0].active, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,

                    // ------------Select inputs fields--------------
                    state: resData[0].state, country: resData[0].country, location_name: resData[0].location_name, batch_name: resData[0].batch_name,
                    level_name: resData[0].level_name, user_name: resData[0].user_name, status: resData[0].status

                })

            })
            .catch((err) => console.log(err));
    }

    return (
        <div className="container-fluid">
            {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
            <div className="row">
                <div className="col-md-12 text-end">
                    <Button variant="contained" onClick={() => navigate("/compose")} >Contact Teacher</Button>
                </div>

            </div>
            <div className="row mb-5">
                {/*-----------------------Column-1 for Inputs------------------------- */}
                <div className="col-11 col-md-6 mx-auto">

                    <h5 className="mb-4">Personal Information</h5>

                    <ReadonlyInput field="First Name" value={field.fname} />

                    <ReadonlyInput field="Middle Name" value={field.mname} />

                    <ReadonlyInput field="Last Name" value={field.lname} />

                    <ReadonlyInput field="Date of Birth" value={field.dob} />

                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Address Line 1</label>
                        <div className="col-sm-7 col-md-7 ">
                            <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.addressline1}></textarea>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Address Line 2</label>
                        <div className="col-sm-7 col-md-7 ">
                            <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.addressline2} ></textarea>
                        </div>
                    </div>

                    <ReadonlyInput field="City" value={field.city} />

                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Country" className="form-control bg-light border-0 " value={field.country} />

                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Province" className="form-control bg-light border-0 " value={field.state} />

                    <ReadonlyInput field="Zip" value={field.zip} />

                    <ReadonlyInput field="Mobile Number" value={field.mobile_no} />

                    <ReadonlyInput field="Home Phone" value={field.home_no} />

                    <ReadonlyInput field="Email" type="email" placeholder="--" value={field.email} />

                    <h5 className="mt-5 mb-4">Parent Information</h5>

                    <ReadonlyInput field="Parent First Name" value={field.parent_fname} />

                    <ReadonlyInput field="Parent Last Name" value={field.parent_lname} />

                    <ReadonlyInput field="Parent Mobile Number" value={field.parent_mobile_no} />

                    <ReadonlyInput field="Parent Email" value={field.parent_email} />

                    <ReadonlyInput field="Parent Mobile Number 2" value={field.parent_mobile_no_2} />

                    <ReadonlyInput field="Parent Email 2" value={field.parent_email_2} />

                </div>
                {/*-----------------------Column-2 for Inputs------------------------- */}
                <div className="col-11 col-md-6 mx-auto">

                    <h5 className="mb-4">Batch Information</h5>

                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Location" className="form-control bg-light border-0 " value={field.location_name} />

                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Batch" className="form-control bg-light border-0 " value={field.batch_name} />

                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Level" className="form-control bg-light border-0 " value={field.level_name} />


                    <h5 className="mt-5 mb-4">Other Information</h5>

                    {/* ---Select Input---- */}
                    <ReadonlyInput field="User" className="form-control bg-light border-0 " value={field.user_name} />

                    <ReadonlyInput field="Global Student Code" className="form-control bg-light border-0 " value={field.gsc} />

                    <ReadonlyInput field="Student Code" className="form-control bg-light border-0 " value={field.stud_code} />

                    <ReadonlyInput field="Status" className="form-control bg-light border-0 " value={field.status} />


                    {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                        <div className="col-sm-7 col-md-7 ps-4">
                        <div className="form-check">
                                <input readOnly className="form-check-input "  type="checkbox" checked={field.photo_consent === "1" || field.photo_consent === "true" ? true : false}    />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                        <div className="col-sm-7 col-md-7 ps-4">
                        <div className="form-check">
                                <input readOnly className="form-check-input " name="disclaimer_signed" type="checkbox" checked={field.disclaimer_signed === "1" || field.disclaimer_signed === "true" ? true : false}  />
                            </div>
                        </div>
                    </div>
                     */}
                    <ReadonlyInput field="Account Name" className="form-control bg-light border-0 " value={field.acc_name} />

                    <ReadonlyInput field="Account Number" className="form-control bg-light border-0 " value={field.acc_number} />

                    <ReadonlyInput name="join_date" field="Join Date" type="date" placeholder="--" value={field.join_date} />

                    <ReadonlyInput name="leave_date" field="Leave Date" type="date" placeholder="--" value={field.leave_date} />
                </div>
            </div>

        </div>
    )
}

export default StudentDetailProfileComp

