import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReadonlyInput } from "../../../Setup/Student/Component/Input";
import Heading from "../../../../Common/Components/Heading";
import axios from "axios";
import url from "../../../../Common/URL";
import { Button } from "@mui/material";

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";


const ViewItemComp = () => {
    const confirm = useConfirm();


    const navigate = useNavigate();
    const { id } = useParams();

    const [state, setState] = useState({
        product_name: "", description: "", make: "", model: "", price: "", location: "", url: "", status: "",
        comments: "", reorder_level: "", reorder_quantity: "",
    })

    useEffect(() => {
        LoadItem();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Item data--------------------
    const LoadItem = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };

        await axios.post(url + "fetch_single_inventory", obj)
            .then((response) => {
                let a = response.data.data;
                setState({
                    product_name: a[0].product_name, description: a[0].description, make: a[0].make, model: a[0].model,
                    price: a[0].price, location: a[0].location, url: a[0].url, status: a[0].status,
                    comments: a[0].comments, reorder_level: a[0].reorder_level, reorder_quantity: a[0].reorder_quantity,
                })
            }).catch((err) => console.log(err));
    }

    // ------------------------Deleting Student table's data-------------------------------------

    const onDelete = () => {
        confirm({ title: "Delete Confirmation", description: 'This will delete this item permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),

                };
                axios.post(url + "delete_inventory", delObj)
                    .then((res) => {
                        // console.log(res);
                        navigate("/inventory");
                        toast.info("Item deleted !")
                    })
                    .catch((err) => console.log(err));
            })

    }



    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item "><Link to="/inventory">Inventory</Link></li>
                        <li className="breadcrumb-item active">View Item</li>
                    </ol>
                </nav>
                <Heading name="View Item" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}

                <div className="container-fluid mt-3">
                    <div className="row my-4">
                        <div className="col-12 d-flex justify-content-end mx-auto">
                            <Button variant="contained" color="error" onClick={onDelete} >Delete</Button>
                            <Button variant="contained" onClick={() => navigate("/edit-item-inv/" + id)} className="ms-3">Edit</Button>
                        </div>
                    </div>

                    <div className="row">

                        {/*-----------------------Column-1 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <h5 className="mb-4">Item Information</h5>


                            <ReadonlyInput name="product_name" field="Product Name"   value={state.product_name} />

                            <ReadonlyInput name="model" field="Model"   value={state.model} />

                            <ReadonlyInput name="make" field="Make"   value={state.make} />

                            <ReadonlyInput name="description" field="Description"   value={state.description} />

                            <ReadonlyInput name="price" field="Price"   value={state.price} />

                            {/* <ReadonlyInput name="location" field="Location"   value={state.location}  /> */}

                        </div>
                        {/*-----------------------Column-2 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <h5 className="mb-4">Other Information</h5>

                            <ReadonlyInput name="url" field="URL"   value={state.url} />

                            <ReadonlyInput name="reorder_level" field="Re-Order Level"   value={state.reorder_level} />

                            <ReadonlyInput name="reorder_quantity" field="Re-Order Quantity"   value={state.reorder_quantity} />

                            <ReadonlyInput name="comments" field="Comments"   value={state.comments} />

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label ">Status</label>
                                <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id="inlineRadio1"
                                            value="Active"
                                            checked={state.status === "Active"}
                                            readOnly

                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id="inlineRadio2"
                                            value="Inactive"
                                            checked={state.status === "Inactive"}
                                            readOnly
                                        />

                                        <label className="form-check-label" htmlFor="inlineRadio2">Inactive</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </>
    )
}
export default ViewItemComp

