import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";


import Add from '@mui/icons-material/Add';

import axios from "axios";
import { useConfirm } from 'material-ui-confirm';
import url, {devUrl} from "../../../../Common/URL";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { TeacherTableHeaders, TeacherTableRow, ValidatorBatchChecker, ValidatorLocationChecker } from "../../../../Common/Functions/BulkUploadHelper";

import xlsxParser from "xlsx-parse-json";
import TeacherUploadValidator from "../../../../Common/UploadValidators/TeacherUploadValidator";


import { Delete } from "@mui/icons-material";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

export const splitAndJoinWithLimit = (rowData, key, itemsPerRow) => {
  const items = rowData[key].split(",").map((item, index) => (
    <span key={index}>
      {item.trim()}{index < rowData[key].split(",").length - 1 ? ', ' : ''}
    </span>
  ));

  const result = [];
  for (let i = 0; i < items.length; i += itemsPerRow) {
    result.push(
      <div key={i / itemsPerRow}>
        {items.slice(i, i + itemsPerRow)}
      </div>
    );
  }

  return result;
};


const TeacherTable = () => {
  const confirm = useConfirm();
  const [loc, setLoc] = useState([]);
  const [batch, setBatch] = useState([]);
  const [fileArrayObj, setFileArrayObj] = useState([]);

  const [validated, setValidated] = useState(false);
  const [validatorDialog, setValidatorDialog] = useState(false);
  const [validatorData, setValidatorData] = useState({
    data: [], error_message: []
  });

  // ============= For Bulk Upload of Studnets in DB======================= 

  const [load, setLoad] = useState(false);
  const [file_import, setFile_import] = useState(null);
  const [open, setOpen] = useState(false);
  // ============= For Bulk Upload of Studnets in DB======================= 

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [muiAlert, setMuiAlert] = useState({
    show: false, length: null, duplicate_data: [], invalid_data: [], data_inserted: null
  });


  const Columns = useMemo(
    () => [
      { header: 'First Name', accessorKey: 'fname' },
      { header: 'Last Name', accessorKey: 'lname' },
      { header: 'Location', id: 'location_name', accessorFn: rowData => splitAndJoinWithLimit(rowData, 'location_name', 3) },
      { header: 'Batch', id: 'batch_name', accessorFn: rowData => splitAndJoinWithLimit(rowData, 'batch_name', 1) },
      { header: 'Level', id: 'level_name', accessorFn: rowData => splitAndJoinWithLimit(rowData, 'level_name', 3) },
      { header: 'Join Date', accessorKey: 'join_date' }
    ],
    [],
  );

  // ------------------------Fetching Teacher table's data-------------------------------------
  useEffect(() => {
    loadTeachers();
    LoadLocations();
    LoadBatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------This function will Fetch all the Existing Locations--------------------
  const LoadLocations = async () => {
    const obj = { uname: sessionStorage.getItem("username") };

    await axios.post(url + "fetch_locations", obj)
      .then((response) => {
        var a = response.data.data;
        var arr = a.map(x => x.name.toLowerCase());
        setLoc(arr);
      })
      .catch((err) => console.log(err));
  };

  // -----------  This function will Fetch all the Existing Batch--------------------
  const LoadBatches = async () => {
    const obj = { uname: sessionStorage.getItem("username") };

    await axios.post(url + "fetch_batches", obj)
      .then((response) => {
        var a = response.data.data;
        var arr = a.map(x => x.batch_name.toLowerCase());
        setBatch(arr);
      })
      .catch((err) => console.log(err));
  };


  const loadTeachers = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios.post(url + "teachers", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
          !a.length ? setData([]) : setData(a);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  // ------------------------Deleting Teacher table's data-------------------------------------

  const onDelete = (id, resolve) => {
    const delObj = {
      id: id,
      uname: sessionStorage.getItem("username"),
      tokenId: 123,
    };
    axios.post(url + "delete_teacher", delObj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  };


  // Below function will convert an 'Array of Arrays' into 'Array of objects' with Custom keys

  const ConvertArrayToObject = (ArrayOfArray) => {

    let keyNames = ["fname", "mname", "lname", "addressline1", "addressline2", "city", "state", "country", "zip", "designation", "education", "experience", "registration_number", "effective_from", "effective_to", "email", "kvk_number", "mobile_no", "home_no", "location_name", "level_name", "batch_name", "probation_period", "contract_period", "contract_renewal_date", "join_date", "leave_date"];

    let ArrayOfObj = [];
    ArrayOfArray.map(x => {
      let simpleObj = Object.assign({}, x);
      // Renaming the simpleObj object's key according to KeyNames array
      for (let i = 0; i < keyNames.length; i++) {
        simpleObj[keyNames[i]] = simpleObj[i];
        delete simpleObj[i];
      }
      simpleObj.uname = sessionStorage.getItem("username"); // Adding "uname" field in every simpleObj
      ArrayOfObj.push((simpleObj));
    });
    // console.log(ArrayOfObj);
    setFileArrayObj(ArrayOfObj);
  };
  // ############################# For uploadig the Bulk Studnet upload Modal  #############################


  const handleOpen = () => {
    setOpen(true);
  };

  const onChange = (e) => {
    let file = e.target.files[0];
    setFile_import(file);
  };

  const UploadFile = () => {
    setLoad(true);
    // console.log(fileArrayObj);
    axios.post(url + "php/teachers/excel_upload.php", fileArrayObj)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {

          if (res.data.duplicate_data !== null || res.data.invalid_data !== null) {
            let x = res.data.duplicate_data !== null ? res.data.duplicate_data.map(x => `${x.fname} ${x.mname} ${x.lname} Email - ${x.email}`) : false;
            let y = res.data.invalid_data !== null ? res.data.invalid_data.map(x => `${x.fname} ${x.mname} ${x.lname} Email - ${x.email}`) : false;
            setMuiAlert({
              ...muiAlert,
              show: true, duplicate_data: x, invalid_data: y, data_inserted: true
            });

          } else toast.success(`Data inserted successfully`);


        } else {

          if (res.data.duplicate_data !== null || res.data.invalid_data !== null) {
            let x = res.data.duplicate_data !== null ? res.data.duplicate_data.map(x => `${x.fname} ${x.mname} ${x.lname} Email - ${x.email}`) : false;
            let y = res.data.invalid_data !== null ? res.data.invalid_data.map(x => `${x.fname} ${x.mname} ${x.lname} Email - ${x.email}`) : false;
            setMuiAlert({
              ...muiAlert,
              show: true, duplicate_data: x, invalid_data: y, data_inserted: false
            });

          } else toast.error("Data not inserted, please check all the mandatory fields in all the records in the file.");

        }
        loadTeachers();
        setOpen(false);
        setLoad(false);
        setValidated(false);
        setFile_import(null);
      })
      .catch((err) => console.log(err));
  };
  // ------------------------------------------------------------
  const ValidateFile = () => {

    setLoad(true);
    xlsxParser
      .onFileSelection(file_import, { showNullProperties: true, hideEmptyRows: true })
      .then(data => {
        // console.log(Object.keys(data.Sheet1[0]));
        let ArrayOfArray = [];
        data.Sheet1.map(x => ArrayOfArray.push(Object.values(x)));

        ConvertArrayToObject(ArrayOfArray); // this is creating Array of objects form the excel file.  

        const errorArray = TeacherUploadValidator(ArrayOfArray, loc, batch);
        // console.log(errorArray)
        if (errorArray.length) {
          setValidatorData({ data: ArrayOfArray, error_message: errorArray });
          setLoad(false);
          setOpen(false);
          setFile_import(null);
          setValidatorDialog(true);
        } else {
          setLoad(false);
          setValidated(true);
        }
      }).catch(err => { console.log(err); });
  };


  return (
    <>
      {/* ############################################ Dialog for Bulk Upload of Teacher in Database ############################################ */}

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Bulk Upload"}</DialogTitle>
        {validated ?
          <>
            <DialogContent>

              <List dense={false}>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>A variety of validations have been conducted on the file.</ListItemText>
                </ListItem>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>It appears error-free.</ListItemText>
                </ListItem>
                <ListItem >
                  <ListItemIcon ><CheckCircleOutlineIcon htmlColor="#408140" /></ListItemIcon>
                  <ListItemText>The file can now be uploaded.</ListItemText>
                </ListItem>
              </List>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                confirm({ title: "Close Confirmation", description: 'Are you sure, you want to close the popup?' })
                  .then(() => {
                    setFile_import(null);
                    setOpen(false);
                    setValidated(false);
                  });
              }} color="primary" >Close</Button>
              <Button onClick={UploadFile} disabled={file_import === null ? true : false} color="primary" >
                {load ? <CircularProgress size={20} /> : "Upload"}
              </Button>

            </DialogActions>
          </> :
          <>
            <DialogContent>
              <div className="d-flex flex-column ">
                <div>
                  <hr className="mt-0" />
                  <div className="d-flex justify-content-between ">
                    <h6>Validate File before Upload</h6>
                    <a href={`${devUrl}import/Teacher%20Upload%20Sample%20File.xlsx`}>File Format</a>
                  </div>
                </div>
                {/* <div className="alert alert-danger mt-3">Only <strong>".csv"</strong> format file allowed.</div> */}

                <input type="file" className="form-control-file mt-1 mb-2" name="profile_pic" onChange={onChange} />

              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setOpen(false);
                setFile_import(null);
              }} color="primary" >Close</Button>
              <Button onClick={ValidateFile} disabled={file_import === null ? true : false} color="primary" >
                {load ? <CircularProgress size={20} /> : "Validate"}
              </Button>

            </DialogActions>
          </>
        }
      </Dialog>
      {/* ================= Validator Dialog ============================ */}

      <Dialog
        open={validatorDialog}
        // fullWidth= {true}
        // maxWidth="lg"
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Data Validation Report Summary"}</DialogTitle>
        <DialogContent>

          {/* ---------- Showing File data in Table Format -----------------  */}
          <table className="table table-responsive table-borderless shadow-sm " style={{ height: '45%' }}>
            <thead className="shadow-sm border-bottom bg-light table-sm position-sticky" style={{ top: "0" }}>
              <tr>
                <th key={0} scope="col">No.</th>
                {TeacherTableHeaders.map((x, i) => <th key={i} scope="col">{x}</th>)}
              </tr>
            </thead>
            <tbody className="overflow-scroll" >
              {validatorData.data.map((x, i) => {

                //  Checking location name, whether it is proper or not. 
                var loc_exists = ValidatorLocationChecker(x[19], loc);
                //  Checking batch name, whether it is proper or not. 
                var batch_exists = ValidatorBatchChecker(x[21], batch);

                return TeacherTableRow(x, i, loc_exists, batch_exists);

              })}
            </tbody>
          </table>
          {/* ---------- Showing File Error Messages in List Format -----------------  */}

          <h6 className="d-flex align-items-center"> <ErrorOutlineIcon fontSize="large" color="action" className="ms-2 me-3" />Errors Encountered are mentioned below</h6>
          <div style={{ height: "40%", overflowY: "scroll" }}>
            {validatorData.error_message.map((x, i) => {
              return <List key={i} dense={true}>
                <ListItem>
                  <ListItemIcon><ErrorIcon color="error" /></ListItemIcon>
                  <ListItemText>{x}</ListItemText>
                </ListItem>
              </List>;
            })}
          </div>
        </DialogContent>
        <DialogActions className="border-top">
          <Button onClick={() => setValidatorDialog(false)} color="primary" autoFocus>Close</Button>
        </DialogActions>
      </Dialog>

      {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

      <Dialog
        open={muiAlert.show}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Improper/Invalid Data Alert!"}</DialogTitle>
        <DialogContent>
          {muiAlert.data_inserted ?
            <>
              <p>
                DATA INSERTED SUCCESSFULLY.<br />
                But below record(s) are having error, and can't be inserted. Please correct it and try to upload again.
              </p><hr />

              {muiAlert.duplicate_data !== false && <div>
                <p>List of Duplicate data :</p>

                <ol className="ms-3">
                  {muiAlert.duplicate_data.map((x, i) => <li key={i}>{x}</li>)}
                </ol>
              </div>}

              {muiAlert.invalid_data !== false && <div>
                <p>List of Invalid data :</p>

                <ol className="ms-3">
                  {muiAlert.invalid_data.map((x, i) => <li key={i}>{x}</li>)}
                </ol>
              </div>}
            </>
            :
            <>
              <p>
                DATA NOT INSERTED.<br />
                Below record(s) are having error, and can't be inserted. Please correct it and try to upload again.
              </p>
              <hr />

              {muiAlert.duplicate_data !== false && <div>
                <p>List of Duplicate data :</p>

                <ol className="ms-3">
                  {muiAlert.duplicate_data.map((x, i) => <li key={i}>{x}</li>)}
                </ol>
              </div>}

              {muiAlert.invalid_data !== false && <div>
                <p>List of Invalid data :</p>

                <ol className="ms-3">
                  {muiAlert.invalid_data.map((x, i) => <li key={i}>{x}</li>)}
                </ol>
              </div>}
            </>
          }
        </DialogContent>
        <DialogActions>

          <Button onClick={() => setMuiAlert({ ...muiAlert, show: false })} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* ###################################################################################################################################### */}

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">Teachers list</li>
          </ol>
        </nav>
        <Heading name="Teachers List" />

        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-between ">
              <Button variant="contained" onClick={handleOpen} className="px-4" startIcon={<CloudUploadIcon />}>Add Teachers in Bulk</Button>
              <Button variant="contained" onClick={() => navigate("/add-teacher")} >Add Teacher <Add /></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.teacher_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-teacher/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <Button variant="contained" color="error" startIcon={<Delete />}
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            });
                          });
                          Promise.all(delPromise).then(() => {
                            loadTeachers();
                            table.resetRowSelection();
                            toast.info(selectedRows.length + " Row(s) Deleted!");
                          });
                        });
                    }}
                  >Delete
                  </Button>
                )}
              />
            </div>
          </div>

        </div>
      </div>

    </>
  );

};
export default TeacherTable




