import { ConfirmProvider } from 'material-ui-confirm';
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "../src/index.css";
import App from "./App";
import ScrollToTop from "./Common/Functions/ScrollToTop";

import { Provider } from 'react-redux';
import store from './store/store';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
    <BrowserRouter>
      <ScrollToTop />
      <Provider store={store}>
        <ConfirmProvider>
          <App />
        </ConfirmProvider>
      </Provider>
    </BrowserRouter>
  </>
);
