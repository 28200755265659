import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import IconButton from "@mui/material/IconButton";
import { LOGGED_OUT } from "../../store/profileNameSlice";
import { CLOSE, OPEN } from "../../store/sidebarSlice";

import { Divider, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation();

  var path = location.pathname.split("/")[1];
  var showSidebarOpenCloseButton;
  switch (path) {
    case "exam-sheet":
      showSidebarOpenCloseButton = false;
      break;

    case "test-sheet":
      showSidebarOpenCloseButton = false;
      break;

    case "new-test-sheet":
      showSidebarOpenCloseButton = false;
      break;

    case "new-exam-sheet":
      showSidebarOpenCloseButton = false;
      break;


    default:
      showSidebarOpenCloseButton = true;
      break;
  }

  const OpenClose = useSelector(state => state.sidebar.value);;
  const ProfileName = useSelector(state => state.profileName.value);

  const dispatch = useDispatch();

  const [session] = useState(sessionStorage.getItem("username"));

  const navigate = useNavigate();

  function Clicked() {
    if (OpenClose) {
      dispatch(CLOSE(false));
    } else {
      dispatch(OPEN(true));
    }
  }

  function Logout() {
    sessionStorage.removeItem("username")
    // sessionStorage.removeItem("profilepic");
    dispatch(LOGGED_OUT(''));
    navigate("/");
  }

  const Hem_btn = {
    backgroundColor: "#fff",
    outline: "none",
    padding: "7px",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
    color: "#174873",
  };

  const navbar = {
    backgroundColor: "#fff",
    color: "#174873",
    minHeight: "4.5em",
    borderBottom: "5px solid #E89801",
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light sticky-top shadow" style={navbar}>

        {/* Mobile Screen Heading Div */}
        <div className="w-100 m-3 text-end d-md-none">
          <h6 className="fw-bolder softwareName">Learning Management System</h6>
          <Divider />
        </div>

        {(session && showSidebarOpenCloseButton) &&
          <IconButton className="ms-3" style={Hem_btn} id="sidebarCollapse" onClick={Clicked}>
            {OpenClose ?
              (<MenuOpenIcon style={{ fontSize: "1.8rem" }} />) :
              (<ArrowForwardIcon style={{ fontSize: "1.8rem" }} />)
            }
          </IconButton>
        }

        {/* Big Screen Heading */}
        <p className="navbar-brand fw-bolder mb-0 softwareName d-none d-md-block ms-3">Learning Management System</p>

        <div className="d-flex ms-auto justify-content-center align-items-center">


          {session && <h6 className="userName mb-0">{ProfileName} </h6>}

          <ul className="navbar-nav">
            <li className="nav-item dropdown ">
              <a className="nav-link me-3" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                <AccountCircleIcon style={{ color: "#174873" }} fontSize="large" />
              </a>
              <div
                className="dropdown-menu dropdown-menu-end border-0 position-absolute shadow"
                aria-labelledby="navbarDropdownMenuLink"
              >
                {session ? (
                  <ListItemButton dense className="dropdown-item" onClick={Logout}>
                    <ListItemIcon>
                      <ExitToAppIcon style={{ color: "#E89801" }} />
                    </ListItemIcon>
                    <ListItemText className="text-black" primary="Logout" />
                  </ListItemButton>
                ) : (
                  <ListItemButton dense className="dropdown-item" onClick={() => navigate('/')}>
                    <ListItemIcon>
                      <PermIdentityIcon style={{ color: "#E89801" }} />
                    </ListItemIcon>
                    <ListItemText className="text-black" primary="Login" />
                  </ListItemButton>
                )}

                {session && (
                  <ListItemButton dense className="dropdown-item" onClick={() => navigate('/profile')}>
                    <ListItemIcon>
                      <PermIdentityIcon style={{ color: "#E89801" }} />
                    </ListItemIcon>
                    <ListItemText className="text-black" primary="Profile" />
                  </ListItemButton>
                )}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
