import React from "react"
import Navbar from "../../Common/Components/Navbar"
import Footer from "../../Common/Components/Footer"
import Sidebar from "../../Sidebar/Sidebar"
import DocumentComp from "./Component/DocumentComp"


const Document =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <DocumentComp />
        </div>
        <Footer />
        </>
    )
}

export default Document